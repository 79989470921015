import React, { Component } from 'react';

import UiPlanCard from '../../ui/cards/UiPlanCard.js';

class UiModalMenu extends Component {

  state = {}
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  locale(obj, val) {
    let line = "";
    if (val == "title") {
      if (this.props.lang == "ch") line = obj.title_ch;
      if (this.props.lang == "en") line = obj.title_en;
      if (this.props.lang == "ru") line = obj.title_ru;
    }
    if (val == "description") {
      if (this.props.lang == "ch") line = obj.description_ch;
      if (this.props.lang == "en") line = obj.description_en;
      if (this.props.lang == "ru") line = obj.description_ru;
    }
    if (val == "full_description") {
      if (this.props.lang == "ch") line = obj.full_description_ch;
      if (this.props.lang == "en") line = obj.full_description_en;
      if (this.props.lang == "ru") line = obj.full_description_ru;
    }
    return line;
  }


  render() {
    if (this.props.menu) {
      var list = this.props.menu.map((item, index) => {
        return (
          <div key={index} className="col-md-3 d-flex plan-card-stretch">
            <UiPlanCard
              addItem={false}
              image={{ uri: item.image }}

              title={item.title_ru}
              ingredient={item.description_ru}

              title={this.locale(item, "title")}
              ingredient={this.locale(item, "description")}


              lanchType={global.locale.modal_menu_dinner}
              about={item.calories + " " + global.locale.modal_menu_kkal + ", " + item.weight + " " + global.locale.modal_menu_gramm}
              ccal={item.calories}
              protein={item.protein}
              fats={item.fats}
              carbo={item.carbohydrates}
              modalAddCard={true}
              price={item.price}
              selectCallBack={() => {
                this.props.callBackChange(item.id, item);
                this.props.callBackClose();
              }}
            />
          </div>
        )
      })
    }

    return (
      <div className={"modal-wrap " + (this.props.show ? "show" : "hide")}>
        <div className="modal-card">
          <div className="card-title">
            <h3>{global.locale.modal_menu_kkal}, 13.04</h3>
            <button onClick={() => this.props.callBackClose()} className="card-title-btn"><img src={require('../../../assets/imgs/ui/close.svg')} /></button>
          </div>
          <div className="row">
            {list}
          </div>
        </div>
      </div>
    );
  }
}
export default UiModalMenu;