import React, { Component } from 'react';
import UiSelect from '../../../components/ui/forms/UiSelect.js';
import UiSelectDate from "../../../components/ui/forms/UiSelectDate";
import UiSelectScroll from '../../../components/ui/forms/UiSelectScroll';

import UiBtnGreen from '../buttons/UiBtnGreen';

import { formatDate, getDateWithOffsetAllowed2, formatDateYMD } from '../../../components/common/Dates.js';
import UiBtnGreenOutline from '../buttons/UiBtnGreenOutline.js';

class UiModalTransfer extends Component {

    state = {
        activePay: 0,
        selectBonus: 0,
        promoCode: "",
        ftdate: formatDate(new Date()),
        excludeDaysArr: [],
        optionsListDays: [4, 6, 12, 24],
        optionsListDaysSmall: [5, 10, 15, 20],
        optionsListDaysText: [
            global.locale.home_food_menu_option_1_item_1,
            global.locale.home_food_menu_option_1_item_2,
            global.locale.home_food_menu_option_1_item_3,
            global.locale.home_food_menu_option_1_item_4
        ],
        optionsListDaysSmallText: [
            global.locale.home_food_menu_option_1_item_5,
            global.locale.home_food_menu_option_1_item_6,
            global.locale.home_food_menu_option_1_item_7,
            global.locale.home_food_menu_option_1_item_8
        ],
        daysCount: 5,
    }
    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.show != this.props.show) {
            this.setState({
                ftdate: getDateWithOffsetAllowed2(0, this.props.minDate)[0].text,
                daysCount: this.props.daysCount,
            })
        }
    }

    componentDidMount() {
        let _excludeDaysArr = [];
        for (let i = -30; i < 360; i++) {
            var date = new Date();
            date.setDate(date.getDate() + i);
            if ((0 == this.getDay(date) || 2 == this.getDay(date) || 4 == this.getDay(date))) {

            } else {
                _excludeDaysArr.push(date);
            }
        }
        this.setState({
            excludeDaysArr: _excludeDaysArr
        })
    }


    getDay(date) {
        var d = new Date(date),
            day = d.getDay();

        return day;
    }

    changeDate() {

        this.props.callBack(formatDateYMD(this.state.ftdate))
    }

    render() {
        return (
            <div className={"modal-wrap " + (this.props.show ? "show" : "hide")}>
                <div className="modal-payment modal-transfer">
                    <div className="card-title">
                        <h3> </h3>
                        <button className="card-title-btn" onClick={() => this.props.callBackClose()} ><img src={require('../../../assets/imgs/ui/close.svg')} /></button>
                    </div>
                    <div className="payment-wrap modal-transfer">
                        <div className="program-info">
                            <img src={require('../../../assets/imgs/menu-type/healthy.svg')} />
                            <div className="program-info-title">
                                <h3>{this.props.title}</h3>
                                <p>{this.props.description}</p>

                            </div>
                            <div className="program-info-price">
                                <p> </p>
                            </div>
                        </div>
                        <div className="promo-info-date modal-transfer">
                            {this.props.programSize == 0 ? (
                                <UiSelectScroll
                                    selectedText={this.state.optionsListDaysText[
                                        this.state.daysCount == 4 ? 0 :
                                            this.state.daysCount == 6 ? 1 :
                                                this.state.daysCount == 12 ? 2 :
                                                    this.state.daysCount == 24 ? 3 : 0
                                    ]}
                                    selectItems={
                                        [
                                            { text: global.locale.home_food_menu_option_1_item_1, value: 4 },
                                            { text: global.locale.home_food_menu_option_1_item_2, value: 6 },
                                            { text: global.locale.home_food_menu_option_1_item_3, value: 12 },
                                            { text: global.locale.home_food_menu_option_1_item_4, value: 24 },
                                        ]
                                    }
                                    callBack={(val) => {
                                        console.log(val, this.state.optionsListDays[val])
                                        this.setState({ daysCount: this.state.optionsListDays[val] })
                                    }}
                                />
                            ) : (
                                <UiSelectScroll
                                    selectedText={this.state.optionsListDaysSmallText[
                                        this.state.daysCount == 5 ? 0 :
                                            this.state.daysCount == 10 ? 1 :
                                                this.state.daysCount == 15 ? 2 :
                                                    this.state.daysCount == 20 ? 3 : 0
                                    ]}
                                    selectItems={
                                        [
                                            { text: global.locale.home_food_menu_option_1_item_5, value: 5 },
                                            { text: global.locale.home_food_menu_option_1_item_6, value: 10 },
                                            { text: global.locale.home_food_menu_option_1_item_7, value: 15 },
                                            { text: global.locale.home_food_menu_option_1_item_8, value: 20 },
                                        ]
                                    }
                                    callBack={(val) => {
                                        console.log(val)
                                        this.setState({ daysCount: val.value })
                                    }}
                                />
                            )}

                            {/*
                            this.props.programSize == 0 ? (
                                <div className="card-info-length">
                                    <label>{global.locale.home_food_menu_option_1}</label>
                                    <UiSelect
                                        currentIndex={
                                            this.state.daysCount == 4 ? 0 :
                                                this.state.daysCount == 6 ? 1 :
                                                    this.state.daysCount == 12 ? 2 :
                                                        this.state.daysCount == 24 ? 3 : 0
                                        }
                                        optionList={[
                                            global.locale.home_food_menu_option_1_item_1,
                                            global.locale.home_food_menu_option_1_item_2,
                                            global.locale.home_food_menu_option_1_item_3,
                                            global.locale.home_food_menu_option_1_item_4
                                        ]}
                                        callBack={(val) => this.setState({ daysCount: this.state.optionsListDays[val] })}
                                    />

                                </div>
                            ) : (
                                <div className="card-info-length">
                                    <label>{global.locale.home_food_menu_option_1}</label>
                                    <UiSelect
                                        currentIndex={
                                            this.state.daysCount == 5 ? 0 :
                                                this.state.daysCount == 10 ? 1 :
                                                    this.state.daysCount == 15 ? 2 :
                                                        this.state.daysCount == 20 ? 3 : 0
                                        }
                                        optionList={[
                                            global.locale.home_food_menu_option_1_item_5,
                                            global.locale.home_food_menu_option_1_item_6,
                                            global.locale.home_food_menu_option_1_item_7,
                                            global.locale.home_food_menu_option_1_item_8
                                        ]}
                                        callBack={(val) => this.setState({ daysCount: this.state.optionsListDays[val] })}
                                    />

                                </div>
                            )
                                    */}
                        </div>

                        <div className="promo-info-date">
                            <label>  {global.locale.cart_delivery_data}</label>
                            <UiSelectDate
                                minDate={new Date(this.props.minDate)}
                                excludeDates={this.state.excludeDaysArr}
                                onChange={(data) => {
                                    console.log(formatDate(data));
                                    this.setState({ ftdate: formatDate(data) })

                                }}
                                selected={
                                    new Date(
                                        this.state.ftdate.replace(/-/g, '/')
                                    )
                                }
                            />
                        </div>

                        <div className="payment-type">
                            <div className="btn-payment-wrap">
                                <UiBtnGreen
                                    btnText={global.locale.home_food_menu_option_stop}
                                    onClick={() => {
                                        this.props.callBack(this.state.ftdate, this.state.daysCount);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="payment-type">
                            <div className="btn-payment-wrap">
                                <UiBtnGreenOutline
                                    btnText={global.locale.reg_btn_cancel}
                                    onClick={() => {
                                        this.props.callBackClose()
                                    }}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default UiModalTransfer;