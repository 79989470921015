import ENV from '../services/Env.js';

export const getAdressAutocompleat = (line) => {
  //'https://maps.google.com/maps/api/geocode/json?address='+line+'&sensor=false&&key=AIzaSyBl4TZRbXs15EwoXCn3-3kXIllv7fTCsq8'
 
    return fetch( 'https://maps.googleapis.com/maps/api/place/autocomplete/json?input='+line+'&language=ch&key=AIzaSyBl4TZRbXs15EwoXCn3-3kXIllv7fTCsq8' , {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }
      }).then((response) => response.json());
  }