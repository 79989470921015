import React, { Component } from 'react';



import menuIcon from '../../assets/imgs/profile/dinner.svg';
import deliveryIcon from '../../assets/imgs/profile/delivery.svg';
import reviewIcon from '../../assets/imgs/profile/heart.svg';

import changeIcon from '../../assets/imgs/profile/adjust.svg';
import infoIcon from '../../assets/imgs/ui/information.svg';
import planEmptyImg from '../../assets/imgs/profile/harvest.svg';

import UiBtnGreen from '../../components/ui/buttons/UiBtnGreen.js';
import UiBtnPinkOutline from '../../components/ui/buttons/UiBtnPinkOutline.js';
import UiCalendar from '../../components/ui/tabs/UiCalendar.js';
import UiDocItem from '../../components/ui/cards/UiDocItem.js';
import UiHeader from '../../components/ui/header/UiHeader.js';
import UiFooter from '../../components/ui/footer/UiFooter.js';
import UiModalPay from '../../components/ui/modals/UiModalPay.js';
import UiModalMenu from '../../components/ui/modals/UiModalMenu.js';
import UiPlanCard from '../../components/ui/cards/UiPlanCard.js';
import UiDeliveryItem from '../../components/ui/cards/UiDeliveryItem';
import UiBtnGreenOutline from '../../components/ui/buttons/UiBtnGreenOutline';
import UiSwitch from '../../components/ui/forms/UiSwitch';
import UiSelectScroll from '../../components/ui/forms/UiSelectScroll';
import UiLoader from '../../components/ui/modals/UiLoader';
import UiMenuProfile from '../../components/ui/tabs/UiMenuProfile';

import { en } from '../../i18n/en.js';
import { ru } from '../../i18n/ru.js';
import { ch } from '../../i18n/ch.js';
import { translateObject } from '../../i18n/locale.js';

import { getDateWithOffset } from '../../components/common/Dates';
import { ValidateInput } from '../../components/common/Validator';
import { loadUser, getProfile } from '../../services/Users';
import { retrieveData, storeData } from '../../services/Storage';

const AnyReactComponent = () => (
    <div classNameName='marker'></div>
);

class BonusPage extends Component {
    state = {
        loader: false,
        plansNum: 1,
        activePlanTab: 0,
        activeItem: 1,
        activeOrderTab: 0,
        menuHeight: 0,
        subscribeActiviti: 1,
        subscribeItem: 0,

        user: {
        }
    }

    constructor(props) {
        super(props);

        let _lang = retrieveData("ikLocale");
        if (_lang == null) _lang = 'ch';
        if (_lang == 'ch') global.locale = ch;
        if (_lang == 'ru') global.locale = ru;
        if (_lang == 'en') global.locale = en;
        this.setState({ lang: _lang });

    }

    changeLang(val) {
        let _lang = 'ch';
        if (val == 0) _lang = 'ru';
        if (val == 1) _lang = 'ch';
        if (val == 2) _lang = 'en';
        if (_lang == 'ch') global.locale = ch;
        if (_lang == 'ru') global.locale = ru;
        if (_lang == 'en') global.locale = en;
        this.setState({ lang: _lang });
        storeData("ikLocale", _lang);
        //this._load();
    }
    locale(obj, val) {
        let line = "";
        if (val == "title") {
            if (this.state.lang == "ch") line = obj.title_ch;
            if (this.state.lang == "en") line = obj.title_en;
            if (this.state.lang == "ru") line = obj.title_ru;
        }
        if (val == "description") {
            if (this.state.lang == "ch") line = obj.description_ch;
            if (this.state.lang == "en") line = obj.description_en;
            if (this.state.lang == "ru") line = obj.description_ru;
        }
        if (val == "full_description") {
            if (this.state.lang == "ch") line = obj.full_description_ch;
            if (this.state.lang == "en") line = obj.full_description_en;
            if (this.state.lang == "ru") line = obj.full_description_ru;
        }

        return line;
    }
    componentDidMount() {
        let _lang = retrieveData("ikLocale");
        if (_lang == null) _lang = 'ch';
        this.setState({ lang: _lang });

        this._load();
        window.addEventListener('scroll', () => {
            //if (document.documentElement.scrollTop > 160) this.setState({ showMenuBar: true }); else this.setState({ showMenuBar: false });
        });
        window.addEventListener('resize', () => {
            this.setState({
                menuHeight: document.getElementById('root').offsetHeight,
            });
        });
    }

    _load() {
        this.setState({ menuHeight: document.getElementById('root').offsetHeight });
        this.setState({ loader: false });
        let _user = loadUser();
        this.setState({ user: _user });
        if (_user) {
            getProfile(_user.api_token).then((res) => {
                console.log("getProfile", res)
                if (res.success && res.response.length > 0) {
                    this.setState({ user: res.response[0] });
                } else {

                }
            });
        }
        if (_user == null) this.props.history.push("/login");


    }

    _copy(text) {
        var input = document.createElement('textarea');
        input.innerHTML = text;
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
    }

    render() {
        return (
            <div ref={this.divPage} id="page">
                <UiLoader show={this.state.loader} />
                <UiModalMenu show={false} />
                <UiModalPay show={false} />
                <div className={"wrapper login-wrapper" + (this.state.showMenuBar ? ' scroll' : '')}>
                    <div className="content">
                        <UiHeader profile menuHeight={this.state.menuHeight} langCallBack={(val) => this.changeLang(val)} />
                        <section className="profile-title image-bg small-section">
                            <div className="image-shadow">
                                <div className="container">
                                    <h2 className="section-title">{global.locale.bonus_header_}</h2>
                                </div>
                            </div>
                        </section>
                        <section className="profile-wrap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 col-xl-3">
                                        <UiMenuProfile activeItem={5} callBack={(val) => {
                                            if (val == 0) this.props.history.push("/profile");
                                            if (val == 1) this.props.history.push("/cart");
                                            if (val == 2) this.props.history.push("/plans");
                                            if (val == 3) this.props.history.push("/subscribe");
                                            if (val == 4) this.props.history.push("/history");
                                            if (val == 5) this.props.history.push("/bonus");
                                        }} />
                                    </div>
                                    <div className="col-md-12 col-lg-8 col-xl-9">
                                        <div className="bonus">
                                            <div className="bonus-header">
                                                <h3 className="form-title">{global.locale.bonus_header_2}</h3>
                                            </div>
                                            <div className="bonus-wrap">
                                                <div className="bonus-block-converting">
                                                    <div className="bonus-block-converting-left">
                                                        <p><strong>{global.locale.bonus_my_bonus}</strong></p>
                                                        <p>{global.locale.bonus_barter}</p>
                                                    </div>
                                                    <div className="bonus-block-converting-right">
                                                        <img src={require('../../assets/imgs/profile/gift.svg')} />
                                                        <span>{this.state.user.bonus}</span>
                                                    </div>
                                                </div>
                                                <div className="bonus-rule-section">
                                                    <div className="bonus-sub-header">
                                                        <p>{global.locale.bonus_rule_}</p>
                                                    </div>
                                                    <div className="bonus-border">
                                                        <p>{global.locale.bonus_rule_1}</p>
                                                        <p>{global.locale.bonus_rule_2}</p>
                                                        <p>{global.locale.bonus_rule_3}</p>
                                                    </div>
                                                </div>
                                                <div className="bonus-link-section">
                                                    <div className="bonus-sub-header">
                                                        <p>{global.locale.bonus_rule_4}</p>
                                                    </div>
                                                    <div className="bonus-link-block">
                                                        <div className="bonus-link-group">
                                                            <label>{global.locale.bonus_promocode}</label>
                                                            <input className="bonus-input" id="myInput" value={"ikitchen-" + this.state.user.id} disabled />
                                                        </div>
                                                        <div className="bonus-button">
                                                            <UiBtnGreen btnText={global.locale.bonus_btn_promocode} onClick={() => this._copy("ikitchen-" + this.state.user.id)} black btnsm />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bonus-border">
                                                    <p>{global.locale.bonus_promocode_rule}</p>
                                                </div>
                                                <div className="bonus-info-section">
                                                    <div className="bonus-sub-header">
                                                        <p>{global.locale.bonus_info}</p>
                                                    </div>
                                                    <p>{global.locale.bonus_info_cashback}</p>
                                                    <div className="bonus-border">
                                                        <p>{global.locale.bonus_info_text1}</p>
                                                    </div>
                                                    <p>{global.locale.bonus_info_promocode}</p>
                                                    <div className="bonus-border">
                                                        <p>{global.locale.bonus_info_text2}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <UiFooter />
                    </div>
                </div>
            </div>
        );
    }
}
export default BonusPage;