import React, { Component } from 'react';

import authIcon from '../../assets/imgs/login/profile.svg';
import orderIcon from '../../assets/imgs/login/basket.svg';

import UiAlert from '../../components/ui/modals/UiAlert.js';
import UiHeader from '../../components/ui/header/UiHeader.js';
import UiFooter from '../../components/ui/footer/UiFooter.js';
import UiFormInput from '../../components/ui/forms/UiFormInput.js';
import UiBtnBlue from '../../components/ui/buttons/UiBtnBlue.js';
import UiBtnGreen from '../../components/ui/buttons/UiBtnGreen.js';
import UiBtnGreenOutline from '../../components/ui/buttons/UiBtnGreenOutline.js';
import UiSelectScroll from '../../components/ui/forms/UiSelectScroll';
import UiSelectDate from "../../components/ui/forms/UiSelectDate";
import UiSwitch from '../../components/ui/forms/UiSwitch';
import UiLoader from '../../components/ui/modals/UiLoader';

import { en } from '../../i18n/en.js';
import { ru } from '../../i18n/ru.js';
import { ch } from '../../i18n/ch.js';
import { translateObject } from '../../i18n/locale.js';

import InputMask from 'react-input-mask';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import { getFormatedCurrentDate, getDateWithOffset, convertStringToDateToRu, getDateWithOffsetAllowed2, formatDate, getDay } from '../../components/common/Dates.js';
import { ValidateInput } from '../../components/common/Validator';
import { getAdressAutocompleat } from '../../services/Adress';
import { getExcludesPrice } from "../../services/System";
import { loginUserFB, loginUser, getProfile, loadUser, registrationUser, resetUser, saveUser } from '../../services/Users';
import { retrieveData, storeData } from '../../services/Storage';

const AnyReactComponent = () => (
  <div classNameName='marker'></div>
);

const google = window.google;

class LoginPage extends Component {

  state = {
    loader: false,
    loginFormActive: 0,
    regType: 0,
    usrType: 0,
    checkActive: false,
    menuHeight: 0,
    cart: null,
    excludeDaysArr: [],
    firstDateArr: [],
    excludesList: [],

    inn: '',
    juradrr: '',
    name: '',
    lastname: '',
    email: '',
    password: '',
    password2: '',
    phone: '',
    minDate: getDateWithOffsetAllowed2(2, getFormatedCurrentDate())[0].value,
    ftdate: '',
    ftdaypart: 0,
    fttime: '',
    street: '',
    city: '',
    code: '',
    comment: '',
    exclude: '',
    fb_token: '',
  }

  constructor(props) {
    super(props);

    let _lang = retrieveData("ikLocale");
    if (_lang == null) _lang = 'ch';
    if (_lang == 'ch') global.locale = ch;
    if (_lang == 'ru') global.locale = ru;
    if (_lang == 'en') global.locale = en;
    this.setState({ lang: _lang });
  }

  changeLang(val) {
    let _lang = 'ch';
    if (val == 0) _lang = 'ru';
    if (val == 1) _lang = 'ch';
    if (val == 2) _lang = 'en';
    if (_lang == 'ch') global.locale = ch;
    if (_lang == 'ru') global.locale = ru;
    if (_lang == 'en') global.locale = en;
    this.setState({ lang: _lang });
    storeData("ikLocale", _lang);
    this._load();
  }

  componentDidMount() {
    this._load();
    window.addEventListener('scroll', () => {
      //if (document.documentElement.scrollTop > 200) this.setState({ showMenuBar: true }); else this.setState({ showMenuBar: false });
    });
    window.addEventListener('resize', () => {
      this.setState({
        menuHeight: document.getElementById('root').offsetHeight,
      });
    });
  }


  initialize() {
    var input = document.getElementById('searchTextField');
    var options = {
      types: ['geocode']
    };
    var autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.setFields(['address_component']);
    autocomplete.addListener('place_changed', () => {
      var componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name'
      };

      var place = autocomplete.getPlace();
      if (place) {
        if (place.address_components) {
          for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (componentForm[addressType]) {
              var val = place.address_components[i][componentForm[addressType]];
              componentForm[addressType] = val;
              if (componentForm.route != 'long_name') {
                document.getElementById("searchTextField").value = componentForm.route + ", " + componentForm.street_number;
                this.setState({ street: componentForm.route + ", " + componentForm.street_number })
              }
              if (componentForm.postal_code != 'short_name') {
                document.getElementById("codeTextField").value = componentForm.postal_code;
                this.setState({ code: componentForm.postal_code });
              }
              if (componentForm.locality != 'long_name') {
                document.getElementById("cityTextField").value = componentForm.locality;
                this.setState({ city: componentForm.locality });
              }
              if (componentForm.locality == 'long_name' && componentForm.administrative_area_level_1 != 'short_name') {
                document.getElementById("cityTextField").value = componentForm.administrative_area_level_1;
                this.setState({ city: componentForm.administrative_area_level_1 });
              }
            }
          }
        }
      }
      console.log(place, componentForm);
    });
  }

  _scrollToTargetAdjusted(target) {
    var element = document.getElementById(target);
    var headerOffset = 130;
    if (window.innerWidth < 770) headerOffset = 350;
    var elementPosition = element.offsetTop;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }


  _alert(_title = null, _text) {
    this.setState({
      showAlert: true,
      alertTitle: _title,
      alertText: _text
    })
  }

  _updateStateVal(_tt, _val) {
    let exArr = [];
    let st = this.state.exclude;
    if (st == null) st = {};

    if (_tt == "exclude") {
      if (_val == null) {
        st = "";
      } else {

        let excludeItem = st ? st : "";
        exArr = excludeItem ? excludeItem.split(",") : [];

        if (!excludeItem.includes(_val)) {
          exArr.push(_val);
        } else {
          let i = 0;
          exArr.map((item, index) => {
            if (item == _val) i = index;
          })
          exArr.splice(i, 1);

        }
        st = exArr.join(",");
      }

    } else {
      st = _val;
    }

    this.setState({ exclude: st })
  }


  _load() {
    let _user = loadUser();
    if (_user != null) this.props.history.push("/profile");

    getExcludesPrice().then((res) => {
      if (res.response) {
        let arr = [{ value: null, text: global.locale.reg_exclude_text2 }];
        res.response.map((item) => {
          arr.push({ value: item.name, text: item.name + " +" + item.value + "Kč/den" });
        })
        this.setState({ excludesList: arr });
      }
    })

    //Login via token 
    if (this.props.match.params.token) {
      getProfile(this.props.match.params.token).then((res) => {
        console.log(res.response);
        this.setState({ loader: false });
        if (res.success) {
          if (res.response) {
            saveUser(res.response[0]);
            this.props.history.push("/profile")
          } else {
            this._alert(global.locale.login_alert_user_miss);
          }
        } else {
          this._alert(global.locale.login_alert_user_miss);
        }
      })
    }

    let _excludeDaysArr = [
      new Date(2022, 11, 22),
      new Date(2022, 11, 25),
      new Date(2022, 11, 27),
      new Date(2022, 11, 29),
      new Date(2023, 0, 1),
    ];
    for (let i = -30; i < 360; i++) {
      var date = new Date();
      date.setDate(date.getDate() + i);
      if ((0 == getDay(date) || 2 == getDay(date) || 4 == getDay(date))) {

      } else {
        _excludeDaysArr.push(date);
      }
    }



    this.setState({
      cart: retrieveData('ikCart'),
      menuHeight: document.getElementById('root').offsetHeight,
      firstDateArr: getDateWithOffsetAllowed2(2, getFormatedCurrentDate()),
      firstTimeArr: [

        { value: "17:00 - 22:00", text: "17:00 - 22:00" },
      ],
      minDate: getDateWithOffsetAllowed2(2, getFormatedCurrentDate())[0].value,
      ftdate: getDateWithOffsetAllowed2(2, getFormatedCurrentDate())[0].text,
      fttime: "17:00 - 22:00",
      excludeDaysArr: _excludeDaysArr
    });
    console.log(
      getDateWithOffsetAllowed2(2, getFormatedCurrentDate()),
      getDateWithOffsetAllowed2(2, getFormatedCurrentDate())[0].text,
      new Date(getDateWithOffsetAllowed2(2, getFormatedCurrentDate())[0].text)
    )
    this._scrollToTargetAdjusted("page")
  }

  showRegPage() {
    this.setState({ loginFormActive: 1 }, () => {
      google.maps.event.addDomListener(window, 'load', this.initialize());
    })
  }

  login() {
    console.log(this.state.email.length, this.state.password.length)
    if (this.state.email.length > 0 && this.state.password.length > 0) {
      this.setState({ loader: true });
      loginUser(this.state.email, this.state.password).then((res) => {
        console.log(res.response);
        this.setState({ loader: false });
        if (res.success) {
          if (res.response) {
            saveUser(res.response);
            if (this.state.cart) {
              this.props.history.push("/cart");
            } else {
              this.props.history.push("/profile");
            }



          } else {
            this._alert(global.locale.login_alert_user_miss);
          }
        } else {
          this._alert(global.locale.login_alert_user_miss);
        }
      })
    } else {
      this._alert(global.locale.login_alert_wrong_filling);
    }
  }

  reset() {

    if (ValidateInput('email', this.state.email)) {
      this.setState({ loader: true });
      resetUser(this.state.email).then(() => {
        this.setState({ loader: false });
        this._alert(global.locale.login_alert_pass_send);
      })
    } else {
      this._alert(global.locale.login_alert_wrong_filling);
    }
  }

  registration() {
    console.log(this.state.name.length > 2,
      this.state.lastname.length > 2,
      ValidateInput('email', this.state.email),
      this.state.password.length > 5,
      this.state.password == this.state.password2,
      this.state.street.length > 2,
      this.state.city.length > 2,
      this.state.code.length > 2);

    if (this.state.name.length > 2 &&
      this.state.lastname.length > 2 &&
      ValidateInput('email', this.state.email) &&
      this.state.password.length > 5 &&
      this.state.password == this.state.password2 &&
      this.state.street.length > 2 &&
      this.state.city.length > 2 &&
      this.state.code.length > 2
    ) {



      let data = {
        name: this.state.name,
        lastname: this.state.lastname,
        email: this.state.email,
        password: this.state.password,
        phone: this.state.phone,
        ftdate: this.state.ftdate,
        ftdaypart: this.state.ftdaypart,
        fttime: this.state.fttime,
        street: this.state.street,
        city: this.state.city,
        code: this.state.code,
        comment: this.state.comment,
        exclude: this.state.exclude,
        inn: this.state.inn,
        juradrr: this.state.juradrr,
        userType: this.state.usrType,
        fb_token: this.state.fb_token
      }
      this.setState({ loader: true });
      registrationUser(data).then((res) => {
        console.log(res);
        this.setState({ loader: false });
        if (res.success) {
          if (res.response) {
            saveUser(res.response);
            if (this.state.cart) {
              storeData("ftdate", this.state.ftdate);
              this.props.history.push("/cart");
            } else {
              this.props.history.push("/profile");
            }
          } else {
            this._alert(global.locale.login_alert_user_exist);
          }
        } else {
          this._alert(global.locale.login_alert_server_not_available);
        }
      })
    } else if (this.state.name.length <= 2) {
      this._alert(global.locale.login_alert_fill_fields);
    } else if (this.state.lastname.length <= 2) {
      this._alert(global.locale.login_alert_fill_fields);
    } else if (!ValidateInput('email', this.state.email)) {
      this._alert(global.locale.login_alert_fill_fields);
    } else if (this.state.password.length <= 5) {
      this._alert(global.locale.login_alert_fill_fields);
    } else if (this.state.street.length <= 2) {
      this._alert(global.locale.login_alert_fill_fields);
    } else if (this.state.city.length <= 2) {
      this._alert(global.locale.login_alert_fill_fields);
    } else if (this.state.code.length <= 2) {
      this._alert(global.locale.login_alert_fill_fields);
    } else {
      this._alert(global.locale.login_alert_fill_fields);
    }
  }

  registrationFB(_data) {

    if (_data.last_name) {
      this.setState({ loader: true });
      loginUserFB(_data.email, _data.userID).then((res) => {

        this.setState({ loader: false });
        if (res.success) {
          if (res.response) {
            saveUser(res.response);
            this.props.history.push("/profile")
          } else {
            this.setState({
              loginFormActive: 1,
              name: _data.first_name,

              lastname: _data.last_name,
              email: _data.email,

              fb_token: _data.userID
            });
            document.getElementById('name').value = _data.first_name;
            document.getElementById('lastname').value = _data.last_name;
            document.getElementById('email').value = _data.email;
          }
        } else {
          this.setState({
            loginFormActive: 1,
            name: _data.first_name,

            lastname: _data.last_name,
            email: _data.email,

            fb_token: _data.userID
          })
          document.getElementById('name').value = _data.first_name;
          document.getElementById('lastname').value = _data.last_name;
          document.getElementById('email').value = _data.email;
        }
      })
    }
  }

  render() {
    return (
      <div ref={this.divPage} id="page">
        <UiLoader show={this.state.loader} />
        <UiAlert
          show={this.state.showAlert}
          title={this.state.alertTitle}
          text={this.state.alertText}
          callBack={() => this.setState({ showAlert: false })}
          leftBtnText="Ок"
        />
        <div className={"wrapper login-wrapper" + (this.state.showMenuBar ? ' scroll' : '')}>
          <div className="content">
            <UiHeader home profile loginMenu homeMenu menuHeight={this.state.menuHeight} langCallBack={(val) => this.changeLang(val)} />
            <section className="login-title image-bg small-section">
              <div className="image-shadow">
                <div className="container">
                  <h2 className="section-title">{this.state.loginFormActive == 0 ? global.locale.login_enter_profile : global.locale.login_reg}</h2>
                </div>
              </div>
            </section>
            <section className="login-info">
              {this.state.cart ?
                <div className="order-info">
                  <div className="order-steps">
                    <div className="order-step active">
                      <img src={authIcon} />
                      <div className="step-num"><span>1. {global.locale.login_enter}</span></div>
                    </div>
                    <div className="order-step">
                      <img src={orderIcon} />
                      <div className="step-num"><span>2. {global.locale.login_my_order}</span></div>
                    </div>
                  </div>
                </div>
                : null}


              <div className="container">
                <div className="row justify-content-center">

                  {this.state.loginFormActive == 0 ?
                    <div className="col-xl-5 col-lg-6 col-md-8">
                      <div className="card login-card">
                        <div className="form">
                          <div className="social-auth">
                            <div className="social-wrap">

                              <FacebookLogin
                                appId="3449674841733464"
                                //autoLoad
                                fields="email, first_name, name,last_name "
                                callback={(res) => this.registrationFB(res)}
                                render={renderProps => (
                                  <UiBtnBlue btnText={global.locale.login_btn_facebook_enter} onClick={renderProps.onClick} btnsm block />
                                )}
                              />
                              <p className="social-text">{global.locale.login_or}</p>
                            </div>
                          </div>
                          {!this.state.isReset ?
                            <div>
                              <UiFormInput label={global.locale.login_email + ":"} type="email" callBack={(val) => this.setState({ email: val })} />
                              <UiFormInput label={global.locale.login_pass + ":"} type="password" callBack={(val) => this.setState({ password: val })} />
                            </div>
                            :
                            <UiFormInput label={global.locale.login_enter_email + ":"} type="email" callBack={(val) => this.setState({ email: val })} />
                          }


                          <div className="form-group" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {!this.state.isReset ?
                              <button className="btn btn-link ml-3" onClick={() => this.setState({ isReset: true })} >{global.locale.login_forgot_pass}</button>
                              :
                              ""
                            }
                          </div>
                          {!this.state.isReset ?
                            <div className="form-group btn-wrap">
                              <UiBtnGreen onClick={() => this.login()} btnText={global.locale.login_btn_entered} btnsm mr2 mb2 black />
                              <UiBtnGreenOutline onClick={() => this.showRegPage()} btnText={global.locale.login_btn_reg} btnsm mb2 black />
                            </div>
                            :
                            <div className="form-group btn-wrap">
                              <UiBtnGreen onClick={() => this.reset()} btnText={global.locale.login_btn_pass_send} btnsm mr2 mb2 black />
                              <UiBtnGreenOutline onClick={() => this.setState({ isReset: false })} btnText={global.locale.login_btn_cancel} btnsm mb2 black />
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    :
                    <div className="col-xl-9">
                      {/* Регистрация с доставкой */}
                      <div className="delivery-reg">
                        <div className="card login-card">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form">
                                <h3 className="form-title">{global.locale.reg_user_reg}</h3>
                                <div className="reg-types">
                                  <span className={"btn btn-link mr-3" + (this.state.regType == 0 ? " active" : "")} onClick={() => this.setState({ regType: 0, usrType: 0 })}>{global.locale.reg_individual_face}</span>
                                  {/*
                                  <span className={"btn btn-link" + (this.state.regType == 1 ? " active" : "")} onClick={() => this.setState({ regType: 1, usrType: 1 })}>{global.locale.reg_entity_face}</span>
                                  */}
                                </div>
                                {this.state.regType == 0 ?
                                  <div className="reg-type-1">
                                    <div className="form-group">
                                      <label>{global.locale.reg_second_name}:</label>
                                      <input type="text" id="lastname" className="form-control" onChange={(e) => this.setState({ lastname: e.target.value })} />
                                    </div>
                                    <div className="form-group">
                                      <label>{global.locale.reg_name}:</label>
                                      <input type="text" id="name" className="form-control" onChange={(e) => this.setState({ name: e.target.value })} />
                                    </div>
                                  </div>
                                  :
                                  <div className="reg-type-2">
                                    <div className="form-group">
                                      <label>{global.locale.reg_organization_name}</label>
                                      <input type="text" className="form-control" onChange={(e) => this.setState({ name: e.target.value })} />
                                    </div>
                                    <div className="form-group">
                                      <label>{global.locale.reg_INN}</label>
                                      <input type="tel" className="form-control" onChange={(e) => this.setState({ inn: e.target.value })} />
                                    </div>
                                    <div className="form-group">
                                      <label>{global.locale.reg_entity_adress}</label>
                                      <input type="text" className="form-control" onChange={(e) => this.setState({ juradrr: e.target.value })} />
                                    </div>
                                  </div>
                                }
                                <div className="form-group">
                                  <label>{global.locale.reg_email}</label>
                                  <input type="email" id="email" className="form-control" onChange={(e) => this.setState({ email: e.target.value })} />
                                </div>
                                <div className="form-group">
                                  <label>{global.locale.reg_pass}</label>
                                  <input type="password" className="form-control" placeholder={global.locale.profile_pass_6_symbol} onChange={(e) => this.setState({ password: e.target.value })} />
                                </div>
                                <div className="form-group">
                                  <label>{global.locale.reg_again_pass}</label>
                                  <input type="password" className="form-control" placeholder={global.locale.profile_pass_6_symbol} onChange={(e) => this.setState({ password2: e.target.value })} />
                                  {this.state.password != this.state.password2 ? <small className="form-text text-danger">{global.locale.reg_again_pass_wrong}</small> : ""}
                                </div>
                                <h3 className="form-title">{global.locale.reg_question_about_find}</h3>
                                <div className="form-group">
                                  <button className={"radio-reg" + (this.state.activeCheck == 0 ? " active" : "")} onClick={() => this.setState({ activeCheck: 0 })}>
                                    <div className="radio-icon"></div>
                                    <span>Instagram</span>
                                  </button>
                                  <button className={"radio-reg" + (this.state.activeCheck == 1 ? " active" : "")} onClick={() => this.setState({ activeCheck: 1 })}>
                                    <div className="radio-icon"></div>
                                    <span>Facebook</span>
                                  </button>
                                  <button className={"radio-reg" + (this.state.activeCheck == 2 ? " active" : "")} onClick={() => this.setState({ activeCheck: 2 })}>
                                    <div className="radio-icon"></div>
                                    <span>{global.locale.reg_inet_AD}</span>
                                  </button>
                                  <button className={"radio-reg" + (this.state.activeCheck == 3 ? " active" : "")} onClick={() => this.setState({ activeCheck: 3 })}>
                                    <div className="radio-icon"></div>
                                    <span>{global.locale.reg_recommend}</span>
                                  </button>
                                  <button className={"radio-reg" + (this.state.activeCheck == 4 ? " active" : "")} onClick={() => this.setState({ activeCheck: 4 })}>
                                    <div className="radio-icon"></div>
                                    <span>{global.locale.reg_over}</span>
                                  </button>
                                  {this.state.activeCheck == 4 ?
                                    <textarea type="text" className="form-control"></textarea> : null
                                  }
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form">
                                <h3 className="form-title">{global.locale.reg_delivery_info}</h3>
                                <small className="form-text text-muted mb-2">{global.locale.reg_info_task}</small>
                                <div className="form-group">

                                  <label>{global.locale.reg_phone}</label>
                                  <InputMask className="form-control" mask="+420 (999) 999-999" onChange={(e) => {
                                    this.setState({ phone: e.target.value });
                                  }} />

                                </div>
                                {this.state.cart ?
                                  <div className="row">
                                    <div className="col-md-12">
                                      <label>{global.locale.reg_delivery_data}</label>
                                      <div className="row">
                                        <div className="col-md-12 col-lg-8">

                                          <UiSelectDate
                                            minDate={new Date(this.state.minDate)}
                                            excludeDates={this.state.excludeDaysArr}
                                            onChange={(data) => {
                                              this.setState({ ftdate: formatDate(data) })

                                            }}
                                            selected={
                                              new Date(
                                                this.state.ftdate.replace(/-/g, '/')
                                              )
                                            }
                                          />

                                        </div>
                                      </div>
                                      <div className="row align-items-center">
                                        <div className="col-md-5">
                                          <UiSwitch
                                            label={global.locale.switch_evening}
                                            labelActive={global.locale.switch_day}
                                            callBack={(val) => this.setState({ ftdaypart: val })}
                                            disabled
                                          />
                                        </div>
                                        <div className="col-md-7">
                                          <UiSelectScroll
                                            selectedText={this.state.fttime}
                                            selectItems={this.state.firstTimeArr}
                                            callBack={(val) => {
                                              this.setState({ fttime: val.value });
                                            }}
                                          />
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                  : null}
                                <div className="form-group">
                                  <label>{global.locale.reg_street}</label>
                                  <input type="text" className="form-control" id="streetInput" onChange={(e) => this.setState({ street: e.target.value })} />
                                </div>
                                <div className="row">
                                  <div className="col-md-8">
                                    <div className="form-group">
                                      <label>{global.locale.reg_city}</label>
                                      <input type="text" className="form-control" id="cityInput" onChange={(e) => this.setState({ city: e.target.value })} />
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>{global.locale.reg_index}</label>
                                      <input type="text" className="form-control" id="codeInput" value={this.state.code} onChange={(e) => this.setState({ code: e.target.value.replace(/[^0-9]/g, '') })} />
                                    </div>
                                  </div>
                                </div>


                                <div className="form-group">
                                  <label>{global.locale.reg_exclude_text}</label>

                                  <UiSelectScroll
                                    selectedText={this.state.exclude}
                                    selectItems={this.state.excludesList}
                                    selectedList={this.state.exclude}
                                    callBack={(val) => {
                                      this._updateStateVal('exclude', val.value)
                                    }}
                                  />

                                </div>


                                <div className="form-group">
                                  <label>{global.locale.reg_commentary}</label>
                                  <textarea type="text" className="form-control" onChange={(e) => this.setState({ comment: e.target.value })} ></textarea>
                                  <small className="form-text text-muted">{global.locale.reg_dop_info}</small>
                                </div>

                                <div className="form-group btn-wrap mt-3">
                                  <UiBtnGreenOutline btnText={global.locale.reg_btn_cancel} onClick={() => this.setState({ loginFormActive: 0 })} btnsm mb2 mr2 black />
                                  <UiBtnGreen btnText={global.locale.reg_btn_reg} onClick={() => this.registration()} btnsm mb2 black />
                                </div>
                                <div className="form-group">
                                  <small className="form-text text-muted">
                                    {global.locale.reg_agreement_1}
                                    <br />
                                    <a href='/info/terms_of_use.pdf' target="_blank">{global.locale.reg_agreement_3}</a> {global.locale.reg_and} <a href='/info/info_security.docx' target="_blank">{global.locale.reg_agreement_2}</a>
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </section>
            <UiFooter />
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;