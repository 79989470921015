export const ru = {
    lang: 'ru',
    /* Выбор языка */
    lang_title: "Выберите язык",
    /* Dates */
    dates_day_ago: " дней назад",
    dates_day_now: "сегодня",
    dates_day_sun: "вс",
    dates_day_mon: "пн",
    dates_day_tue: "вт",
    dates_day_wed: "ср",
    dates_day_thu: "чт",
    dates_day_fri: "пт",
    dates_day_sat: "сб",
    dates_month_1: "январь",
    dates_month_2: "февраль",
    dates_month_3: "март",
    dates_month_4: "апрель",
    dates_month_5: "май",
    dates_month_6: "июнь",
    dates_month_7: "июль",
    dates_month_8: "август",
    dates_month_9: "сентябрь",
    dates_month_10: "октябрь",
    dates_month_11: "ноябрь",
    dates_month_12: "декабрь",
    dates_month_pril_1: "января",
    dates_month_pril_2: "февраля",
    dates_month_pril_6: "июня",
    dates_month_pril_7: "июля",
    dates_month_pril_8: "августа",
    dates_month_pril_9: "сентября",
    dates_month_pril_10: "октября",
    dates_month_pril_3: "марта",
    dates_month_pril_4: "апреля",
    dates_month_pril_5: "мая",
    dates_month_pril_11: "ноября",
    dates_month_pril_12: "декабря",
    /* LoginPage */
    //Авторизация 
    login_alert_user_exist: "Пользователь c таким email существует.",
    login_alert_user_miss: "Пользователь не найден",
    login_alert_wrong_filling: "Заполните логин или пароль",
    login_alert_pass_send: "На ваш email был отправлен пароль",
    login_alert_server_not_available: "Сервер временно не доступен",
    login_alert_fill_name: "Заполните поле имя",
    login_alert_fill_lastname: "Заполните поле фамилия",
    login_alert_fill_email: "Заполните поле email",
    login_alert_fill_street: "Заполните поле улица",
    login_alert_fill_city: "Заполните поле город",
    login_alert_fill_password: "Заполните поле пароль",
    login_alert_fill_code: "Поле индекс не заполнено",

    //alert message end
    login_enter_profile: "Войти в профиль",
    login_reg: "Зарегистрироваться",
    login_enter: "Вход",
    login_my_order: "Мой заказ",
    login_btn_facebook_enter: "Войти через facebook",
    login_or: "или",
    login_email: "E-mail",
    login_pass: "Пароль",
    login_enter_email: "Введите ваш Е-mail",
    login_forgot_pass: "Забыли пароль?",
    login_btn_reg: "Регистрация",
    login_btn_entered: "Войти",
    login_btn_cancel: "Отмена",
    login_btn_pass_send: "Выслать пароль",
    // Регистрация
    reg_user_reg: "Регистрация пользователя",
    reg_individual_face: "Физическое лицо",
    reg_entity_face: "Юридическое лицо",
    reg_second_name: "Фамилия",
    reg_name: "Имя",
    reg_organization_name: "Название организации:",
    reg_INN: "ИНН:",
    reg_entity_adress: "Юридический адрес:",
    reg_email: "E-mail:",
    reg_pass: "Пароль:",
    reg_again_pass: "Подтвердить пароль:",
    reg_again_pass_wrong: "* Пароли не совпадают",
    reg_question_about_find: "Как вы узнали о нас?",
    reg_inet_AD: "Реклама в интернете",
    reg_recommend: "Рекомендация",
    reg_over: "Другое",
    reg_delivery_info: "Информация о доставке",
    reg_info_task: "Для оформления заказа необходимо добавить информацию о доставке.",
    reg_phone: "Контактный телефон:",
    reg_delivery_data: "Дата первой доставки:",
    reg_street: "Улица, дом:",
    reg_city: "Город:",
    reg_index: "Индекс:",
    reg_commentary: "Комментарий:",
    reg_dop_info: "Дополнительная информация о доставке (номер квартиры, подъезд, домофон, другой комментарый для курьера)",
    reg_agreement_1: "Нажимая кнопку «Регистрация», вы подтверждаете",
    reg_agreement_2: "Соглашение на обработку персональных данных",
    reg_agreement_3: "Пользовательское соглашение",
   
    reg_exclude_1: "Мясо",
    reg_exclude_2: "Рыба и морепродукты",
    reg_exclude_2: "Орехи",
    reg_exclude_3: "Молочные продукты (без лактозы)",
    reg_exclude_4: "Клубника",
    reg_exclude_5: "Грибы",
    reg_exclude_6: "Другое",
    reg_exclude_text: "Исключить из рациона",

    reg_exclude_about: "* Замена одного продукта – бесплатно, замена каждого последующего продукта – 20 Кч/ один продукт в день. Замена молочных продуктов -  40 Кч/ день.</br> Если Вы указали продукт не из предложенного списка, то возможность его исключения будет подтверждена менеджером после получения заказа.</br> Стоимость замены будет добавлена к цене программы после подтверждения заказа нашим менеджером. Фактура будет отправлена до 2 рабочих дней.",

    reg_btn_reg: "Регистрация",
    reg_btn_cancel: "Назад",
    reg_and: "и",
    /* BonusPage */
    bonus_alert_copyed: "Скопированно",
    bonus_header_1: "Профиль iKitchen",
    bonus_header_2: "Бонусы",
    bonus_my_bonus: "Мои бонусы:",
    bonus_barter: "1 бонус = 1 крона",
    bonus_rule_0: "Правила траты бонусов",
    bonus_rule_1: "Нельзя создавать фейковые аккаунты",
    bonus_rule_2: "Вы можете оплатить бонусами не более 20% от общего заказа.",
    bonus_rule_3: "Этот процент может изменяться, в зависимости от целей маркетинговой кампании.",
    bonus_rule_4: "Поделитесь промокодом с друзьями для получения бонусов!",
    bonus_promocode: "Скопируйте промокод отсюда:",
    bonus_btn_promocode: "Скопировать промокод",
    bonus_promocode_rule: "Время действия этого промо-кода 30 дней",
    bonus_info: "Информация о правилах начисления бонусов",
    bonus_info_cashback: "Кэшбек:",
    bonus_info_text1: "С заказа меню на пару дней/неделю/месяц идёт кешбек на счет в виде iKitchen бонусов, как процент с цены (2%)",
    bonus_info_promocode: "Промокод:",
    bonus_info_text2: "Будет генерироваться промокод, который можно дать знакомым и друзьям.После того как друзья сделают и оплатят свой заказ по промокоду, на ваш счет приходит процент% от заказа.Одновременно с этим идет начисление суммы тому, кто заказ тот сделал и оплатил.",
    /* CartPage */
    cart_alert_error: "Ошибка оформления заказа",
    cart_basket: "Корзина iKitchen",
    cart_new_order: "Моя корзина",
    cart_dont_paid: "не оплачен",
    cart_on_day1: "на",
    cart_on_day2: "дней",
    cart_to_paid: "К оплате",
    cart_btn_paid: "Подтвердить заказ",
    cart_currency: "Kč",
    cart_btn_cancel_order: "Удалить из корзины",
    cart_info: "в отмеченные дни вы можете дополнительно настроить меню.",
    cart_menu: "Меню",
    cart_delivery: "Доставка",
    cart_summary_in: "Всего в",
    cart_kkal: "кКал",
    cart_B: "Б",
    cart_J: "Ж",
    cart_U: "У",
    cart_drink: "напиток",
    cart_gramm: "г.",
    cart_breakfast: "Завтрак",
    cart_delivery_data: "Дата первой доставки ",
    cart_delivery_info_1: "Доставка осуществляется в воскресенье, вторник и четверг с рационом на 2 дня.",
    cart_delivery_info_2: "Начало питания с понедельника - заказ до пятницы",
    cart_delivery_info_3: "Начало питания со среды - заказ до воскресенья",
    cart_delivery_info_4: "Начало питания с пятницы - заказ до вторника",
    cart_delivery_from_day: "Доставка по дням ",
    cart_delivery_info_5: "Фотографии блюд на сайте являются вариантом сервировки блюда. Внешний вид блюда может отличаться от фотографии на сайте. Мы сохраняем за собой право замены некоторых ингредиентов в зависимости от их доступности",
    cart_empty_backet: "У вас пока корзина пуста",
    cart_btn_go_to_menu: "Перейти в меню",
    /* HomePage */
    home_kkal: "кКал",
    home_currency: "Kč",
    /* About */
    home_about_header_1: "Здоровая и вкусная еда",
    home_about_header_2: "доставка полного рациона питания на каждый день",
    home_about_text_1: "Вкусно как в ресторане",
    home_about_text_2: "Еда, приготовленная профессиональными поварами премиум класса под руководством нутрициолога",
    home_about_text_3: "Сбалансированное питание",
    home_about_text_4: "Меню составлено в соответствии с дневными нормами КБЖУ. Вам не придется докупать продукты – все необходимое мы привезем",
    home_about_text_5: "Новое меню каждый день",
    home_about_text_6: "Блюда не повторяются в течение месяца",
    home_about_text_7: "Разнообразные программы для каждого",
    home_about_text_8: "Баланс, Веганская, Кето, Антивозрастное питание",
    home_about_text_9: "Современное производство",
    home_about_text_10: "Интеллектуальное оборудование для наилучшего результата",
    home_about_text_11: "Комфортная доставка",
    home_about_text_12: "Доставка до дверей вежливыми курьерами",
    home_about_text_13: "Бонусная система",
    home_about_text_14: "Система скидок",
    home_about_text_15: "Личный кабинет",
    home_about_text_16: "Удобный личный кабинет клиента",
    /* Calculate */
    home_calculate_header_1: "Выбери",
    home_calculate_header_2: "правильный план",
    home_calculate_subtitle: "Подходящий план",
    home_calculate_btn: "К выбранному меню",
    /* Food Menu */
    home_food_menu_header_1: "Сбалансированные планы",
    home_food_menu_header_2: "Твое комплексное меню уже готово",
    home_food_menu_in: "Описание",
    home_food_menu_day: "День недели",
    home_food_menu_text_0: "ПОНЕДЕЛЬНИК",
    home_food_menu_text_1: "Прием пищи",
    home_food_menu_text_2: "ЗАВТРАК",
    home_food_menu_text_3: "Завтрак",
    home_food_menu_text_4: "Перекус",
    home_food_menu_text_5: "Обед",
    home_food_menu_text_6: "Десерт",
    home_food_menu_text_7: "Ужин",
    home_food_menu_option_stop: "Приостановить",

    home_food_menu_option_1: "План питания",
    home_food_menu_option_2: "Настройка порций", 
    home_food_menu_option_3: "Количество человек",
    home_food_menu_option_4: "Количество дней",
    home_food_menu_option_5: "Оплата",
    home_food_menu_option_5_item_1: "Оплачено",
    home_food_menu_option_5_item_2: "Не оплачено",

    home_food_menu_option_4_item_1: "Пн - Сб",
    home_food_menu_option_4_item_2: "Пн - Пт",

    home_food_menu_option_1_item_1: "на 4 дня",
    home_food_menu_option_1_item_2: "на 6 дней",
    home_food_menu_option_1_item_3: "на 12 дней",
    home_food_menu_option_1_item_4: "на 24 дня",

    home_food_menu_option_1_item_5: "на 5 дня",
    home_food_menu_option_1_item_6: "на 10 дней",
    home_food_menu_option_1_item_7: "на 15 дней",
    home_food_menu_option_1_item_8: "на 20 дня",
    
    home_food_menu_option_2_item_1: "все порции",
    home_food_menu_option_2_item_2: "Завтрак + Обед",
    home_food_menu_option_2_item_3: "Завтрак + Ужин",
    home_food_menu_option_2_item_4: "Обед + Полдник",
    home_food_menu_option_2_item_5: "Обед + Ужин",
    home_food_menu_option_2_item_6: "Завтрак + Обед + Ужин",

    home_food_menu_price: "Стоимость",
    home_food_menu_price_text_1: "Ваша выгода составит",
    home_food_menu_price_text_2: "вернем на ваш бонусный счет",
    home_food_menu_btn: "Оформить заказ",
    /* App */
    home_app_header_1: "iKitchen App",
    home_app_header_2: "Скачай приложение",
    home_app_text_1: "Будь всегда на связи, чтобы сделать заказ, оставить нам свой отзыв или выложить фотографии своего обеда в Instagram. Удобное оформление заказа и доставки из любого места.",
    home_app_text_2: "Скачай приложение",
    home_map_header: "Карта доставки",
    home_reviews_header: "Наши клиенты",
    home_faqs_header: "Частые вопросы",
    home_inst_header_1: "Instagram",
    home_inst_header_2: "",
    /* plansPage */
    plans_alert_pay_1: "Вам необходимо будет доплатить",
    plans_alert_pay_2: "курьеру за блюдо",
    plans_alert_save_err: "Невозможно сохранить без указания улицы и номера дома",
    plans_alert_error: "Ошибка обновления",
    plans_transfer_title: "Перенос программы",
    plans_transfer_title_description: "Выберете новый день начала программы",

    plans_pay_title_paid: "Доплата",
    plans_pay_subtitle_paid: "Доплата за замену блюда в программе",
    plans_not_paid: "Не оплачен",
    plans_not_paid_from: "с",
    plans_not_paid_for: "по",
    plans_header: "Профиль iKitchen",
    plans_title: "Активные планы",
    plans_prolongation_title: "Авто продление плана",
    plans_prolongation_btn: "Авто продление",
    plans_replay: "Повторить",
    plans_li_1: "Меню",
    plans_li_2: "Доставка",
    plans_li_3: "Оценки",
    plans_info: "в дни, отмеченные данной иконкой, вы можете дополнительно настроить меню.",
    plans_kkal: "ккал",
    plans_summary_in: "Всего во",
    plans_B: "Б",
    plans_J: "Ж",
    plans_U: "У",
    plans_drink: "напиток",
    plans_currency: "Kč",
    plans_gramm: "г.",
    plans_dinner: "Завтрак",
    plans_text: "Фотографии блюд на сайте являются вариантом сервировки блюда. Внешний вид блюда может отличаться от фотографии на сайте.",
    plans_empty_orders: "У вас пока не было заказов",
    plans_btn_go_to_menu: "Перейти в меню",
    /*ProfilePage*/
    profile_header: "Профиль iKitchen",
    profile_my_data: "Мои данные",
    profile_second_name: "Фамилия",
    profile_name: "Имя",
    profile_organization_name: "Название организации",
    profile_INN: "ИНН",
    profile_entity_adress: "Юридический адрес",
    profile_email: "E-mail",
    profile_change_pass: "Изменить Пароль",
    profile_old_pass: "Старый Пароль",
    profile_new_pass: "Новый Пароль",
    profile_again_pass: "Подтвердить пароль",
    profile_pass_6_symbol: "минимум 6 символов",
    profile_again_pass_wrong: "* Пароли не совпадают",
    profile_delivery_info: "Информация о доставке",
    profile_info_task: "Для оформления заказа необходимо добавить информацию о доставке.",
    profile_phone: "Контактный телефон",
    profile_street: "Улица, дом",
    profile_city: "Город",
    profile_index: "Индекс",
    profile_commentary: "Комментарий",
    profile_dop_info: "Дополнительная информация о доставке (номер квартиры, подъезд, домофон, другой комментарый для курьера)",
    profile_btn_save: "Сохранить",
    profile_agreement: "Соглашение на обработку персональных данных",
    profile_btn_exit: "Выход",
    /*SubscribePage*/
    subscribe_alert: "Для оформления подписки обратитесь к менеджеру",
    subscribe_header: "Профиль iKitchen",
    subscribe_question: "Что мне даёт подписка?",
    subscribe_my_subscribe: "Моя подписка",
    subscribe_miss: "У вас нет активной подписки. Для оформления подписки прикрепите карту ниже",
    subscribe_btn_subscribe_deal: "Прикрепить подписку",
    subscribe_card_info: "Информация о карте",
    subscribe_card_num: "Номер карты",
    subscribe_btn_change_card: "Изменить карту",
    subscribe_discount_info: "Вам предоставляется скидка",
    subscribe_info_1: "Заказ программы на 12 дней, 18 дней или месяц",
    subscribe_info_2: "Скидку х % Kč. при оплате сразу",
    subscribe_info_3: "Возможность оплатить программу сразу полностью",
    subscribe_info_4: "Или оплачивать месячную подписку раз в неделю в определенный день",
    subscribe_faq_header: "Как работает подписка?",
    subscribe_faq_text_1: "На сколько дней можно оформить подписку?",
    subscribe_faq_text_2: "12 дней, 18 дней или месяц",
    subscribe_faq_text_3: "Как оплатить подписку?",
    subscribe_faq_text_4: "Картой через платежную систему",
    subscribe_faq_text_5: "Как идёт списание средств?",
    subscribe_faq_text_6: "Раз в неделю (в субботу/воскресение перед началом новой недели программы) идет списание средств с карты в автоматическом режиме.",
    subscribe_faq_text_7: "Что если нет денег на карте?",
    subscribe_faq_text_8: "Если списание денег не сработало в субботу, то курьер запросит оплату заказа при доставке в воскресение через платежный терминал, с которым он будет ездить, либо запросит оплату наличными.",
    /*UI Module*/
    //uibasketcard
    basketcard_text_1: "Текст описание",
    basketcard_text_2: "завтрак + обед, на 14 дней",
    basketcard_currency: "Kč",
    //UiCalculator
    calculator_gender: "Пол",
    calculator_woman: "женщина",
    calculator_man: "мужчина",
    calculator_age: "Возраст",
    calculator_height: "Рост",
    calculator_sm: "см",
    calculator_weight: "Вес",
    calculator_kg: "кг",
    calculator_back_step: "Назад",
    calculator_next_step: "Следующий шаг",
    calculator_done: "Готово",
    calculator_header_1: "Ваш образ жизни",
    calculator_li_1_item_1: "сидячий образ жизни",
    calculator_li_1_item_2: "небольшие физические нагрузки 1-2 раза в неделю",
    calculator_li_1_item_3: "активные занятия спортом",
    calculator_li_1_item_4: "профессиональный спортсмен",
    calculator_header_2: "Предпочтения в питании",
    calculator_li_2_item_1: "сбалансированное питание",
    calculator_li_2_item_2: "веганское питание",
    calculator_li_2_item_3: "гиппоаллергенное (исключены глютен, орехи, лактоза, яйца)",
    calculator_li_2_item_4: "низкоуглеводная диета (для быстрого похудения)",
    calculator_li_2_item_5: "палео диета",
    calculator_li_2_item_6: "антивозрастное питание",
    calculator_li_2_item_7: "повышение иммунитета",
    calculator_header_3: "Цель",
    calculator_li_3_item_1: "похудеть",
    calculator_li_3_item_2: "правильно питаться",
    calculator_li_3_item_3: "набрать массу",
    //UiDeliveryItem
    delivery_item_delivery: "Доставка порций на",
    delivery_item_day: "утром",
    delivery_item_evening: "вечером",
    delivery_item_adress: "Адрес доставки",
    delivery_item_btn_change: "Изменить",
    delivery_item_wishes: "Пожелания",
    //UiPlanCard
    plan_card_btn_add: "Добавить",
    plan_card_btn_delete: "Удалить",
    plan_card_btn_swap: "Заменить",
    plan_card_composition: "Состав",
    plan_card_in_100_gramm: "в 1 порции",
    plan_card_kkal: "ккал",
    plan_card_B: " Белки",
    plan_card_J: " Жиры",
    plan_card_U: " Углеводы",
    plan_card_btn_pick: "Выбрать",
    plan_card_currency: "Kč",
    //UiRating
    rating_text: "уже оценили",
    //UiPie
    pie_kkal: "кКал",
    pie_gramm: "г",
    pie_B: "Б",
    pie_J: "Ж",
    pie_U: "У",
    //UiFooter
    footer_logo_text: "iKitchen",
    footer_adress: "Адрес",
    footer_adress_text_0: "iCatering s.r.o.",
    footer_adress_text_1: "ID компании: 09092684",
    footer_adress_text_2: "Номер плательщика НДС: CZ09092684",
    footer_adress_text_3: "Evropská 157a, Прага 6",
    footer_adress_text_4: "info@ikitchen.cz",
    footer_phone: "Телефон",
    footer_phone_num: "+420 222 744 130",
    footer_brend: "iKitchen",
    footer_ARR: "Все права защищены - 2020",
    //UiSwitch
    switch_evening: "вечером",
    switch_day: "утром",
    //UiHeader
    header_lang_ru: "Русский",
    header_lang_ch: "Čeština",
    header_lang_en: "English",
    header_about: "О Нас",
    header_calc: "Калькулятор",
    header_faq: "Вопросы и Ответы",
    header_contact: "Контакты",
    header_home: "Главная",
    header_profile: "Мой профиль",
    header_basket: "Моя корзина",
    header_plans: "Активые планы",
    header_subscribe: "Подписка",
    header_preferences: "Мои предпочтения",
    header_bonus: "Бонусы",
    header_btn_menu: "Меню",
    //UiModalChangeAdress
    modal_change_adress_street: "Улица, дом",
    modal_change_adress_city: "Город",
    modal_change_adress_index: "Индекс",
    modal_change_adress_wishes: "Пожелания",
    modal_change_adress_time: "Время",
    modal_change_adress_btn_save: "Сохранить",
    //UiMenuProfile
    menu_profile_profile: "Мой профиль",
    menu_profile_basket: "Моя корзина",
    menu_profile_plans: "Активые планы",
    menu_profile_subscribe: "Подписка",
    menu_profile_preferences: "Мои предпочтения",
    menu_profile_bonus: "Бонусы",
    menu_profile_history:  "История покупок",
    //UiModalPay
    modal_pay_on: "на",
    modal_pay_days: "дней",
    modal_pay_currency: "Kč",
    modal_pay_bonus: "Доступных бонусов для оплаты",
    modal_pay_pay_bonus: "Оплатить бонусами",
    modal_pay_promocode: "Промо-код",
    modal_pay_promocode_not_valid: "Промокод не действителен",
    modal_pay_to_pay: "К оплате",
    modal_pay_NDS: "Включая НДС",
    modal_pay_pay_card: "Оплатить картой",
    modal_pay_placeholder: "Имя владельца карты",
    modal_pay_cash_to_courier: "Наличными курьеру",
    modal_pay_pay_card_to_courier: "Оплата картой курьеру при получении",
    modal_pay_bank_transfer: "Банковский перевод",
    modal_pay_btn_pay: "Оплатить",
    modal_pay_btn_order: "Заказать",
    //UiTopSlider
    home_slider_title_1: "Доставка Здоровой Еды",
    home_slider_text_1: "Текст описание",
    home_slider_title_2: "Доставка Здоровой Еды",
    home_slider_text_2: "Текст описание",
    home_slider_title_3: "Доставка Здоровой Еды",
    home_slider_text_3: "Текст описание",
    home_slider_btn_more: "Подробнее",
    home_slider_btn_plan: "Подобрать план",
    home_slider_btn_deli: "Карта доставки",
    //UiModalMenu
    modal_menu_dinner: "Завтрак",
    modal_menu_kkal: "ккал",
    modal_menu_gramm: "г.",
    modal_menu_kkal: "Заменить второй завтрак во вторник",
    //UiFaq
    faq_slimming: "Выгода",
    faq_products: "Продукты",
    faq_rates: "Программы",
    faq_pay: "Оплата и Доставка",
    faq_Storage: "Хранение",
    /*Выгода*/
    faq_0_question_1: "Сколько времени я сэкономлю?",
    faq_0_question_2: "Придется ли мне дополнительно покупать продукты?",
    faq_0_question_3: "Мне не надоест рацион?",
    faq_0_question_4: "Смогу ли я питаться с iKitchen в путешествии или взять обед на работу?",
    faq_0_answer_1: "До 20 часов в неделю! И все потому, что вам не нужно забивать голову мыслями о еде, покупать ее, готовить и мыть посуду. Просто удалите защитную пленку и поместите контейнер в микроволновую печь на несколько минут. Вкусная и полезная еда готова! Лучше провести свободное время с семьей, общением с друзьями, радоваться жизни и развиваться.",
    faq_0_answer_2: "Ваш холодильник будет заполнен до краев боксами здоровой и вкусной еды. Каждое меню разработано шеф-поваром, поэтому все линии питания сбалансированы в соответствии с КБЖУ. Если Вам чего-то не хватает, вам не нужно идти в магазин. При заказе просто воспользуйтесь конструктором меню и добавьте дополнительные позиции в меню!",
    faq_0_answer_3: "Мы не повторяемся. Каждый день месяца вы едите что-то новое, но всегда из ваших любимых качественных и полезных ингредиентов. Каждый раз, мы будем удивлять Вас новыми и правильными сладостями и десертами. Кроме того, вы всегда можете изменить программу питания или по-другому настроить меню: изменить или добавить блюдо.",
    faq_0_answer_4: "Наши контейнеры герметично упакованы и компактны. Наши блюда легко взять с собой, они удобны в как в плане упаковки для эксплуатации, так и для приема пищи. Например, десерт. Но горячие блюда станут вкуснее, если вы предварительно разогреете их в микроволновой печи. Важно отметить, что для дальних поездок лучше перевозить еду в холодильнике, чтобы она сохранила свою свежесть и вкус.",
    /*продукты*/
    faq_1_question_1: "Кто составляет Ваше меню?",
    faq_1_question_2: "Действительно ли Вами предлагаемый рацион сбалансированный и разнообразный? ",
    faq_1_question_3: "Какие продукты Вы используете?",
    faq_1_answer_1: "Меню составляет профессиональный шеф-повар совместно с нутрициологом.",
    faq_1_answer_2: "Для нас крайне важным является именно разнообразие рациона. Поэтому наше питание богато овощами и фруктами. Кроме того мы предлагаем и здоровые десерты",
    faq_1_answer_3: "Мы используем продукты наивысшего качества. Мы не используем консерванты, искусственные красители, глютен. В блюдах используется минимальное количество сахара, соли. Все продукты мы получаем от проверенных поставщиков, часть ингредиентов мы закупаем у чешских фермеров.",
    /*Программы*/
    faq_2_question_1: "Какие варианты программ Вы предлагаете?",
    faq_2_answer_1: "Мы предлагаем 4 базовые программы (Balance, Vegan, Keto, Anti-age), в которых вы можете выбрать от 4 до 5 специальных пакетов. Balance - эта программа предлагает сбалансированное питание без красителей, консервантов, глютена, а также с низким содержанием лактозысахара и соли. Подходит для тех, кому не нужна специальная диета. Включает пакеты «Lose weight», «Daily», «Fit», «Power» и «Kids».Vegan - Как следует из названия, это программа с веганским рационом, т.е. пища, которая не имеет животного происхождения. Как и программа «Баланс», программа «Веган» включает в себя пакеты «Lose weight», «Daily», «Fit», «Power» и «Kids».Keto - в этой программе Вы найдете кето-диету или диету, богатую белками, такими как молочные продукты, бобовые или курица. Эта программа включает в себя пакеты «Lose weight», «Daily», «Fit», «Power».Anti-age - это антивозрастная программа, включающая в себя определенные продукты, которые помогают нашему организму поддерживать силу и энергию молодости. Он делится на пакеты «Lose weight», «Daily», «Fit».",
    /*оплата и доставка*/
    faq_3_question_1: "Как осуществляется доставка?",
    faq_3_question_2: "Сколько стоит доставка?",
    faq_3_question_3: "Что если я не смогу забрать еду?",
    faq_3_question_4: "Какие Вы предлагаете способы оплаты?",
    faq_3_answer_1: "Доставка осуществляется в воскресенье, вторник, четверг нашей курьерской службой с 18:00 до 23:00. Если вы хотите заказать еду также на воскресенье, доставка будет осуществлена ​​в пятницу или субботу, по договоренности.",
    faq_3_answer_2: "В настоящее время у нас есть бесплатная доставка в Праге и ее окрестностях (Прага-Восток, Прага-Запад), минимальный заказ 1000 крон.",
    faq_3_answer_3: "Если вы заранее знаете, что не можете получить еду от курьера в согласованное время или по утвержденному адресу, сообщите нам об этом как минимум за 24 часа (до 12:00). Таким образом, мы можем изменить адрес и время, заморозить доставку или перенести ее на другой, подходящий для Вас день. Такая мера необходима для осуществления процесса покупки свежих продуктов, приготовления и сбора блюд для Вас, а также внесения изменений в маршрут. В случае форс-мажорных обстоятельств (вы недоступны / вы не отвечаете на телефон / вы не просили оставить пакет с едой на дверной ручке или на стойке регистрации), курьер сможет оставаться на адресе доставки не более 15 минут, после чегоб он будет вынужден выехать на другой заказ. Но Вы всегда сможете забрать еду в нашем пункте выдачи или дополнительно оплатить следующую доставку в тот же день в размере 150 чешских крон. Если вы не свяжетесь с нами в течение дня, мы будем вынуждены утилизировать продукты за ваш счет.",
    faq_3_answer_4: "Вы можете оплатить кредитной картой, банковским переводом или наличными.",
    /*хранение*/
    faq_4_question_1: "Какие условия хранения должны быть соблюдены?",
    faq_4_question_2: "Каким образом можно подогреть еду? ",
    faq_4_answer_1: "Еда доставляется охлажденной и должна храниться в холодильнике",
    faq_4_answer_2: "Некоторые блюда Вы можете съесть охлажденными (салаты, десерты, перекусы). Обогрев определенных блюд осуществляется в микроволновой печи 2-3 мин при 600-700 W.",
}