import React, { Component } from 'react';

class UiAlert extends Component {

    state = {}

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState) { }

    render() {

        return (
            <div className={"alert-wrap " + (this.props.show ? "show" : "hide")}>
                <div className="card alert-card">
                    <div className="alert">
                        {this.props.title != null ? <h3 className="alert-info-title">{this.props.title}</h3> : null}
                        <p className="alert-info-text">{this.props.text}</p>
                    </div>
                    <div className="alert-buttons">
                        {this.props.leftBtnText ? 
                            <button className="btn" onClick={()=> this.props.callBack()}>{this.props.leftBtnText}</button>
                        : null}
                        {this.props.rightBtnText ? 
                            <button className="btn">{this.props.rightBtnText}</button>
                        : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default UiAlert;