import React, { Component } from 'react';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import cs from 'date-fns/locale/cs';

class UiSelectDate extends Component {

    state = {
        selectValue: '166',
        currentIndex: 0,
    }

    constructor(props) {
        super(props);


        registerLocale('cs', cs)
        setDefaultLocale('cs');
    }

    componentWillMount() {

    }
 
    render() {
 
        return (
            <button className="delivery-first"  >
                    <DatePicker
                        excludeDates={this.props.excludeDates}
                        dateFormat="dd-MM-yyyy"
                        onChange={(data) => {
                            this.props.onChange(data)
                        }}
                        selected={this.props.selected}
                        minDate={this.props.minDate}
                    />
            </button>
        );
    }
}
export default UiSelectDate;