import React, { Component } from 'react';
import ReactWOW from 'react-wow';
import UiBtnGreen from '../buttons/UiBtnGreen';
import UiBtnPink from '../buttons/UiBtnPink';

import bg0 from '../../../assets/imgs/slider/pexels-photo-3775561.jpg';
import bg1 from '../../../assets/imgs/slider/pexels-photo-1660030.jpg';
import bg2 from '../../../assets/imgs/slider/pexels-photo-1640777.jpg';

class UiTopSlider extends Component {

  state = {
    activeSlide: 0,
  }

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  _prevSlide() {
    if (this.state.activeSlide > 0) {
      this.setState({ activeSlide: this.state.activeSlide - 1 })
    } else {
      this.setState({ activeSlide: 2 })
    }
  }

  _nextSlide() {
    if (this.state.activeSlide < 2) {
      this.setState({ activeSlide: this.state.activeSlide + 1 })
    } else {
      this.setState({ activeSlide: 0 })
    }
  }

  _scrollToTargetAdjusted(target) {
    var element = document.getElementById(target);
    var headerOffset = 130;
    if (window.innerWidth < 770) headerOffset = 350;
    var elementPosition = element.offsetTop;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  render() {
    return (
      <div className="slider">
        <div className="slide-wrap">
          {this.state.activeSlide == 0 ?
            <ReactWOW delay="0s" animation="fadeIn">
              <div className={"slide" + (this.state.activeSlide == 0 ? " active" : "")} style={{ backgroundImage: "url(" + bg0 + ")" }}>
                <div className="slide-content">
                  <div className="container">
                    <div className="row justify-content-md-center">
                      <div className="col-md-8">
                        <div className="slide-content-text">
                          <ReactWOW delay="0.5s" animation="fadeInLeft">
                            <h2>{global.locale.home_slider_title_1}</h2>
                          </ReactWOW>
                          <ReactWOW delay="0.5s" animation="fadeInLeft">
                            <p>{global.locale.home_slider_text_1}</p>
                          </ReactWOW>
                          <ReactWOW delay="0.5s" animation="fadeInLeft">
                            <div className="btn-wrap">
                              <UiBtnGreen onClick={() => this._scrollToTargetAdjusted('about')} btnText={global.locale.home_slider_btn_more} mr2 white />
                              <UiBtnPink onClick={() => this._scrollToTargetAdjusted('calculate')} btnText={global.locale.home_slider_btn_plan} white />
                            </div>
                          </ReactWOW>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slide-shadow"></div>
              </div>
            </ReactWOW>
            : null
          }
          {this.state.activeSlide == 1 ?
            <ReactWOW delay="0s" animation="fadeIn">
              <div className={"slide" + (this.state.activeSlide == 1 ? " active" : "")} style={{ backgroundImage: "url(" + bg1 + ")" }}>
                <div className="slide-content">
                  <div className="container">
                    <div className="row justify-content-md-center">
                      <div className="col-md-8">
                        <div className="slide-content-text">
                          <ReactWOW delay="0.5s" animation="fadeInLeft">
                            <h2>{global.locale.home_slider_title_2}</h2>
                          </ReactWOW>
                          <ReactWOW delay="0.5s" animation="fadeInLeft">
                            <p>{global.locale.home_slider_text_2}</p>
                          </ReactWOW>
                          <ReactWOW delay="0.5s" animation="fadeInLeft">
                            <div className="btn-wrap">
                              <UiBtnGreen onClick={() => this._scrollToTargetAdjusted('about')} btnText={global.locale.home_slider_btn_more} mr2 white />
                              <UiBtnPink onClick={() => this._scrollToTargetAdjusted('map')} btnText={global.locale.home_slider_btn_deli} white />
                            </div>
                          </ReactWOW>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slide-shadow"></div>
              </div>
            </ReactWOW>
            : null
          }
          {this.state.activeSlide == 2 ?
            <ReactWOW delay="0s" animation="fadeIn">
              <div className={"slide" + (this.state.activeSlide == 2 ? " active" : "")} style={{ backgroundImage: "url(" + bg2 + ")" }}>
                <div className="slide-content">
                  <div className="container">
                    <div className="row justify-content-md-center">
                      <div className="col-md-8">
                        <div className="slide-content-text">
                          <ReactWOW delay="0.5s" animation="fadeInLeft">
                            <h2>{global.locale.home_slider_title_3}</h2>
                          </ReactWOW>
                          <ReactWOW delay="0.5s" animation="fadeInLeft">
                            <p>{global.locale.home_slider_text_3}</p>
                          </ReactWOW>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slide-shadow"></div>
              </div>
            </ReactWOW>
            : null
          }

        </div>
        <div className="slide-control">
          <ReactWOW delay="0.5s" animation="fadeIn">
            <button className="slide-btn" onClick={() => this._prevSlide()}>
              <img className="slide-btn-img" src={require('../../../assets/imgs/ui/up-arrow.svg')} />
            </button>
            <div className="btn-slide-wrap">
              <button className={"btn-slide slide-0" + (this.state.activeSlide == 0 ? " active" : "")} onClick={() => this.setState({ activeSlide: 0 })}>
                {this.state.activeSlide == 0 ? 
                  <img src={require('../../../assets/imgs/slider/small/salad.svg')} /> :
                  <img src={require('../../../assets/imgs/slider/small/salad-white.svg')} />
                }
              </button>
            </div>
            <div className="btn-slide-wrap">
              <button className={"btn-slide slide-1" + (this.state.activeSlide == 1 ? " active" : "")} onClick={() => this.setState({ activeSlide: 1 })}>
                {this.state.activeSlide == 1 ? 
                  <img src={require('../../../assets/imgs/slider/small/truck.svg')} /> :
                  <img src={require('../../../assets/imgs/slider/small/truck-white.svg')} />
                }
              </button>
            </div>
            <div className="btn-slide-wrap">
              <button className={"btn-slide slide-2" + (this.state.activeSlide == 2 ? " active" : "")} onClick={() => this.setState({ activeSlide: 2 })}>
                {this.state.activeSlide == 2 ?
                  <img src={require('../../../assets/imgs/slider/small/fresh.svg')} /> :
                  <img src={require('../../../assets/imgs/slider/small/fresh-white.svg')} />
                }
              </button>
            </div>
            <button className="slide-btn" onClick={() => this._nextSlide()}>
              <img className="slide-btn-img" src={require('../../../assets/imgs/ui/down-arrow.svg')} />
            </button>
          </ReactWOW>
        </div>
      </div>
    );
  }
}
export default UiTopSlider;