import ENV from "../services/Env.js";

export const storeData = (key, value) => {
  let val = JSON.stringify(value);
  return localStorage.setItem(key, val);
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const retrieveData = (key) => {
  return isJson(localStorage.getItem(key))
    ? JSON.parse(localStorage.getItem(key))
    : null;
};

export const uploadImageAsync = (_token, uri) => {
  let _filename = uri.split("/").pop();
  const uriParts = uri.split(".");
  const fileType = uriParts[uriParts.length - 1];
  _filename = _filename.split(".");
  let formData = new FormData();
  let apiUrl =
    ENV.API_URL +
    "/files/upload?module=ROUTE_OFFERS&entityName=" +
    _filename[0];
  let fileTypeExt = "image";
  if (
    fileType == "jpg" ||
    fileType == "jpeg" ||
    fileType == "png" ||
    fileType == "gif"
  ) {
    fileTypeExt = "image";
  } else {
    fileTypeExt = "application";
  }

  formData.append("file", {
    uri,
    extension: fileType,
    type: fileTypeExt + `/${fileType}`,
    name: _filename[0] + "." + fileType,
  });
  console.log(formData);
  return fetch(apiUrl, {
    method: "POST",
    body: formData,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + _token,
    },
  }).then((response) => response.json());
};

export const checkDublePromo = (_promocode) => {
  let f = false;
  let list = isJson(localStorage.getItem("_promocode_ik"))
    ? JSON.parse(localStorage.getItem("_promocode_ik"))
    : [];
  if(list){
    list.map((item) => {
      if (item == _promocode) f = true;
    });
  } 

  return f;
};

export const addPromocodeToStorage = (_promocode) => {
  let list = isJson(localStorage.getItem("_promocode_ik"))
    ? JSON.parse(localStorage.getItem("_promocode_ik"))
    : [];
  if(list == null)  list = [];
  list.push(_promocode);
  let val = JSON.stringify(list);
  localStorage.setItem("_promocode_ik", val);
};
