import React, { Component } from 'react';

import UiHeader from '../../components/ui/header/UiHeader.js';
import UiFooter from '../../components/ui/footer/UiFooter.js';
import UiModalPay from '../../components/ui/modals/UiModalPay.js';
import UiModalMenu from '../../components/ui/modals/UiModalMenu.js';
import UiBtnGreenOutline from '../../components/ui/buttons/UiBtnGreenOutline';
import UiLoader from '../../components/ui/modals/UiLoader';
import UiMenuProfile from '../../components/ui/tabs/UiMenuProfile';
import UiSelectScroll from '../../components/ui/forms/UiSelectScroll';

import { en } from '../../i18n/en.js';
import { ru } from '../../i18n/ru.js';
import { ch } from '../../i18n/ch.js';

import InputMask from 'react-input-mask';

import { loadUser, saveUser, updateUser, getOrders, getProfile } from '../../services/Users';
import { getExcludesPrice } from "../../services/System";
import { retrieveData, storeData } from '../../services/Storage';

const AnyReactComponent = () => (
  <div classNameName='marker'></div>
);
const google = window.google;

class ProfilePage extends Component {

  state = {
    loader: false,
    plansNum: 1,
    activePlanTab: 0,
    activeItem: 0,
    activeOrderTab: 0,
    menuHeight: 0,
    subscribeActiviti: 1,
    subscribeItem: 0,

    excludesList: [],

    user: {
      api_token: "",
      city: "",
      code: "",
      comment: "",
      exclude: "",
      ftdate: "",
      ftdaypart: "",
      inn: null,
      is_company: 0,
      juradrr: null,
      lname: "",
      mail: "",
      name: "",
      password: "",
      old_password: "",
      phone: "",
      street: "",
    }
  }

  constructor(props) {
    super(props);
    let _lang = retrieveData("ikLocale");
    if (_lang == null) _lang = 'ch';
    if (_lang == 'ch') global.locale = ch;
    if (_lang == 'ru') global.locale = ru;
    if (_lang == 'en') global.locale = en;

    let _user = loadUser();
    this.setState({ lang: _lang, user: _user });
  }

  changeLang(val) {
    let _lang = 'ch';
    if (val == 0) _lang = 'ru';
    if (val == 1) _lang = 'ch';
    if (val == 2) _lang = 'en';
    if (_lang == 'ch') global.locale = ch;
    if (_lang == 'ru') global.locale = ru;
    if (_lang == 'en') global.locale = en;
    this.setState({ lang: _lang });
    storeData("ikLocale", _lang);
    //this._load();
  }

  componentDidMount() {
    this._load();
    window.addEventListener('scroll', () => {
      //if (document.documentElement.scrollTop > 200) this.setState({ showMenuBar: true }); else this.setState({ showMenuBar: false });
    });
    window.addEventListener('resize', () => {
      this.setState({
        menuHeight: document.getElementById('root').offsetHeight,
      });
    });

    google.maps.event.addDomListener(window, 'load', this.initialize());
  }

  initialize() {
    var input = document.getElementById('searchTextField');
    var options = {
      types: ['geocode']
    };
    var autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.setFields(['address_component']);
    autocomplete.addListener('place_changed', () => {
      var componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name'
      };

      var place = autocomplete.getPlace();
      if (place) {
        if (place.address_components) {
          for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (componentForm[addressType]) {
              var val = place.address_components[i][componentForm[addressType]];
              componentForm[addressType] = val;
              if (componentForm.route != 'long_name') {
                document.getElementById("searchTextField").value = componentForm.route + ", " + componentForm.street_number;
                this.setState({ street: componentForm.route + ", " + componentForm.street_number })
              }
              if (componentForm.postal_code != 'short_name') {
                document.getElementById("codeTextField").value = componentForm.postal_code;
                this.setState({ code: componentForm.postal_code });
              }
              if (componentForm.locality != 'long_name') {
                document.getElementById("cityTextField").value = componentForm.locality;
                this.setState({ city: componentForm.locality });
              }
              if (componentForm.locality == 'long_name' && componentForm.administrative_area_level_1 != 'short_name') {
                document.getElementById("cityTextField").value = componentForm.administrative_area_level_1;
                this.setState({ city: componentForm.administrative_area_level_1 });
              }
            }
          }
        }
      }
      console.log(place, componentForm);
    });
  }

  _updateStateVal(_tt, _val) {
    let exArr = [];
    let st = this.state.user;
    if (st == null) st = {};
  
    if(_tt == "exclude"){
      if(_val == null) {
        st[_tt] = "";
      } else {

        let excludeItem = st[_tt] ? st[_tt] : "";
        exArr = excludeItem ? excludeItem.split(",") : [];
        
        if(!excludeItem.includes(_val)) { 
          exArr.push(_val); 
        } else {
          let i = 0;
          exArr.map((item, index) => {
            if(item == _val) i = index;
          })
          exArr.splice(i,1); 
        
        }  
        st[_tt] = exArr.join(","); 
      }
     
    } else {
      st[_tt] = _val;
    }
   
    this.setState({ user: st })
  }

  _load() {
    this.setState({ menuHeight: document.getElementById('root').offsetHeight });
    this.setState({ loader: false });
    let _user = loadUser();
    this.setState({ user: _user });

    getExcludesPrice().then((res) => {
      if(res.response){
        let arr =[{value: null, text: global.locale.reg_exclude_text2 }];
        res.response.map((item) => {
          arr.push({ value: item.name, text: item.name+" +"+item.value+"Kč/den"});
        })
        this.setState({ excludesList: arr });
      } 
    })
   
    if (_user) {
      getOrders(_user.api_token, _user.id).then((res) => {
        console.log(_user.api_token, _user.id, res)
        if (res.success) {

        } else {
          saveUser(null);
          this.props.history.push("/login");
        }
      });

      getProfile(_user.api_token ).then((res) => {
        console.log("getProfile",   res)
        if (res.success && res.response.length > 0) {
          this.setState({ user: res.response[0] });
        } else {
          
        }
      });

    } else {
      saveUser(null);
      this.props.history.push("/login");
    
    }
  }

  logout() {
    saveUser(null);
    this.props.history.push("/");
  }

  updateProfile() {

    if (this.state.user.phone.length > 8 && this.state.user.name.length > 2) {
      this.setState({ loader: true });
      updateUser(this.state.user.api_token, this.state.user).then((res) => {
        console.log(res);
        this.setState({ loader: false });
        if (res.success) {
          if (res.response) {
            saveUser(this.state.user);
          }
        }
      }).catch((res) => {
        console.log(res);
      })
    }

  }

  render() {
    return (
      <div ref={this.divPage} id="page">
        <UiLoader show={this.state.loader} />

        <UiModalMenu show={false} />
        <UiModalPay show={false} />

        <div className={"wrapper login-wrapper" + (this.state.showMenuBar ? ' scroll' : '')}>
          <div className="content">
            <UiHeader home profile menuHeight={this.state.menuHeight} langCallBack={(val) => this.changeLang(val)} />
            <section className="profile-title image-bg small-section">
              <div className="image-shadow">
                <div className="container">
                  <h2 className="section-title">{global.locale.profile_header}</h2>
                </div>
              </div>
            </section>

            <section className="profile-wrap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-xl-3">
                    <UiMenuProfile activeItem={0} callBack={(val) => {
                      if (val == 0) this.props.history.push("/profile");
                      if (val == 1) this.props.history.push("/cart");
                      if (val == 2) this.props.history.push("/plans");
                      if (val == 3) this.props.history.push("/subscribe");
                      if (val == 4) this.props.history.push("/history");

                      if (val == 5) this.props.history.push("/bonus");
                    }} />
                  </div>

                  <div className="col-md-12 col-lg-8 col-xl-9">

                    {/* Регистрация с доставкой */}
                    <div className="delivery-reg">
                      <div className="card login-card">
                        <div className="row">

                          <div className="col-md-6">
                            <div className="form">
                              <h3 className="form-title">{global.locale.profile_my_data}</h3>
                              <div className="reg-type-1">
                                <div className="form-group">
                                  <label>{global.locale.profile_second_name}:</label>
                                  <input type="text" placeholder={this.state.user.lname} onChange={e => this._updateStateVal('lname', e.target.value)} className="form-control" />
                                </div>
                                <div className="form-group">
                                  <label>{global.locale.profile_name}:</label>
                                  <input type="text" placeholder={this.state.user.name} onChange={e => this._updateStateVal('name', e.target.value)} className="form-control" />
                                </div>
                              </div>
                              <div className="form-group">
                                <label>{global.locale.profile_email}:</label>
                                <input type="email" placeholder={this.state.user.mail} onChange={e => this._updateStateVal('mail', e.target.value)} className="form-control" />
                              </div>
                              <h3 className="form-title">{global.locale.profile_change_pass}</h3>
                              <div className="form-group">
                                <label>{global.locale.profile_old_pass}:</label>
                                <input type="password" className="form-control" onChange={e => this.setState({ password: e.target.value })} placeholder={global.locale.profile_pass_6_symbol} />
                              </div>
                              <div className="form-group">
                                <label>{global.locale.profile_new_pass}:</label>
                                <input type="password" className="form-control" disabled={this.state.user.password != this.state.password} onChange={e => this._updateStateVal('password', e.target.value)} placeholder={global.locale.profile_pass_6_symbol} />
                              </div>
                              <div className="form-group">
                                <label>{global.locale.profile_again_pass}:</label>
                                <input type="password" className="form-control" disabled={this.state.user.password != this.state.password} onChange={e => this._updateStateVal('password', e.target.value)} placeholder={global.locale.profile_pass_6_symbol} />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form">
                              <h3 className="form-title">{global.locale.profile_delivery_info}</h3>
                              <small className="form-text text-muted mb-2">{global.locale.profile_info_task}</small>
                              <div className="form-group">
                                <label>{global.locale.profile_phone}:</label>

                                <InputMask className="form-control" mask="+420 (999) 999-999"
                                  value={this.state.user.phone}
                                  onChange={e => this._updateStateVal('phone', e.target.value)}
                                />

                              </div>
                              <div className="form-group">
                                <label>{global.locale.profile_street}:</label>
                                <input type="text" id="streetInput" placeholder={this.state.user.street} onChange={e => this._updateStateVal('street', e.target.value)} className="form-control" />
                              </div>
                              <div className="row">
                                <div className="col-md-8">
                                  <div className="form-group">
                                    <label>{global.locale.profile_city}:</label>
                                    <input type="text" id="cityInput" onChange={e => this._updateStateVal('city', e.target.value)} placeholder={this.state.user.city} className="form-control" />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <label>{global.locale.profile_index}:</label>
                                    <input type="text" id="codeInput" value={this.state.user.code} onChange={e => this._updateStateVal('code', e.target.value.replace(/[^0-9]/g, '')  )} placeholder={this.state.user.code} className="form-control" />
                                  </div>
                                </div>
                              </div>

                              <div className="form-group">
                                <label>{global.locale.reg_exclude_text}</label>
                              
                                  <UiSelectScroll
                                    selectedText={this.state.user.exclude}
                                    selectItems={this.state.excludesList}
                                    selectedList={this.state.user.exclude}
                                    callBack={(val) => {
                                        this._updateStateVal('exclude', val.value )
                                    }}
                                  />  

                              </div>

                              <div className="form-group">
                                <label>{global.locale.profile_commentary}:</label>
                                <textarea type="text" className="form-control" defaultValue={this.state.user.comment} onChange={e => this._updateStateVal('comment', e.target.value)} ></textarea>
                                <small className="form-text text-muted">{global.locale.profile_dop_info}</small>
                              </div>
                              <div className="form-group btn-wrap mt-3">
                                <UiBtnGreenOutline onClick={() => this.updateProfile()} btnText={global.locale.profile_btn_save} btnsm mb2 black />
                              </div>
                              <div className="form-group">
                                <a href='/info/info_security.docx' target="_blank">{global.locale.profile_agreement}</a>
                              </div>
                              <div className="form-group btn-wrap mt-3">
                                <UiBtnGreenOutline onClick={() => this.logout()} btnText={global.locale.profile_btn_exit} btnsm mb2 black />
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </section>

            <UiFooter />
          </div>
        </div>
      </div>
    );
  }
}

export default ProfilePage;