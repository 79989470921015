import React, { Component } from 'react';

import shareIcon from '../../../assets/imgs/ui/share-white.svg';

class UiInstCard extends Component {

  state = {}

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    return (
      <div className="inst-card" onClick={() => window.location.href = this.props.url }>
        <div className="inst-card-inner">
          <img src={this.props.img} />
        </div>
        <div className="inst-card-hover">
          <div className="overlay">
            <img src={shareIcon} />
          </div>
        </div>
      </div>
    );
  }
}
export default UiInstCard;