import React, { Component } from 'react';
import ReactWOW from 'react-wow';

import placeIcon from '../../../assets/imgs/ui/header/pin.svg';
import downIcon from '../../../assets/imgs/ui/select-arrow.svg';
import phoneIcon from '../../../assets/imgs/ui/header/call.svg';
import maleIcon from '../../../assets/imgs/ui/header/email.svg';
import menuIcon from '../../../assets/imgs/ui/header/menu.svg';
import userIcon from '../../../assets/imgs/ui/header/user.svg';
import basketHeadIcon from '../../../assets/imgs/ui/header/online-shopping.svg';

import profileIcon from '../../../assets/imgs/profile/user.svg';
import profileIconActive from '../../../assets/imgs/profile/user-white.svg';
import basketIcon from '../../../assets/imgs/profile/shopping.svg';
import basketIconActive from '../../../assets/imgs/profile/shopping-white.svg';
import planIcon from '../../../assets/imgs/profile/timetable.svg';
import planIconActive from '../../../assets/imgs/profile/timetable-white.svg';
import subIcon from '../../../assets/imgs/profile/history.svg';
import subIconActive from '../../../assets/imgs/profile/history-white.svg';
import favIcon from '../../../assets/imgs/profile/list.svg';
import favIconActive from '../../../assets/imgs/profile/list-white.svg';
import bonusIcon from '../../../assets/imgs/profile/percentage.svg';
import bonusIconActive from '../../../assets/imgs/profile/percentage-white.svg';

import UiBtnGreen from '../buttons/UiBtnGreen';

import { retrieveData } from '../../../services/Storage';

class UiHeader extends Component {

  state = {
    cartCount: 0,
    rightMenuOpen: false,
    langShow: false,
    selectedLang: 0,
  }

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  componentDidMount() {
    let _cart = retrieveData("ikCart");
    if (_cart != null) {
      this.setState({
        rightMenuOpen: false,
        cartCount: 1,
      })
    }

    let _lang = retrieveData("ikLocale");
    console.log(_lang)
    if (_lang == null) _lang = 'ch';
    if (_lang == 'ch') this.setState({ selectedLang: 1 });
    if (_lang == 'ru') this.setState({ selectedLang: 0 });
    if (_lang == 'en') this.setState({ selectedLang: 2 });
  }

  _scrollToTargetAdjusted(target) {
    var element = document.getElementById(target);
    var headerOffset = 130;
    if (window.innerWidth < 991) headerOffset = 58;
    var elementPosition = element.offsetTop;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  render() {
    return (
      <header>
        <div className="header-wrap">
          <div className="header-full">
            <ReactWOW animation="fadeIn">
              <div className="top-info-wrap">
                <div className="container">
                  <div className="top-info">
                    <div className="top-info-contacts">
                      <button className="contact-item" onClick={() => this.setState({ langShow: !this.state.langShow })}>
                        <img className="icon-left" src={placeIcon} />
                        {this.state.selectedLang == 0 ?
                          <span>{global.locale.header_lang_ru}</span> : null
                        }
                        {this.state.selectedLang == 1 ?
                          <span>{global.locale.header_lang_ch}</span> : null
                        }
                        {this.state.selectedLang == 2 ?
                          <span>{global.locale.header_lang_en}</span> : null
                        }
                        <img className="icon-right" src={downIcon} />
                      </button>
                      <div className={"lang-list" + (this.state.langShow ? " show" : " hide")}>
                        <a className="lang-item" onClick={() => this.setState({ selectedLang: 1, langShow: false }, () => { if (this.props.langCallBack) this.props.langCallBack(1) })}>{global.locale.header_lang_ch}</a>
                       {/* <a className="lang-item" onClick={() => this.setState({ selectedLang: 0, langShow: false }, () => { if (this.props.langCallBack) this.props.langCallBack(0) })}>{global.locale.header_lang_ru}</a> */} 
                        <a className="lang-item" onClick={() => this.setState({ selectedLang: 2, langShow: false }, () => { if (this.props.langCallBack) this.props.langCallBack(2) })}>{global.locale.header_lang_en}</a>
                      </div>
                      <div className="contact-item">
                        <img className="icon-left" src={phoneIcon} />
                        <strong><a href="tel:+420 222 744 130">+420 222 744 130</a></strong>
                      </div>
                      <div className="contact-item">
                        <img className="icon-left" src={maleIcon} />
                        <span><a href="mailto:info@ikitchen.cz">info@ikitchen.cz</a></span>
                      </div>
                    </div>
                    <div className="top-info-profile">
                      <a className="profile-item" href="/login">
                        <img className="basket-icon" src={userIcon} />
                      </a>
                      <a className="profile-item" href="/cart">
                        <img className="basket-icon" src={basketHeadIcon} />
                        <span>{this.state.cartCount}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ReactWOW>
            <div className="menu-wrap">
              <div className="container">
                <div className="menu">
                  <div className="logo">
                    <ReactWOW animation="fadeIn">
                      <div className="logo-text">
                        {this.props.home ?
                          <a href="/"><img src={require('../../../assets/imgs/logo.svg')} /></a>
                          :
                          <a href="/profile"><img src={require('../../../assets/imgs/logo.svg')} /></a>
                        }

                      </div>
                    </ReactWOW>
                  </div>
                  {!this.props.profile ?
                    <ReactWOW animation="fadeIn">
                      <ul className="menu-list">
                        <li className="menu-item"><a onClick={() => this._scrollToTargetAdjusted('about')}>{global.locale.header_about}</a></li>
                        <li className="menu-item"><a onClick={() => this._scrollToTargetAdjusted('calculate')}>{global.locale.header_calc}</a></li>
                        <li className="menu-item"><a onClick={() => this._scrollToTargetAdjusted('faq')}>{global.locale.header_faq}</a></li>
                        <li className="menu-item"><a onClick={() => this._scrollToTargetAdjusted('contact')}>{global.locale.header_contact}</a></li>
                      </ul>
                    </ReactWOW>
                    : null}
                  {!this.props.profile ?
                    <ReactWOW animation="fadeIn">
                      <UiBtnGreen onClick={() => this._scrollToTargetAdjusted('menu')} btnText={global.locale.header_btn_menu} black />
                    </ReactWOW>
                    : null}
                </div>
              </div>
            </div>
          </div>
          <div className="header-mobile">
            <div className="header-mobile-wrap">
              <div className="header-left">
                <div className="logo">
                  <a href="/">
                    <div className="logo-text">
                      <img className="img" src={require('../../../assets/imgs/logo-img.svg')} />
                      <img src={require('../../../assets/imgs/logo-white.svg')} />
                    </div>
                  </a>
                </div>
              </div>
              <div className="header-right">
                <button className="menu-btn" onClick={() => this.setState({ rightMenuOpen: !this.state.rightMenuOpen })}>
                  <img className="basket-icon" src={menuIcon} />
                </button>
              </div>
            </div>
            <div className={"menu-mobile-wrap" + (this.state.rightMenuOpen ? " active" : "")} style={{ height: this.props.menuHeight }}>
              {this.props.homeMenu ?
                <div className="profile-menu">
                  <a href="/" onClick={() => this.setState({ activeItem: 0 })} className={"menu-item" + (this.state.activeItem == "0" ? " active" : "")}>
                    <h4>{global.locale.header_home}</h4>
                  </a>
                  {this.props.loginMenu ?
                    null :
                    <div className="home-menu-wrap">
                      <a onClick={() => { this.setState({ activeItem: 1, rightMenuOpen: false }); this._scrollToTargetAdjusted('about'); }} className={"menu-item" + (this.state.activeItem == "1" ? " active" : "")}>
                        <h4>{global.locale.header_about}</h4>
                      </a>
                      <a onClick={() => { this.setState({ activeItem: 2, rightMenuOpen: false }); this._scrollToTargetAdjusted('calculate'); }} className={"menu-item" + (this.state.activeItem == "2" ? " active" : "")}>
                        <h4>{global.locale.header_calc}</h4>
                      </a>
                      <a onClick={() => { this.setState({ activeItem: 3, rightMenuOpen: false }); this._scrollToTargetAdjusted('faq'); }} className={"menu-item" + (this.state.activeItem == "3" ? " active" : "")}>
                        <h4>{global.locale.header_faq}</h4>
                      </a>
                      <a onClick={() => { this.setState({ activeItem: 4, rightMenuOpen: false }); this._scrollToTargetAdjusted('contact'); }} className={"menu-item" + (this.state.activeItem == "4" ? " active" : "")}>
                        <h4>{global.locale.header_contact}</h4>
                      </a>
                    </div>
                  }

                  <a href="/profile" onClick={() => this.setState({ activeItem: 5 })} className={"menu-item" + (this.state.activeItem == "5" ? " active" : "")}>
                    {this.state.activeItem == "5" ?
                      <img src={profileIconActive} /> :
                      <img src={profileIcon} />
                    }
                    <h4>{global.locale.header_profile}</h4>
                  </a>
                  <a href="/cart" onClick={() => this.setState({ activeItem: 6 })} className={"menu-item" + (this.state.activeItem == "6" ? " active" : "")}>
                    {this.state.activeItem == "6" ?
                      <img src={basketIconActive} /> :
                      <img src={basketIcon} />
                    }
                    <h4>{global.locale.header_basket}</h4>
                  </a>
                  <div className="mobile-lang">
                    <a className={"mobile-lang-item" + (this.state.selectedLang == 1 ? " active" : "")} onClick={() => this.setState({ selectedLang: 1, langShow: false }, () => { if (this.props.langCallBack) this.props.langCallBack(1) })}>{global.locale.header_lang_ch}</a>
                    {/* 
                    <a className={"mobile-lang-item" + (this.state.selectedLang == 0 ? " active" : "")} onClick={() => this.setState({ selectedLang: 0, langShow: false }, () => { if (this.props.langCallBack) this.props.langCallBack(0) })}>{global.locale.header_lang_ru}</a>
                    */}
                    <a className={"mobile-lang-item" + (this.state.selectedLang == 2 ? " active" : "")} onClick={() => this.setState({ selectedLang: 2, langShow: false }, () => { if (this.props.langCallBack) this.props.langCallBack(2) })}>{global.locale.header_lang_en}</a>
                  </div>
                </div>
                :
                <div className="profile-menu">
                  <a href="/" onClick={() => this.setState({ activeItem: 0 })} className={"menu-item" + (this.state.activeItem == "0" ? " active" : "")}>
                    <h4>{global.locale.header_home}</h4>
                  </a>
                  <a href="/profile" onClick={() => this.setState({ activeItem: 1 })} className={"menu-item" + (this.state.activeItem == "1" ? " active" : "")}>
                    {this.state.activeItem == "1" ?
                      <img src={profileIconActive} /> :
                      <img src={profileIcon} />
                    }
                    <h4>{global.locale.header_profile}</h4>
                  </a>
                  <a href="/cart" onClick={() => this.setState({ activeItem: 2 })} className={"menu-item" + (this.state.activeItem == "2" ? " active" : "")}>
                    {this.state.activeItem == "2" ?
                      <img src={basketIconActive} /> :
                      <img src={basketIcon} />
                    }
                    <h4>{global.locale.header_basket}</h4>
                  </a>
                  <a href="/plans" onClick={() => this.setState({ activeItem: 3 })} className={"menu-item" + (this.state.activeItem == "3" ? " active" : "")}>
                    {this.state.activeItem == "3" ?
                      <img src={planIconActive} /> :
                      <img src={planIcon} />
                    }
                    <h4>{global.locale.header_plans}</h4>
                  </a>
                  {/*
                      <a href="/subscribe" onClick={() => this.setState({ activeItem: 4 })} className={"menu-item" + (this.state.activeItem == "4" ? " active" : "")}>
                    {this.state.activeItem == "4" ?
                      <img src={subIconActive} /> :
                      <img src={subIcon} />
                    }
                    <h4>{global.locale.header_subscribe}</h4>
                  </a>
                  */}
                  <a href="/history" onClick={() => this.setState({ activeItem: 4 })} className={"menu-item" + (this.state.activeItem == "4" ? " active" : "")}>
                    {this.state.activeItem == "4" ?
                      <img src={subIconActive} /> :
                      <img src={subIcon} />
                    }
                    <h4>{global.locale.menu_profile_history}</h4>
                  </a>

                  <a href="/bonus" onClick={() => this.setState({ activeItem: 5 })} className={"menu-item" + (this.state.activeItem == "5" ? " active" : "")}>
                    {this.state.activeItem == "5" ?
                      <img src={bonusIconActive} /> :
                      <img src={bonusIcon} />
                    }
                    <h4>{global.locale.header_bonus}</h4>
                  </a>
                  <div className="mobile-lang">
                    <a className={"mobile-lang-item" + (this.state.selectedLang == 1 ? " active" : "")} onClick={() => this.setState({ selectedLang: 1, langShow: false }, () => { if (this.props.langCallBack) this.props.langCallBack(1) })}>{global.locale.header_lang_ch}</a>
                    {/*
                    <a className={"mobile-lang-item" + (this.state.selectedLang == 0 ? " active" : "")} onClick={() => this.setState({ selectedLang: 0, langShow: false }, () => { if (this.props.langCallBack) this.props.langCallBack(0) })}>{global.locale.header_lang_ru}</a>
                    */}
                    <a className={"mobile-lang-item" + (this.state.selectedLang == 2 ? " active" : "")} onClick={() => this.setState({ selectedLang: 2, langShow: false }, () => { if (this.props.langCallBack) this.props.langCallBack(2) })}>{global.locale.header_lang_en}</a>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default UiHeader;