import React, { Component } from 'react';

import upArrow from '../../../assets/imgs/ui/up.svg';
import downArrow from '../../../assets/imgs/ui/down.svg';

class UiWeekSelect extends Component {

  state = {
    selectedDayIndex: 0,
    selectedListStartIndex: 0,
    selectedListEndIndex: 8,
  }

  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if (this.props.selectedDayIndex != undefined && this.props.selectedDayIndex) {
      this.setState({ selectedDayIndex: this.props.selectedDayIndex });
    }
  }
  componentDidUpdate(nProps, nState) {
    if (nProps.clear != undefined && nProps.clear) {
      this.setState({ selectedDayIndex: null });
      this.props.clearCallBack(!nProps.clear);
    }
    if (this.props.selectedDayIndex != undefined && this.props.selectedDayIndex && nProps.selectedDayIndex != this.state.selectedDayIndex) {
      this.setState({ selectedDayIndex: this.props.selectedDayIndex });
    }
    return true;
  }

  setValue = (val, index) => {
    this.setState({ selectedDayIndex: index });
    this.props.callBack(val);
  }

  _change(_v) {
    if (_v) {
      if (this.state.selectedDayIndex < 6) {
        if (this.props.daysList[this.state.selectedDayIndex + 1]) this.setValue(this.props.daysList[this.state.selectedDayIndex + 1], this.state.selectedDayIndex + 1);
      }
    } else {
      if (this.state.selectedDayIndex > 0) {
        if (this.props.daysList[this.state.selectedDayIndex - 1]) this.setValue(this.props.daysList[this.state.selectedDayIndex - 1], this.state.selectedDayIndex - 1);
      }

    }
  }

  render() {
    var days = this.props.daysList.map((item, index) => {
      if (index < 7) {
        return (
          <button
            key={index}
            className={"week-select-btn" + (this.state.selectedDayIndex == index ? ' active' : '')}
            onClick={() => {
              this.setValue(item, index);
            }}
          >
            {item.weekDay}<br />
            <span>{item.calDay}</span>
          </button>
        );
      }
    });

    return (
      <div className="week-select">

        <button className="week-change" onClick={() => {
          this._change(false);
        }} >
          <img src={upArrow} />
        </button>

        {days}
        <button className="week-change" onClick={() => this._change(true)}>
          <img src={downArrow} />
        </button>
      </div>
    );
  }
}
export default UiWeekSelect;