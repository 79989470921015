import React, { Component } from 'react';

import docIcon from '../../../assets/imgs/profile/document.svg';

class UiDocItem extends Component {

  state = {}

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    return (
      <a className="doc-item" href={this.props.link}>
        <img className="doc-item-icon" src={docIcon} />
        <p>{this.props.text}</p>
      </a>
    );
  }
}
export default UiDocItem;