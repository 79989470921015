import React, { Component } from 'react';

class UiSectionTitle extends Component {

  state = {}

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    return (
      <div className="title-wrap">
        {this.props.subtitle ? 
          <h4 className="subtitle">{this.props.subtitle}</h4>
          : null 
        }
        {this.props.title ? 
          <h3 className="section-title">{this.props.title}<br /><span>{this.props.mark}</span></h3> 
          : null
        }
        {this.props.subtext ? 
          <p className="section-sub">{this.props.subtext}</p> 
          : null
        }
      </div>
    );
  }
}
export default UiSectionTitle;