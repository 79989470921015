import React, { Component } from 'react';

import changeIcon from '../../../assets/imgs/profile/adjust.svg';
import changeIconActive from '../../../assets/imgs/profile/adjust-white.svg';

class UiCalendar extends Component {

  state = {
    radioBoxActive: 0,
  }

  constructor(props) {
    super(props);
  }
  componentWillMount(){
    if(this.props.radioBoxActive != undefined && this.props.radioBoxActive ){
      this.setState({ radioBoxActive: this.props.radioBoxActive });
    }
  }
  componentDidUpdate(nProps,nState){
    if(nProps.clear != undefined && nProps.clear){
      this.setState({ radioBoxActive: null });
      this.props.clearCallBack(!nProps.clear);
    }
    if(this.props.radioBoxActive != undefined && this.props.radioBoxActive && nProps.radioBoxActive != this.state.radioBoxActive){
      this.setState({ radioBoxActive: this.props.radioBoxActive });
    }
    return true;
  }

  setValue = (val) => {
    this.setState({ radioBoxActive: val });
  }

  render() {
    var weeks = this.props.weeksList.map((item, index) => {
      return (
        <div key={index} className="calendar-day">
          <button onClick={() => {
              this.setValue( item.id );
              this.props.callBack(item);
            }} className={"day-wrap" + (item.disabled ? " disabled" : "") + (item.editMenu ? " editable" : "") + (this.state.radioBoxActive == item.id ? " active" : "")}>
            {item.editMenu ? (<div className="day-icon"><img src={changeIconActive} /><img src={changeIcon} /></div>) : null}
            <strong><span>{item.dayName}</span> {item.day}</strong>
          </button>
        </div>
      );
    });
    return (
      <div className="plan-calendar">
        {weeks}
      </div>
    );
  }
}
export default UiCalendar;