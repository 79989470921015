import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { HomePage, LoginPage, ProfilePage, CartPage, PlansPage, SubcribePage, HistoryPage, BonusPage } from '../';

class Container extends Component {
  render() {
    return (
      <div>
        <Switch>
          <BrowserRouter basename="/">
            <Route exact path='/' component={HomePage} />
            <Route exact path='/login' component={LoginPage} />
            <Route exact path='/login/:token' component={LoginPage} />
            <Route exact path='/profile' component={ProfilePage} />
            <Route exact path='/cart/:id?/:token?' component={CartPage} />
            <Route exact path='/plans' component={PlansPage} />
            <Route exact path='/subscribe' component={SubcribePage} />
            <Route exact path='/history' component={HistoryPage} />
            <Route exact path='/bonus' component={BonusPage} />

          </BrowserRouter>  
        </Switch>
      </div>
    );
  }
}

export default Container;