import React, { useEffect, useState } from 'react';

import leftIcon from '../../../assets/imgs/ui/left-round.svg';
import leftIconActive from '../../../assets/imgs/ui/left-round-active.svg';
import leftIconDis from '../../../assets/imgs/ui/left-round-gray.svg';
import rightIcon from '../../../assets/imgs/ui/right-round.svg';
import rightIconActive from '../../../assets/imgs/ui/right-round-active.svg';
import rightIconDis from '../../../assets/imgs/ui/right-round-gray.svg';


const UiSwitch = (props) => {

  const [checkActive, setActive] = useState(false);

  useEffect(() => {
    if (props.selectValue) {
      setActive(props.selectValue);
    } 
  }, [props.selectValue])

  useEffect(() => {
    if (props.clear) {
      setActive(false);
      props.clearCallBack(!props.clear);
    }

  }, [props.clear])




  return (
    <button
      className={"switch" + (checkActive ? " active" : "")}
      disabled={props.disabled}
      onClick={() => {
        setActive(!checkActive);
        props.callBack(checkActive ? true : false);
      }}
    >
      <div className="switch-check"></div>
      {checkActive ?
        <span className="switch-label">{props.label}</span> :
        <span className="switch-label">{props.labelActive}</span>
      }
    </button>
  );

}
export default UiSwitch;