import React, { Component } from 'react';

class UiFormInput extends Component {

  state = {
  }

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="form-group">
        <label>{this.props.label}</label>
        <input type={this.props.type} className="form-control" onChange={e => this.props.callBack( e.target.value ) } />
      </div>
    );
  }
}
export default UiFormInput;