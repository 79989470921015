import React, { Component } from 'react';

import profileIcon from '../../../assets/imgs/profile/user.svg';
import profileIconActive from '../../../assets/imgs/profile/user-white.svg';
import basketIcon from '../../../assets/imgs/profile/shopping.svg';
import basketIconActive from '../../../assets/imgs/profile/shopping-white.svg';
import planIcon from '../../../assets/imgs/profile/timetable.svg';
import planIconActive from '../../../assets/imgs/profile/timetable-white.svg';
import subIcon from '../../../assets/imgs/profile/history.svg';
import subIconActive from '../../../assets/imgs/profile/history-white.svg';
import favIcon from '../../../assets/imgs/profile/list.svg';
import favIconActive from '../../../assets/imgs/profile/list-white.svg';
import bonusIcon from '../../../assets/imgs/profile/percentage.svg';
import bonusIconActive from '../../../assets/imgs/profile/percentage-white.svg';

import changeIcon from '../../../assets/imgs/profile/adjust.svg';
import changeIconActive from '../../../assets/imgs/profile/adjust-white.svg';

class UiMenuProfile extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="card profile-menu">
                <button onClick={() => this.props.callBack(0)} className={"menu-item" + (this.props.activeItem == "0" ? " active" : "")}>
                    {this.props.activeItem == "0" ?
                        <img src={profileIconActive} /> :
                        <img src={profileIcon} />
                    }
                    <h4>{global.locale.menu_profile_profile}</h4>
                </button>
                <button onClick={() => this.props.callBack(1)} className={"menu-item" + (this.props.activeItem == "1" ? " active" : "")}>
                    {this.props.activeItem == "1" ?
                        <img src={basketIconActive} /> :
                        <img src={basketIcon} />
                    }
                    <h4>{global.locale.menu_profile_basket}</h4>
                </button>
                <button onClick={() => this.props.callBack(2)} className={"menu-item" + (this.props.activeItem == "2" ? " active" : "")}>
                    {this.props.activeItem == "2" ?
                        <img src={planIconActive} /> :
                        <img src={planIcon} />
                    }
                    <h4>{global.locale.menu_profile_plans}</h4>
                </button>
                {/*
                <button onClick={() => this.props.callBack(3)} className={"menu-item" + (this.props.activeItem == "3" ? " active" : "")}>
                    {this.props.activeItem == "3" ?
                        <img src={subIconActive} /> :
                        <img src={subIcon} />
                    }
                    <h4>{global.locale.menu_profile_subscribe}</h4>
                </button>
             
                     <button onClick={() => this.props.callBack(4) } className={"menu-item" + (this.props.activeItem == "4" ? " active" : "")}>
                    {this.props.activeItem == "4" ?
                        <img src={favIconActive} /> :
                        <img src={favIcon} />
                    }
                    <h4>{global.locale.menu_profile_preferences}</h4>
                </button>
                */}

                <button onClick={() => this.props.callBack(4)} className={"menu-item" + (this.props.activeItem == "4" ? " active" : "")}>
                    {this.props.activeItem == "4" ?
                         <img src={subIconActive} /> :
                         <img src={subIcon} />
                    }
                    <h4>{global.locale.menu_profile_history}</h4>
                </button>

                <button onClick={() => this.props.callBack(5)} className={"menu-item" + (this.props.activeItem == "5" ? " active" : "")}>
                    {this.props.activeItem == "5" ?
                        <img src={bonusIconActive} /> :
                        <img src={bonusIcon} />
                    }
                    <h4>{global.locale.menu_profile_bonus}</h4>
                </button>
            </div>
        );
    }
}
export default UiMenuProfile;