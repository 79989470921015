import React, { Component } from 'react';

import slimIcon from '../../../assets/imgs/about/slim.svg';
import slimWhiteIcon from '../../../assets/imgs/about/slim-white.svg';
import courierIcon from '../../../assets/imgs/about/courier.svg';
import courierWhiteIcon from '../../../assets/imgs/about/courier-white.svg';
import lawIcon from '../../../assets/imgs/about/law.svg';
import lawWhiteIcon from '../../../assets/imgs/about/law-white.svg';
import chefIcon from '../../../assets/imgs/about/chef.svg';
import chefWhiteIcon from '../../../assets/imgs/about/chef-white.svg';
import bearIcon from '../../../assets/imgs/about/bear.svg';
import bearWhiteIcon from '../../../assets/imgs/about/bear-white.svg';
import dumbbellIcon from '../../../assets/imgs/about/dumbbell.svg';
import dumbbellWhiteIcon from '../../../assets/imgs/about/dumbbell-white.svg';
import vegetableIcon from '../../../assets/imgs/about/vegetable.svg';
import vegetableWhiteIcon from '../../../assets/imgs/about/vegetable-white.svg';
import ecologyIcon from '../../../assets/imgs/about/ecology.svg';
import ecologyWhiteIcon from '../../../assets/imgs/about/ecology-white.svg';

class UiAboutCard extends Component {

  state = {}

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    return (
      <div className="about-info-card">
        <div className="about-info-back">
          <div className="about-info-back-bg"></div>
        </div>
        <div className="about-info-wrap">
          {this.props.icon == "slim" ?
            <div className="about-info-img">
              <img className="icon" src={slimIcon} />
              <img className="icon-white" src={slimWhiteIcon} />
            </div>
            :
            (this.props.icon == "courier" ?
              <div className="about-info-img">
                <img className="icon" src={courierIcon} />
                <img className="icon-white" src={courierWhiteIcon} />
              </div>
              :
              (this.props.icon == "law" ?
                <div className="about-info-img">
                  <img className="icon" src={lawIcon} />
                  <img className="icon-white" src={lawWhiteIcon} />
                </div>
                :
                (this.props.icon == "chef" ?
                  <div className="about-info-img">
                    <img className="icon" src={chefIcon} />
                    <img className="icon-white" src={chefWhiteIcon} />
                  </div>
                  :
                  (this.props.icon == "bear" ?
                    <div className="about-info-img">
                      <img className="icon" src={bearIcon} />
                      <img className="icon-white" src={bearWhiteIcon} />
                    </div>
                    :
                    (this.props.icon == "dumbbell" ?
                      <div className="about-info-img">
                        <img className="icon" src={dumbbellIcon} />
                        <img className="icon-white" src={dumbbellWhiteIcon} />
                      </div>
                      :
                      (this.props.icon == "vegetable" ?
                        <div className="about-info-img">
                          <img className="icon" src={vegetableIcon} />
                          <img className="icon-white" src={vegetableWhiteIcon} />
                        </div>
                        :
                        (this.props.icon == "ecology" ?
                          <div className="about-info-img">
                            <img className="icon" src={ecologyIcon} />
                            <img className="icon-white" src={ecologyWhiteIcon} />
                          </div>
                          :
                          null
                        )
                      )
                    )
                  )
                )
              )
            )
          }
          <h4>{this.props.title}</h4>
          <p>{this.props.text}</p>
        </div>
      </div>
    );
  }
}
export default UiAboutCard;