import React, { Component } from 'react';
import ReactWOW from 'react-wow';

import backIcon from '../../../assets/imgs/ui/left-slide.svg';
import nextIcon from '../../../assets/imgs/ui/right-slide.svg';

class UiFoodTabs extends Component {

  state = {
    bottomArrowActive: false,
    caloriesScroll: 0,
    cardNum: 0,
    programBgLeft: 0,
    programScroll: 0,
    selectCalories: 0,
    selectCaloriesVal: 0,
    selectedProgram: 0,
    topArrowActive: false,
  }

  constructor(props) {
    super(props);
  }

  _changeSizeScroll() {
    if ((this.props.tabsWrapSize - 80) > (this.props.programsList.length * 176)) {
      this.setState({ topArrowActive: false });
    } else {
      this.setState({ topArrowActive: true });
    }
    if ((this.props.tabsWrapSize - 80) > (this.props.programsList[this.state.selectedProgram].caloriesList.length * 180)) {
      this.setState({ bottomArrowActive: false });
    } else {
      this.setState({ bottomArrowActive: true });
    }

  }

  getTouches(evt) {
    return evt.touches ||             // browser API
      evt.originalEvent.touches; // jQuery
  }

  handleTouchStart(evt) {
    const firstTouch = this.getTouches(evt)[0];
    this.setState({ xDown: firstTouch.clientX, yDown: firstTouch.clientY });
  };


  handleTouchMove(evt) {
    if (!this.state.xDown || !this.state.yDown) {
      return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = this.state.xDown - xUp;
    var yDiff = this.state.yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
      if (xDiff > 0) {
        /* left swipe */

        this._nextProgram();
      } else {
        /* right swipe */
        this._prevProgram();
      }
    } else {
      if (yDiff > 0) {
        /* up swipe */
      } else {
        /* down swipe */
      }
    }
    /* reset values */
    this.setState({ xDown: null, yDown: null });
  }

  handleTouchStart2(evt) {
    const firstTouch = this.getTouches(evt)[0];
    this.setState({ xDown2: firstTouch.clientX, yDown2: firstTouch.clientY });
  };


  handleTouchMove2(evt) {
    if (!this.state.xDown2 || !this.state.yDown2) {
      return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff2 = this.state.xDown2 - xUp;
    var yDiff2 = this.state.yDown2 - yUp;

    if (Math.abs(xDiff2) > Math.abs(yDiff2)) {/*most significant*/
      if (xDiff2 > 0) {
        /* left swipe */
        this._nextCalories();
      } else {
        /* right swipe */
        this._prevCalories();
      }
    } else {
      if (yDiff2 > 0) {
        /* up swipe */
      } else {
        /* down swipe */
      }
    }
    /* reset values */
    this.setState({ xDown2: null, yDown2: null });
  }


  componentDidMount() {
    this._load();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.programsList != this.props.programsList) this._changeSizeScroll();
    if (prevProps.tabsWrapSize != this.props.tabsWrapSize) {
      /* arrows show/hide when resize */
      if (this.props.programsList.length > 0) this._changeSizeScroll();
      this.setState({ cardNum: Math.floor((this.props.tabsWrapSize - 80) / 176) }, () => { });
    }

    if (this.props.selectedCalories != undefined && this.props.selectedCalories && prevProps.selectedCalories != this.state.selectCalories) {
      this.setState({ selectCalories: this.props.selectedCalories });
      if(this.props.programsList[this.state.selectedProgram]){
        let kk = 0;
        this.props.programsList[this.state.selectedProgram].caloriesList.map((item, index) => {
          if(item.value == this.props.selectedCalories) kk = index;
        });
        this.setState({ caloriesScroll: 0 - (  ( kk ) * 180 ) });
        //console.log("SS", this.props.selectedCalories)
      }

    }

    if (this.props.selectedPlan != undefined && this.props.selectedPlan !== null  &&     this.props.selectedPlan != this.state.selectedProgram) {

      this.setState({ selectedProgram: this.props.selectedPlan });
      this.setState({ programBgLeft: this.props.selectedPlan * 176 });
    }

  }

  _load() {

  }

  _nextProgram() {
    if (this.state.selectedProgram < this.props.programsList.length - 1) {
      this.props.callBack(
        this.state.selectedProgram + 1,
        this.props.programsList[this.state.selectedProgram + 1].caloriesList[0].value,
        this.props.programsList[this.state.selectedProgram + 1].caloriesList[0].ccalFrom + " - " + this.props.programsList[this.state.selectedProgram + 1].caloriesList[0].ccalTo);

      this.setState({
        programBgLeft: this.state.programBgLeft + 176,
        selectedProgram: this.state.selectedProgram + 1,
      }, () => {
        if (this.state.selectedProgram + 1 > (this.props.tabsWrapSize - 80) / 176 && this.state.programScroll < this.props.tabsWrapSize - 80) {
          this.setState({ programScroll: this.state.programScroll - 176 });
        }
        this._changeSizeScroll();
      });
    }
  }

  _prevProgram() {
    if (this.state.selectedProgram > 0) {
      this.props.callBack(
        this.state.selectedProgram - 1,
        this.props.programsList[this.state.selectedProgram - 1].caloriesList[0].value,
        this.props.programsList[this.state.selectedProgram - 1].caloriesList[0].ccalFrom + " - " + this.props.programsList[this.state.selectedProgram - 1].caloriesList[0].ccalTo);


      this.setState({
        programBgLeft: this.state.programBgLeft - 176,
        selectedProgram: this.state.selectedProgram - 1,
      }, () => {
        if (this.state.programScroll < 0) {
          this.setState({ programScroll: this.state.programScroll + 176 });
        }
        this._changeSizeScroll();
      });

    }
  }

  _nextCalories() {

    if (this.state.selectCaloriesVal < this.props.programsList[this.state.selectedProgram].caloriesList.length - 1) {
      this.setState({
        selectCaloriesVal: this.state.selectCaloriesVal + 1,
        selectCalories: this.props.programsList[this.state.selectedProgram].caloriesList[this.state.selectCaloriesVal + 1].value,
      }, () => {
        if (this.state.selectCaloriesVal + 1 > (this.props.tabsWrapSize - 80) / 180 && this.state.caloriesScroll < this.props.tabsWrapSize - 80) {
          this.setState({ caloriesScroll: this.state.caloriesScroll - 180 });
        }
        this.props.callBack(
          this.state.selectedProgram,
          this.state.selectCalories,
          this.props.programsList[this.state.selectedProgram].caloriesList[this.state.selectCaloriesVal].ccalFrom + " - " + this.props.programsList[this.state.selectedProgram].caloriesList[this.state.selectCaloriesVal].ccalTo);
      });
    }
  }

  _prevCalories() {
    if (this.state.selectCaloriesVal > 0) {
      this.props.callBack(
        this.state.selectedProgram,
        this.props.programsList[this.state.selectedProgram].caloriesList[this.state.selectCaloriesVal - 1].value,
        this.props.programsList[this.state.selectedProgram].caloriesList[this.state.selectCaloriesVal - 1].ccalFrom + " - " + this.props.programsList[this.state.selectedProgram].caloriesList[this.state.selectCaloriesVal - 1].ccalTo);


      this.setState({
        selectCaloriesVal: this.state.selectCaloriesVal - 1,
        selectCalories: this.props.programsList[this.state.selectedProgram].caloriesList[this.state.selectCaloriesVal - 1].value,
      }, () => {
        if (this.state.caloriesScroll < 0) {
          this.setState({ caloriesScroll: this.state.caloriesScroll + 180 });
        }


        if (this.state.selectCaloriesVal > 0) {
          //console.log(this.state.selectedProgram, this.state.selectCalories, this.props.programsList[this.state.selectedProgram].caloriesList[this.state.selectCalories - 1]);

        }
      });
    }
  }

  render() {
    var Programs = this.props.programsList.map((item, index) => {
      return (
        <div className="card-fade-wrap" key={index}>
          <ReactWOW delay={((index + 1) * 0.2) + "s"} animation="fadeIn">
            <div className="tab-card-wrap">
              <button
                className={"tab-card" + (this.state.selectedProgram == index ? ' active' : '')}
                onClick={() => {
                  if (this.props.tabsWrapSize > 690) {
                    if (index < (this.props.tabsWrapSize - 80) / 176) {
                      this.setState({ programScroll: 0 });
                    }
                  }
                  this.setState({ selectedProgram: index }, () => {
                    this.setState({ programBgLeft: index * 176 });
                    if (this.props.tabsWrapSize > 690) {
                      if (index + 2 > (this.props.tabsWrapSize - 80) / 176) {
                        this.setState({ programScroll: - (this.props.programsList.length - Math.trunc((this.props.tabsWrapSize - 80) / 176)) * 176 });
                      }
                    }
               

                    this._changeSizeScroll();
                  });

                  this.props.callBack(
                    index, 
                    this.props.programsList[index].caloriesList[0].value, 
                    this.props.programsList[index].caloriesList[0].ccalFrom + " - " + this.props.programsList[index].caloriesList[0].ccalTo
                    );
                }}
              >
                <div className="tab-card-img" style={{ backgroundImage: "url(" + item.programImage + ")" }}></div>
                <div className="tab-card-text">
                  <h4>{item.programName}</h4>
                  <p>{item.programAbout}</p>
                </div>
              </button>
            </div>
          </ReactWOW>
        </div>
      );
    });

    if (this.props.programsList.length > 0) {
      if(this.props.programsList[this.state.selectedProgram]){
        var Calories = this.props.programsList[this.state.selectedProgram].caloriesList.map((item, index) => {
          return (
            <div key={index}>
              <ReactWOW delay="0.4s" animation="fadeInLeft">
                <div className={"program-type-cards" + (this.state.selectCalories == item.value ? ' active' : '')}>
                  <button
                    className={"program-card" + (this.state.selectCalories == item.value ? ' active' : '')}
                    onClick={() => {
                      this.setState({ selectCalories: item.value });
                      this.props.callBack(this.state.selectedProgram, item.value, item.ccalFrom + " - " + item.ccalTo);
                    }}
                  >
                    <div className="product-card-img">
                      <img src={item.img} />
                      <img src={item.imgActive} />
                    </div>
                    <h4>{item.title}</h4>
                    <p>{item.about} <br />{item.ccalFrom && item.ccalTo ? <span>({item.ccalFrom} - {item.ccalTo})</span> : null}</p>
                  </button>
                </div>
              </ReactWOW>
            </div>
          );
        });
      } else {
        var Calories = null;
      }
      
    }


    return (
      <div className="food-card-tabs-wrap"> 
        <div className="food-card-tabs">
          {this.state.topArrowActive ?
            <button className="program-tabs-slide" onClick={() => this._prevProgram()}>
              <img src={backIcon} />
            </button>
            : null
          }
          <div className="program-tabs-slides-wrap">
            <div
              className="program-tabs-slides-scroll"
              onTouchStart={(ev) => this.handleTouchStart(ev)}
              onTouchMove={(ev) => this.handleTouchMove(ev)}
              style={this.state.topArrowActive ? { left: this.state.programScroll } : null}>

              {/* Список программ */}
              {Programs}

              <div className="active-card-back" style={{ left: this.state.programBgLeft }}></div>
            </div>
          </div>
          {this.state.topArrowActive ?
            <button className="program-tabs-slide" onClick={() => this._nextProgram()}>
              <img src={nextIcon} />
            </button>
            : null
          }
        </div>

        <div className="program-tabs">
          {this.state.bottomArrowActive ?
            <button className="program-tabs-slide" onClick={() => this._prevCalories()}>
              <img src={backIcon} />
            </button>
            : null
          }
          <div className="program-tabs-slides-wrap">
            <div className="program-tabs-slides-scroll"
              onTouchStart={(ev) => this.handleTouchStart2(ev)}
              onTouchMove={(ev) => this.handleTouchMove2(ev)}
              style={this.state.bottomArrowActive ? { left: this.state.caloriesScroll } : null}>

              {/* Список калорийностей программ */}
              {this.props.programsList.length > 0 ? Calories : null}

            </div>
          </div>
          {this.state.bottomArrowActive ?
            <button className="program-tabs-slide" onClick={() => this._nextCalories()}>
              <img src={nextIcon} />
            </button>
            : null
          }
        </div>
      </div>
    );
  }
}
export default UiFoodTabs;