import React, { Component } from 'react';

import UiSwitch from '../../../components/ui/forms/UiSwitch';
import UiSelectScroll from '../../../components/ui/forms/UiSelectScroll';
import masterIcon from '../../../assets/imgs/profile/mastercard.svg';
import visaIcon from '../../../assets/imgs/profile/visa.svg';
import UiBtnGreen from '../buttons/UiBtnGreen';

class UiModalChangeAdress extends Component {

    state = {
        activePay: 0,
        dp: 0,
        time: "17:00 - 22:00",
        comment: "",
        street: "",
        city: "",
        code: ""
    }
    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState) { }

    render() {
        return (
            <div className={"modal-wrap " + (this.props.show ? "show" : "hide")}>
                <div className="modal-payment">
                    <div className="card-title">
                        <button onClick={() => this.props.closeCallBack()} className="card-title-btn"><img src={require('../../../assets/imgs/ui/close.svg')} /></button>
                    </div>
                    <div className="payment-wrap">


                        <div className="form-group">
                            <label>{global.locale.modal_change_adress_street}:</label>
                            <input type="text" id="streetInput" className="form-control" onChange={(e) => this.setState({ street: e.target.value })} />
                        </div>
                        <div className="row">
                            <div className="col-md-8">
                                <div className="form-group">
                                    <label>{global.locale.modal_change_adress_city}:</label>
                                    <input type="text" id="cityInput" className="form-control" onChange={(e) => this.setState({ city: e.target.value })}  />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>{global.locale.modal_change_adress_index}:</label>
                                    <input type="text" id="codeInput" className="form-control"  onChange={(e) => this.setState({ code: e.target.value })} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/*
                            <div className="col-md-4 justify-content-end">
                                <UiSwitch callBack={(val) => this.setState({ dp: val })} disabled />
                            </div>
                            */}
                            <div className="col-md-4">
                                <label>{global.locale.modal_change_adress_time}</label>
                                <UiSelectScroll
                                    selectedText={this.state.time}
                                    selectItems={this.props.firstTimeArr}
                                    callBack={(val) => {
                                        this.setState({ time: val.value });
                                    }}
                                />
                            </div>
                            <div className="col-md-8">
                                <label>{global.locale.modal_change_adress_wishes}</label>
                                <div className="input-group">
                                    <textarea defaultValue={this.props.comment} onChange={(e) => this.setState({ comment: e.target.value })} className="form-control"></textarea>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="btn-payment-wrap">
                                    <UiBtnGreen
                                        btnText={global.locale.modal_change_adress_btn_save}
                                        onClick={() => this.props.callBack(
                                            this.state.dp, 
                                            this.state.time, 
                                            this.state.comment,
                                            this.state.city, 
                                            this.state.street, 
                                            this.state.code
                                            )}
                                        black
                                        btnsm
                                    />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}
export default UiModalChangeAdress;