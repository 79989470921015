import React, { Component } from 'react';

import addIcon from '../../../assets/imgs/profile/plus.svg';

import UiBtnGreenOutline from '../buttons/UiBtnGreenOutline.js';
import UiRating from './UiRating';
import Env from '../../../services/Env';

class UiPlanCard extends Component {

  state = {}

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) {
   
   }

  render() {
    return (
      <div className="plan-card">
        {this.props.addItem ?
          <div className="plan-card-add">
            <button className="add-button" onClick={() => this.props.addEScallBack() }>
              <img src={addIcon} />
              <h3>{global.locale.plan_card_btn_add} {this.props.lanchType}</h3>
            </button>
          </div>
          :
          <div className="plan-card-wrap">
            <div className="plan-card-img">
              <img src={Env.PUBLIC_URL + this.props.image.uri} />
              {this.props.day ?
                <span className="info-mark">{this.props.day}</span>
                : null
              }
            </div>
            <div className="plan-card-info">
              {this.props.lanchType ?
                <p className="info-type"><strong>{this.props.lanchType}</strong></p> :
                null
              }
              {/*
                <div className="info-btns">
                  <div className="btn-bar">
                    <button onClick={() => this.props.changeCallBack() } className="change-btn mb-2">{global.locale.plan_card_btn_swap}</button>
                  </div>
                </div>
              */}
              
              { this.props.callBackRating ? <UiRating active={this.props.hasRaiting == 0} rating={this.props.rating} callBack={(val) => this.props.callBackRating(val) } /> : null}
              <div className="plan-card-title-wrap">
                <h3>{this.props.title} <img src={require('../../../assets/imgs/profile/visible.svg')} /></h3>
                <p className="info-comp">{global.locale.plan_card_composition}: {this.props.ingredient}</p>
              </div>
              <p className="info-about">{this.props.about}</p>
              <div className="info-status">
                <div>
                  <span>{global.locale.plan_card_in_100_gramm}: </span><strong>{this.props.ccal} {global.locale.plan_card_kkal} | {global.locale.plan_card_B}:{this.props.protein}, {global.locale.plan_card_J}:{this.props.fats}, {global.locale.plan_card_U}:{this.props.carbo} </strong>
                </div>
              </div>
              {this.props.modalAddCard ?
                <div className="card-add-price">
                  <UiBtnGreenOutline onClick={() => this.props.selectCallBack() } btnText={global.locale.plan_card_btn_pick} btnsm black />
                  {this.props.price ? <span>{this.props.price} {global.locale.plan_card_currency}</span> : null}
                </div>
                : null
              }
            </div>
          </div>
        }
      </div>
    );
  }
}
export default UiPlanCard;