import React, { Component } from 'react';

import slimIcon from '../../../assets/imgs/faq/slim.svg';
import slimActiveIcon from '../../../assets/imgs/faq/slim-white.svg';
import foodIcon from '../../../assets/imgs/faq/food.svg';
import foodActiveIcon from '../../../assets/imgs/faq/food-white.svg';
import checklistIcon from '../../../assets/imgs/faq/checklist.svg';
import checklistActiveIcon from '../../../assets/imgs/faq/checklist-white.svg';
import creditIcon from '../../../assets/imgs/faq/credit.svg';
import creditActiveIcon from '../../../assets/imgs/faq/credit-white.svg';

import basketIcon from '../../../assets/imgs/profile/shopping-gray.svg';
import basketIconActive from '../../../assets/imgs/profile/shopping-white.svg';


import snowIcon from '../../../assets/imgs/faq/snow.svg';
import snowActiveIcon from '../../../assets/imgs/faq/snow-white.svg';

import selectIcon from '../../../assets/imgs/ui/select-arrow.svg';

class UiFaq extends Component {

  state = {
    activeTab: 0,
    activeItem: -1,
  }

  constructor(props) {
    super(props);
  }
  componentWillMount() {
    if (this.props.activeTab != undefined && this.props.activeTab) {
      this.setState({ activeTab: this.props.activeTab });
    }
  }
  componentDidUpdate(nProps, nState) {
    if (nProps.clear != undefined && nProps.clear) {
      this.setState({ activeTab: null });
      this.props.clearCallBack(!nProps.clear);
    }
    if (this.props.activeTab != undefined && this.props.activeTab && nProps.activeTab != this.state.activeTab) {
      this.setState({ activeTab: this.props.activeTab });
    }
    return true;
  }

  setValue = (val) => {
    this.setState({ activeTab: val });
    this.props.callBack(val);
  }

  render() {
    return (
      <div className="faq-wrap">
        <div className="faq-tabs">
          {/*slimming*/}
          <button className={"faq-tab" + (this.state.activeTab == 0 ? " active" : "")} onClick={() => this.setState({ activeTab: 0, activeItem0: false, activeItem1: false, activeItem2: false, activeItem3: false, activeItem4: false })}>
            <div className="faq-card">
              {this.state.activeTab == 0 ?
                <img src={snowActiveIcon} className="tab-icon" /> :
                <img src={snowIcon} className="tab-icon" />
              }
              <h3>{global.locale.faq_slimming}</h3>
            </div>
          </button>
          {/*products*/}
          <button className={"faq-tab" + (this.state.activeTab == 1 ? " active" : "")} onClick={() => this.setState({ activeTab: 1, activeItem0: false, activeItem1: false, activeItem2: false, activeItem3: false, activeItem4: false })}>
            <div className="faq-card">
              {this.state.activeTab == 1 ?
                <img src={foodActiveIcon} className="tab-icon" /> :
                <img src={foodIcon} className="tab-icon" />
              }
              <h3>{global.locale.faq_products}</h3>
            </div>
          </button>
          {/*rates*/}
          <button className={"faq-tab" + (this.state.activeTab == 2 ? " active" : "")} onClick={() => this.setState({ activeTab: 2, activeItem0: false, activeItem1: false, activeItem2: false, activeItem3: false, activeItem4: false })}>
            <div className="faq-card">
              {this.state.activeTab == 2 ?
                <img src={checklistActiveIcon} className="tab-icon" /> :
                <img src={checklistIcon} className="tab-icon" />
              }
              <h3>{global.locale.faq_rates}</h3>
            </div>
          </button>
          {/*pay*/}
          <button className={"faq-tab" + (this.state.activeTab == 3 ? " active" : "")} onClick={() => this.setState({ activeTab: 3, activeItem0: false, activeItem1: false, activeItem2: false, activeItem3: false, activeItem4: false })}>
            <div className="faq-card">
              {this.state.activeTab == 3 ?
                <img src={creditActiveIcon} className="tab-icon" /> :
                <img src={creditIcon} className="tab-icon" />
              }
              <h3>{global.locale.faq_pay}</h3>
            </div>
          </button>
          {/*Storage*/}
          <button className={"faq-tab" + (this.state.activeTab == 4 ? " active" : "")} onClick={() => this.setState({ activeTab: 4, activeItem0: false, activeItem1: false, activeItem2: false, activeItem3: false, activeItem4: false })}>
            <div className="faq-card">
              {this.state.activeTab == 4 ?
                <img src={basketIconActive} className="tab-icon" /> :
                <img src={basketIcon} className="tab-icon" />
              }
              <h3>{global.locale.faq_Storage}</h3>
            </div>
          </button>

        </div>
        {/*slimming/Advantages*/}
        {this.state.activeTab == 0 ?
          <div className="faq-info container">
            <div className="row">
              <div className="col-md-12 col-lg-8 offset-lg-2">
                <div className="faq-info-list">
                  <button className={"info-item" + (this.state.activeItem0 ? " active" : "")} onClick={() => this.setState({ activeItem0: !this.state.activeItem0 })}>
                    <div className="info-item-title">
                      <h3>{global.locale.faq_0_question_1}</h3>
                      <img src={selectIcon} />
                    </div>
                    <div className="info-item-content">
                      <p>{global.locale.faq_0_answer_1}</p>
                    </div>
                  </button>
                  <button className={"info-item" + (this.state.activeItem1 ? " active" : "")} onClick={() => this.setState({ activeItem1: !this.state.activeItem1 })}>
                    <div className="info-item-title">
                      <h3>{global.locale.faq_0_question_2}</h3>
                      <img src={selectIcon} />
                    </div>
                    <div className="info-item-content">
                      <p dangerouslySetInnerHTML={{ __html: global.locale.faq_0_answer_2 }}></p>
                    </div>
                  </button>
                  <button className={"info-item" + (this.state.activeItem2 ? " active" : "")} onClick={() => this.setState({ activeItem2: !this.state.activeItem2 })}>
                    <div className="info-item-title">
                      <h3>{global.locale.faq_0_question_3}</h3>
                      <img src={selectIcon} />
                    </div>
                    <div className="info-item-content">
                      <p dangerouslySetInnerHTML={{ __html: global.locale.faq_0_answer_3 }}></p>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          : null
        }
        {/*products*/}
        {this.state.activeTab == 1 ?
          <div className="faq-info container">
            <div className="row">
              <div className="col-md-12 col-lg-8 offset-lg-2">
                <div className="faq-info-list">
                  <button className={"info-item" + (this.state.activeItem0 ? " active" : "")} onClick={() => this.setState({ activeItem0: !this.state.activeItem0 })}>
                    <div className="info-item-title">
                      <h3>{global.locale.faq_1_question_1}</h3>
                      <img src={selectIcon} />
                    </div>
                    <div className="info-item-content">
                      <p dangerouslySetInnerHTML={{ __html: global.locale.faq_1_answer_1 }}></p>
                    </div>
                  </button>

                  <button className={"info-item" + (this.state.activeItem1 ? " active" : "")} onClick={() => this.setState({ activeItem1: !this.state.activeItem1 })}>
                    <div className="info-item-title">
                      <h3>{global.locale.faq_1_question_2}</h3>
                      <img src={selectIcon} />
                    </div>
                    <div className="info-item-content">
                      <p dangerouslySetInnerHTML={{ __html: global.locale.faq_1_answer_2 }}></p>
                    </div>
                  </button>

                  <button className={"info-item" + (this.state.activeItem2 ? " active" : "")} onClick={() => this.setState({ activeItem2: !this.state.activeItem2 })}>
                    <div className="info-item-title">
                      <h3>{global.locale.faq_1_question_3}</h3>
                      <img src={selectIcon} />
                    </div>
                    <div className="info-item-content">
                      <p dangerouslySetInnerHTML={{ __html: global.locale.faq_1_answer_3 }}></p>
                    </div>
                  </button>

                  <button className={"info-item" + (this.state.activeItem3 ? " active" : "")} onClick={() => this.setState({ activeItem3: !this.state.activeItem3 })}>
                    <div className="info-item-title">
                      <h3>{global.locale.faq_1_question_4}</h3>
                      <img src={selectIcon} />
                    </div>
                    <div className="info-item-content">
                      <p dangerouslySetInnerHTML={{ __html: global.locale.faq_1_answer_4 }}></p>
                    </div>
                  </button>

                  <button className={"info-item" + (this.state.activeItem4 ? " active" : "")} onClick={() => this.setState({ activeItem4: !this.state.activeItem4 })}>
                    <div className="info-item-title">
                      <h3>{global.locale.faq_1_question_5}</h3>
                      <img src={selectIcon} />
                    </div>
                    <div className="info-item-content">
                      <p dangerouslySetInnerHTML={{ __html: global.locale.faq_1_answer_5 }}></p>
                    </div>
                  </button>

                  <button className={"info-item" + (this.state.activeItem5 ? " active" : "")} onClick={() => this.setState({ activeItem5: !this.state.activeItem5 })}>
                    <div className="info-item-title">
                      <h3>{global.locale.faq_1_question_6}</h3>
                      <img src={selectIcon} />
                    </div>
                    <div className="info-item-content">
                      <p dangerouslySetInnerHTML={{ __html: global.locale.faq_1_answer_6 }}></p>
                    </div>
                  </button> 

                </div>
              </div>
            </div>
          </div>
          : null
        }
        {/*rates*/}
        {this.state.activeTab == 2 ?
          <div className="faq-info container">
            <div className="row">
              <div className="col-md-12 col-lg-8 offset-lg-2">
                <div className="faq-info-list">
                  <button className={"info-item" + (this.state.activeItem0 ? " active" : "")} onClick={() => this.setState({ activeItem0: !this.state.activeItem0 })}>
                    <div className="info-item-title">
                      <h3>{global.locale.faq_2_question_1}</h3>
                      <img src={selectIcon} />
                    </div>
                    <div className="info-item-content">
                      <p dangerouslySetInnerHTML={{ __html: global.locale.faq_2_answer_1 }}></p>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          : null
        }
        {/*pay*/}
        {this.state.activeTab == 3 ?
          <div className="faq-info container">
            <div className="row">
              <div className="col-md-12 col-lg-8 offset-lg-2">
                <div className="faq-info-list">
                  <button className={"info-item" + (this.state.activeItem0 ? " active" : "")} onClick={() => this.setState({ activeItem0: !this.state.activeItem0 })}>
                    <div className="info-item-title">
                      <h3>{global.locale.faq_3_question_1}</h3>
                      <img src={selectIcon} />
                    </div>
                    <div className="info-item-content">
                      <p dangerouslySetInnerHTML={{ __html: global.locale.faq_3_answer_1 }} ></p>
                    </div>
                  </button>
                  <button className={"info-item" + (this.state.activeItem1 ? " active" : "")} onClick={() => this.setState({ activeItem1: !this.state.activeItem1 })}>
                    <div className="info-item-title">
                      <h3>{global.locale.faq_3_question_2}</h3>
                      <img src={selectIcon} />
                    </div>
                    <div className="info-item-content">
                      <p dangerouslySetInnerHTML={{ __html: global.locale.faq_3_answer_2 }} ></p>
                    </div>
                  </button>
                  <button className={"info-item" + (this.state.activeItem2 ? " active" : "")} onClick={() => this.setState({ activeItem2: !this.state.activeItem2 })}>
                    <div className="info-item-title">
                      <h3>{global.locale.faq_3_question_3}</h3>
                      <img src={selectIcon} />
                    </div>
                    <div className="info-item-content">
                      <p dangerouslySetInnerHTML={{ __html: global.locale.faq_3_answer_3 }} ></p>
                    </div>
                  </button>
                  <button className={"info-item" + (this.state.activeItem3 ? " active" : "")} onClick={() => this.setState({ activeItem3: !this.state.activeItem3 })}>
                    <div className="info-item-title">
                      <h3>{global.locale.faq_3_question_4}</h3>
                      <img src={selectIcon} />
                    </div>
                    <div className="info-item-content">
                      <p dangerouslySetInnerHTML={{ __html: global.locale.faq_3_answer_4 }} ></p>
                    </div>
                  </button>
                  <button className={"info-item" + (this.state.activeItem4 ? " active" : "")} onClick={() => this.setState({ activeItem4: !this.state.activeItem4 })}>
                    <div className="info-item-title">
                      <h3>{global.locale.faq_3_question_5}</h3>
                      <img src={selectIcon} />
                    </div>
                    <div className="info-item-content">
                      <p dangerouslySetInnerHTML={{ __html: global.locale.faq_3_answer_5 }} ></p>
                    </div>
                  </button>
                  <button className={"info-item" + (this.state.activeItem5 ? " active" : "")} onClick={() => this.setState({ activeItem5: !this.state.activeItem5 })}>
                    <div className="info-item-title">
                      <h3>{global.locale.faq_3_question_6}</h3>
                      <img src={selectIcon} />
                    </div>
                    <div className="info-item-content">
                      <p dangerouslySetInnerHTML={{ __html: global.locale.faq_3_answer_6 }} ></p>
                    </div>
                  </button>

                  <button className={"info-item" + (this.state.activeItem6 ? " active" : "")} onClick={() => this.setState({ activeItem6: !this.state.activeItem6 })}>
                    <div className="info-item-title">
                      <h3>{global.locale.faq_3_question_7}</h3>
                      <img src={selectIcon} />
                    </div>
                    <div className="info-item-content">
                      <p dangerouslySetInnerHTML={{ __html: global.locale.faq_3_answer_7 }} ></p>
                    </div>
                  </button>

                  <button className={"info-item" + (this.state.activeItem7 ? " active" : "")} onClick={() => this.setState({ activeItem7: !this.state.activeItem7 })}>
                    <div className="info-item-title">
                      <h3>{global.locale.faq_3_question_8}</h3>
                      <img src={selectIcon} />
                    </div>
                    <div className="info-item-content">
                      <p dangerouslySetInnerHTML={{ __html: global.locale.faq_3_answer_8 }} ></p>
                    </div>
                  </button>

                </div>
              </div>
            </div>
          </div>
          : null
        }
        {/*Storage*/}
        {this.state.activeTab == 4 ?
          <div className="faq-info container">
            <div className="row">
              <div className="col-md-12 col-lg-8 offset-lg-2">
                <div className="faq-info-list">
                  <button className={"info-item" + (this.state.activeItem0 ? " active" : "")} onClick={() => this.setState({ activeItem0: !this.state.activeItem0 })}>
                    <div className="info-item-title">
                      <h3>{global.locale.faq_4_question_1}</h3>
                      <img src={selectIcon} />
                    </div>
                    <div className="info-item-content">
                      <p dangerouslySetInnerHTML={{ __html: global.locale.faq_4_answer_1 }} ></p>
                    </div>
                  </button>
                  <button className={"info-item" + (this.state.activeItem1 ? " active" : "")} onClick={() => this.setState({ activeItem1: !this.state.activeItem1 })}>
                    <div className="info-item-title">
                      <h3>{global.locale.faq_4_question_2}</h3>
                      <img src={selectIcon} />
                    </div>
                    <div className="info-item-content">
                      <p dangerouslySetInnerHTML={{ __html: global.locale.faq_4_answer_2 }} ></p>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          : null
        }
      </div>
    );
  }
}
export default UiFaq;