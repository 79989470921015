import ENV from './Env.js';


export const saveUser = (_user) => {
   localStorage.setItem("mh_user", JSON.stringify(_user) );
}

export const loadUser = () => {
  let user = null;
  let str = localStorage.getItem("mh_user");
  try {
    JSON.parse(str);
  } catch (e) {
    if (Array.isArray(str)) user = str;
    return user;
  }
  return  JSON.parse(str);

 
}

export const loginUserFB = (_login, _pass) => {

  return fetch(ENV.API_URL+'/users/loginfb', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: _login,
        token: _pass,
      }) 
    }).then(function(response) {
      return response.json();
    });
}
 
export const loginUser = (_login, _pass) => {

  return fetch(ENV.API_URL+'/users/login', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: _login,
        password: _pass,
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const getProfile = (_token) => {

  return fetch(ENV.API_URL+'/users/profile', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        api_token: _token, 
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const checkPromo = (_token) => {

  return fetch(ENV.API_URL+'/users/promo/check', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code: _token, 
      }) 
    }).then(function(response) {
      return response.json();
    });
}




export const resetUser = (_email) => {

  return fetch(ENV.API_URL+'/users/reset', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: _email,
       
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const registrationUser = (_data) => {

  return fetch(ENV.API_URL+'/users/register', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(_data) 
    }).then(function(response) {
      return response.json();
    });
}

export const updateUser = (_token, _data) => {

  return fetch(ENV.API_URL+'/users/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        api_token: _token,
        data:  _data,
      })
    }).then(function(response) {
      return response.json();
    });
}

export const payOrder = (_token, _data) => {
  return fetch(ENV.API_URL + '/users/order/create', {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          api_token: _token,
          data: _data,
      })
  }).then(function (response) {
      return response.json();
  });
}

export const repayOrder = (_token) => {
  return fetch(ENV.API_URL + '/users/order/pay/'+_token, {
    method: 'GET',
  }).then(function (response) {
      return response.json();
  });
}



export const confirmOrder = (_id, _token) => {
  return fetch(ENV.API_URL + '/users/order/confirm/'+_id+"/"+_token, {
      method: 'GET',
  }).then(function (response) {
      return response.json();
  });
}



export const getOrdersWS = (_token, _id) => {
  return fetch(ENV.API_URL + '/users/order/allws', {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          api_token: _token,
          user_id: _id,
      })
  }).then(function (response) {
      return response.json();
  });
}

export const getOrders = (_token, _id) => {
  return fetch(ENV.API_URL + '/users/order/all', {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          api_token: _token,
          user_id: _id,
      })
  }).then(function (response) {
      return response.json();
  });
}

export const getOrdersHistory = (_token, _id) => {
  return fetch(ENV.API_URL + '/users/orders/history', {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          api_token: _token,
          user_id: _id,
      })
  }).then(function (response) {
      return response.json();
  });
}



export const getOrder = (_token, _id) => {
  return fetch(ENV.API_URL + '/users/order/id', {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          api_token: _token,
          id: _id,
          user_id: _id,
      })
  }).then(function (response) {
      return response.json();
  });
}



export const updateDelivery = (_token, _id, _delivery) => {
  return fetch(ENV.API_URL + '/users/order/add/delivery', {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          api_token: _token,
          order_id: _id,
          deliverys: _delivery
      })
  }).then(function (response) {
      return response.json();
  });
}

export const updateChnage = (_token, _uid, _id, _changes) => {
  return fetch(ENV.API_URL + '/users/order/add/change', {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          api_token: _token,
          user_id: _uid,
          order_id: _id,
          changes: _changes
      })
  }).then(function (response) {
      return response.json();
  });
}

export const addReview = (_token, _id, _did, _val) => {
  return fetch(ENV.API_URL + '/users/order/add/review', {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          api_token: _token,
          user_id: _id,
          dishe_id: _did,
          value: _val,
      })
  }).then(function (response) {
      return response.json();
  });
}

export const getReviews = (_token, _id ) => {
  return fetch(ENV.API_URL + '/users/order/get/review', {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          api_token: _token,
          user_id: _id,
      })
  }).then(function (response) {
      return response.json();
  });
}


export const getInstagramPhoto = () => {
  return fetch(ENV.API_URL + '/users/instagramm', {
    method: 'POST',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({ })
}).then(function (response) {
    return response.json();
});

 
}

