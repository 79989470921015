import React, { Component } from 'react';



import menuIcon from '../../assets/imgs/profile/dinner.svg';
import deliveryIcon from '../../assets/imgs/profile/delivery.svg';
import reviewIcon from '../../assets/imgs/profile/heart.svg';

import changeIcon from '../../assets/imgs/profile/adjust.svg';
import infoIcon from '../../assets/imgs/ui/information.svg';
import planEmptyImg from '../../assets/imgs/profile/harvest.svg';

import subIconActive from '../../assets/imgs/profile/history-white.svg';

import UiBtnGreen from '../../components/ui/buttons/UiBtnGreen.js';
import UiBtnPinkOutline from '../../components/ui/buttons/UiBtnPinkOutline.js';
import UiCalendar from '../../components/ui/tabs/UiCalendar.js';
import UiDocItem from '../../components/ui/cards/UiDocItem.js';
import UiHeader from '../../components/ui/header/UiHeader.js';
import UiFooter from '../../components/ui/footer/UiFooter.js';
import UiModalPay from '../../components/ui/modals/UiModalPay.js';
import UiModalMenu from '../../components/ui/modals/UiModalMenu.js';
import UiPlanCard from '../../components/ui/cards/UiPlanCard.js';
import UiDeliveryItem from '../../components/ui/cards/UiDeliveryItem';
import UiBtnGreenOutline from '../../components/ui/buttons/UiBtnGreenOutline';
import UiSwitch from '../../components/ui/forms/UiSwitch';
import UiSelectScroll from '../../components/ui/forms/UiSelectScroll';
import UiLoader from '../../components/ui/modals/UiLoader';
import UiMenuProfile from '../../components/ui/tabs/UiMenuProfile';

import { en } from '../../i18n/en.js';
import { ru } from '../../i18n/ru.js';
import { ch } from '../../i18n/ch.js';
import { translateObject } from '../../i18n/locale.js';

import { getDateWithOffset } from '../../components/common/Dates';
import { ValidateInput } from '../../components/common/Validator';
import { loadUser, getProfile, getOrdersHistory } from '../../services/Users';
import {  getAllPlans, getProlongateList, stopProlongation } from "../../services/Plans";
import { retrieveData, storeData } from '../../services/Storage';
 

const AnyReactComponent = () => (
    <div classNameName='marker'></div>
);

class HistoryPage extends Component {
    state = {
        loader: false,
        plansNum: 1,
        activePlanTab: 0,
        activeItem: 1,
        activeOrderTab: 0,
        menuHeight: 0,
        subscribeActiviti: 1,
        subscribeItem: 0,

        orders: [],
        prolongations: [],
        plansList: [],

        user: {
        }
    }

    constructor(props) {
        super(props);

        let _lang = retrieveData("ikLocale");
        if (_lang == null) _lang = 'ch';
        if (_lang == 'ch') global.locale = ch;
        if (_lang == 'ru') global.locale = ru;
        if (_lang == 'en') global.locale = en;
        this.setState({ lang: _lang });

    }

    changeLang(val) {
        let _lang = 'ch';
        if (val == 0) _lang = 'ru';
        if (val == 1) _lang = 'ch';
        if (val == 2) _lang = 'en';
        if (_lang == 'ch') global.locale = ch;
        if (_lang == 'ru') global.locale = ru;
        if (_lang == 'en') global.locale = en;
        this.setState({ lang: _lang });
        storeData("ikLocale", _lang);
        //this._load();
    }
    locale(obj, val) {
        let line = "";
        if (val == "title") {
            if (this.state.lang == "ch") line = obj.title_ch;
            if (this.state.lang == "en") line = obj.title_en;
            if (this.state.lang == "ru") line = obj.title_ru;
        }
        if (val == "description") {
            if (this.state.lang == "ch") line = obj.description_ch;
            if (this.state.lang == "en") line = obj.description_en;
            if (this.state.lang == "ru") line = obj.description_ru;
        }
        if (val == "full_description") {
            if (this.state.lang == "ch") line = obj.full_description_ch;
            if (this.state.lang == "en") line = obj.full_description_en;
            if (this.state.lang == "ru") line = obj.full_description_ru;
        }

        return line;
    }
    componentDidMount() {
        let _lang = retrieveData("ikLocale");
        if (_lang == null) _lang = 'ch';
        this.setState({ lang: _lang });

        this._load();
        window.addEventListener('scroll', () => {
            //if (document.documentElement.scrollTop > 160) this.setState({ showMenuBar: true }); else this.setState({ showMenuBar: false });
        });
        window.addEventListener('resize', () => {
            this.setState({
                menuHeight: document.getElementById('root').offsetHeight,
            });
        });
    }

    _load() {
        this.setState({ menuHeight: document.getElementById('root').offsetHeight });
        this.setState({ loader: false });
        let _user = loadUser();
        this.setState({ user: _user });
        if (_user) {
            getProfile(_user.api_token).then((res) => {
                console.log("getProfile", res)
                if (res.success && res.response.length > 0) {
                    this.setState({ user: res.response[0] });
                } else {

                }
            });

            getOrdersHistory(_user.api_token, _user.id).then((res) => {
                console.log("getOrdersHistory", res);
                if (res) {
                    this.setState({ orders: res.response });
                }
            })

            getProlongateList(_user.id).then((res) => {
                console.log("getProlongateList", res);
                if (res) {
                    this.setState({ prolongations: res.response });
                }
            })
        }
        if (_user == null) this.props.history.push("/login");


        getAllPlans().then((res) => {
            let _arr = [];
            let _lang = retrieveData("ikLocale");
            res.response.map((item) => {
                var _call = [];
                item.calories.map((item2) => {
                    _call.push({
                        value: item2.id,
                        title: _lang == "ru" ? item2.title_ru : _lang == "ch" ? item2.title_ch : _lang == "en" ? item2.title_en : "",
                        about: _lang == "ru" ? item2.description_ru : _lang == "ch" ? item2.description_ch : _lang == "en" ? item2.description_en : "",
                        ccalFrom: item2.from_cal,
                        ccalTo: item2.to_cal,
                    })
                })
                _arr.push({
                    value: item.id,
                    sales: item.sales,
                    programName: _lang == "ru" ? item.title_ru : _lang == "ch" ? item.title_ch : _lang == "en" ? item.title_en : "",
                    programAbout: _lang == "ru" ? item.description_ru : _lang == "ch" ? item.description_ch : _lang == "en" ? item.description_en : "",
                    programDescription: _lang == "ru" ? item.full_description_ru : _lang == "ch" ? item.full_description_ch : _lang == "en" ? item.full_description_en : "",

                    programName_ru: item.title_ru,
                    programAbout_ru: item.description_ru,
                    programDescription_ru: item.full_description_ru,

                    programName_ch: item.title_ch,
                    programAbout_ch: item.description_ch,
                    programDescription_ch: item.full_description_ch,

                    programName_en: item.title_en,
                    programAbout_en: item.description_en,
                    programDescription_en: item.full_description_en,

                    caloriesList: _call,
                });
            })

            this.setState({ plansList: _arr })
        })
    }

    _stop(_id) {
        stopProlongation(this.state.user.api_token, _id).then((res) => {
            console.log(res)
            this._load();
        })
    }

    _getProgramName(_id) {
        let f = null;
        this.state.orders.map((item, index) => {
            if (item.id == _id) f = item.program.title_ch + " - " + item.calories.title_ch;
        });
        return f;
    }

    _replay(_index) {
        let _cart = this.state.orders[_index];
        let _plan = null;

        this.state.plansList.map((item) => {
            if (item.value == _cart.program.id) _plan = item;
        })
        console.log(_cart, _plan)
        let data = {
            plan: _plan,
            itemId: _cart.calories_id,
            days: _cart.days - _cart.days_offset,
            size: _cart.size,
            count: _cart.count,
            programSize: _cart.program_size
        }
        console.log(_cart, data)
        storeData("ikCart", data);
        this.props.history.push("/cart");

    }


    render() {
        let orders = this.state.orders.map((item, index) => {
            return (
                <tr>
                    <td>{item.id}</td>
                    <td>{item.program.title_ch} - {item.calories.title_ch}</td>
                    <td>
                        {item.size == 0 ? global.locale.home_food_menu_option_2_item_1 : ""}
                        {item.size == 1 ? global.locale.home_food_menu_option_2_item_2 : ""}
                        {item.size == 2 ? global.locale.home_food_menu_option_2_item_3 : ""}

                        {item.size == 3 ? global.locale.home_food_menu_option_2_item_5 : ""}
                        {item.size == 4 ? global.locale.home_food_menu_option_2_item_6 : ""}

                    </td>
                    <td>{item.days - item.days_offset}</td>
                    <td>{item.program_size == 0 ? global.locale.home_food_menu_option_4_item_1 : global.locale.home_food_menu_option_4_item_2}</td>
                    <td>{item.is_pay == 0 ? global.locale.home_food_menu_option_5_item_2 : global.locale.home_food_menu_option_5_item_1}</td>
                    <td>{item.price}</td>
                    <td>
                        <div className='btn-wrapper green-button' style={{ width: 150, marginTop: 10 }}>
                            <div class="btn-back"><div class="btn-shadow black-shadow"></div></div>
                            <button
                                className={"plan-tab-btn btn btn-sm btn-green"}
                                onClick={() => {
                                    this._replay(index);
                                }}
                                style={{ padding: 4 }}
                            >
                                <img src={subIconActive} /> <strong> {global.locale.plans_replay}</strong>
                            </button>
                        </div>
                    </td>
                </tr >
            )
        });

        let prolongations = this.state.prolongations.map((item, index) => {
            return (
                <tr>
                    <td>{item.plan_id}</td>
                    <td>{this._getProgramName(item.plan_id)}</td>
                    <td>
                        <button
                            className='btn-wrapper green-button-outline'
                            onClick={() => {
                                this._stop(item.id);
                            }}
                        >
                            <strong>{global.locale.home_food_menu_option_stop}</strong>
                        </button>
                    </td>

                </tr >


            )
        });

        return (
            <div ref={this.divPage} id="page" >
                <UiLoader show={this.state.loader} />
                <UiModalMenu show={false} />
                <UiModalPay show={false} />
                <div className={"wrapper login-wrapper" + (this.state.showMenuBar ? ' scroll' : '')}>
                    <div className="content">
                        <UiHeader profile menuHeight={this.state.menuHeight} langCallBack={(val) => this.changeLang(val)} />
                        <section className="profile-title image-bg small-section">
                            <div className="image-shadow">
                                <div className="container">
                                    <h2 className="section-title">{global.locale.bonus_header_}</h2>
                                </div>
                            </div>
                        </section>
                        <section className="profile-wrap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 col-xl-3">
                                        <UiMenuProfile activeItem={4} callBack={(val) => {
                                            if (val == 0) this.props.history.push("/profile");
                                            if (val == 1) this.props.history.push("/cart");
                                            if (val == 2) this.props.history.push("/plans");
                                            if (val == 3) this.props.history.push("/subscribe");
                                            if (val == 4) this.props.history.push("/history");
                                            if (val == 5) this.props.history.push("/bonus");
                                        }} />
                                    </div>
                                    <div className="col-md-12 col-lg-8 col-xl-9">
                                        {prolongations.length > 0 ?
                                            <div className="bonus">
                                                <h3>{global.locale.plans_prolongation_title}</h3>
                                                <div className="bonus-wrap">
                                                    <div className="bonus-info-section">
                                                        <table className="table table-bordered food-table">
                                                            <tr>
                                                                <th>#</th>
                                                                <th>{global.locale.home_food_menu_option_1}</th>

                                                                <th>{global.locale.home_food_menu_option_1}</th>

                                                            </tr>
                                                            {prolongations}
                                                        </table>

                                                    </div>
                                                </div>
                                            </div> : null}

                                        <div className="bonus" style={{ marginTop: "20px" }}>
                                            <div className="bonus-wrap">
                                                <div className="bonus-info-section">
                                                    <table className="table table-bordered food-table">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>{global.locale.home_food_menu_option_1}</th>
                                                            <th>{global.locale.home_food_menu_option_2}</th>
                                                            <th>{global.locale.home_food_menu_option_6}</th>
                                                            <th>{global.locale.home_food_menu_option_4}</th>
                                                            <th>{global.locale.home_food_menu_option_5}</th>
                                                            <th>{global.locale.home_food_menu_price}</th>
                                                            <th> </th>
                                                        </tr>
                                                        {orders}
                                                    </table>

                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>
                        </section>
                        <UiFooter />
                    </div>
                </div>
            </div>
        );
    }
}
export default HistoryPage;