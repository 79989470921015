import React, { Component } from 'react';

class UiRating extends Component {

  state = {
    myRating: 0,
  }

  constructor(props) {
    super(props);
    

  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.rating != undefined && this.props.rating && prevProps.rating != this.state.myRating){
      this.setState({ myRating: this.props.rating });
    }
    return true;
  }

  render() {
    return (
      <div className="rating-info">
        {this.props.active ?
          <div className="rating">
            <button className="rating-btn" onClick={() => this.setState({ myRating: 1 }, () => this.props.callBack(this.state.myRating))}>
              {this.state.myRating > 0 ?
                <img src={require('../../../assets/imgs/ui/star-active.svg')} /> :
                <img src={require('../../../assets/imgs/ui/star.svg')} />
              }
            </button>
            <button className="rating-btn" onClick={() => this.setState({ myRating: 2 }, () => this.props.callBack(this.state.myRating))}>
              {this.state.myRating > 1 ?
                <img src={require('../../../assets/imgs/ui/star-active.svg')} /> :
                <img src={require('../../../assets/imgs/ui/star.svg')} />
              }
            </button>
            <button className="rating-btn" onClick={() => this.setState({ myRating: 3 }, () => this.props.callBack(this.state.myRating))}>
              {this.state.myRating > 2 ?
                <img src={require('../../../assets/imgs/ui/star-active.svg')} /> :
                <img src={require('../../../assets/imgs/ui/star.svg')} />
              }
            </button>
            <button className="rating-btn" onClick={() => this.setState({ myRating: 4 }, () => this.props.callBack(this.state.myRating))}>
              {this.state.myRating > 3 ?
                <img src={require('../../../assets/imgs/ui/star-active.svg')} /> :
                <img src={require('../../../assets/imgs/ui/star.svg')} />
              }
            </button>
            <button className="rating-btn" onClick={() => this.setState({ myRating: 5 }, () => this.props.callBack(this.state.myRating))}>
              {this.state.myRating > 4 ?
                <img src={require('../../../assets/imgs/ui/star-active.svg')} /> :
                <img src={require('../../../assets/imgs/ui/star.svg')} />
              }
            </button>
          </div>
          :
          <div className="rating">
            <div className="rating-btn">
              {this.props.rating > 0 ?
                <img src={require('../../../assets/imgs/ui/star-active.svg')} /> :
                <img src={require('../../../assets/imgs/ui/star.svg')} />
              }
            </div>
            <div className="rating-btn">
              {this.props.rating > 1 ?
                <img src={require('../../../assets/imgs/ui/star-active.svg')} /> :
                <img src={require('../../../assets/imgs/ui/star.svg')} />
              }
            </div>
            <div className="rating-btn">
              {this.props.rating > 2 ?
                <img src={require('../../../assets/imgs/ui/star-active.svg')} /> :
                <img src={require('../../../assets/imgs/ui/star.svg')} />
              }
            </div>
            <div className="rating-btn">
              {this.props.rating > 3 ?
                <img src={require('../../../assets/imgs/ui/star-active.svg')} /> :
                <img src={require('../../../assets/imgs/ui/star.svg')} />
              }
            </div>
            <div className="rating-btn">
              {this.props.rating > 4 ?
                <img src={require('../../../assets/imgs/ui/star-active.svg')} /> :
                <img src={require('../../../assets/imgs/ui/star.svg')} />
              }
            </div>
          </div>
        }
        <div className="rate-num">
          {this.props.active ?
            null :
            <p>{this.props.ratesNum} {global.locale.rating_text}</p>
          }
        </div>
      </div>
    );
  }
}
export default UiRating;