import React, { Component } from 'react';

import leftIcon from '../../../assets/imgs/ui/left-round.svg';
import leftIconActive from '../../../assets/imgs/ui/left-round-active.svg';
import leftIconDis from '../../../assets/imgs/ui/left-round-gray.svg';
import rightIcon from '../../../assets/imgs/ui/right-round.svg';
import rightIconActive from '../../../assets/imgs/ui/right-round-active.svg';
import rightIconDis from '../../../assets/imgs/ui/right-round-gray.svg';

class UiCalcDropdown extends Component {

  state = {
    listActive: false,
    selectValue: '166',
    currentIndex: 0,
  }

  constructor(props) {
    super(props);
  }

  componentWillMount(){
    if(this.props.selectValue != undefined && this.props.selectValue ){
      this.setState({ selectValue: this.props.selectValue });
    }
    if(this.props.currentIndex != undefined && this.props.currentIndex ){
      this.setState({ currentIndex: this.props.currentIndex, selectedTitle: this.props.optionList[this.props.currentIndex].title ,  selectValue: this.props.optionList[this.props.currentIndex].index });
    } else if(this.props.optionList) this.setState({selectedTitle: this.props.optionList[0].title  })
  }
  componentDidUpdate(nProps,nState){
    if(nProps.clear != undefined && nProps.clear){
      this.setState({ selectValue: null });
      this.props.clearCallBack(!nProps.clear);
    }
    if(this.props.selectValue != undefined && this.props.selectValue && nProps.selectValue != this.state.selectValue){
      this.setState({ selectValue: this.props.selectValue });
    }

    if(this.props.currentIndex != undefined && this.props.currentIndex && nProps.currentIndex != this.state.currentIndex ){
        this.setState({ currentIndex: this.props.currentIndex, selectedTitle: this.props.optionList[this.props.currentIndex].title , selectValue: this.props.optionList[this.props.currentIndex].index });
      }

    return true;
  }

  setValue = (val) => {
    this.setState({ selectValue: val });
    this.props.callBack(val);
  }

  _nextClick() {
    if (this.state.currentIndex < (this.props.optionList.length - 1)) {
        if(this.props.callBack) this.props.callBack( this.state.currentIndex + 1);
      this.setState({currentIndex: this.state.currentIndex + 1}, () => {
        
      });
    }
  }
  _prevClick() {
    if (this.state.currentIndex > 0) {
        if(this.props.callBack) this.props.callBack( this.state.currentIndex - 1);
      this.setState({currentIndex: this.state.currentIndex - 1}, () => {
       
      });
    }
  }

  render() {
    var list = this.props.optionList.map((item, index) => {
      return (
        <button key={index} className="drop-list-item" onClick={() => {
          this.setState({listActive: false, selectedTitle: item.title });
          this.props.callBack(item.index);
        }}>{item.title }</button>
      )
    });
    return (
      <div className={"select-wrap calc-dropdown-wrap" + (this.state.listActive ? " active" : "")}>
        <h4>{this.props.title}</h4>
        <div className="input-group calc-dropdown">
          <button className="form-control select-input" onClick={() => this.setState({listActive: !this.state.listActive})}>
            <span>{this.state.selectedTitle}</span>
          </button>
          <div className={"drop-list" + (this.state.listActive ? " active" : "")}>
           {list}
          </div>
        </div>
      </div>
    );
  }
}
export default UiCalcDropdown;