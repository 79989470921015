import React, { Component } from 'react';
import ReactWOW from 'react-wow';

import instaImageSlide1 from "../../assets/imgs/demo/i1.jpg";
import instaImageSlide2 from "../../assets/imgs/demo/i2.jpg";
import instaImageSlide3 from "../../assets/imgs/demo/i3.jpg";
import instaImageSlide4 from "../../assets/imgs/demo/i4.jpg";
import instaImageSlide5 from "../../assets/imgs/demo/i5.jpg";
import instaImageSlide6 from "../../assets/imgs/demo/i6.jpg";
import instaImageSlide7 from "../../assets/imgs/demo/i7.jpg";
import instaImageSlide8 from "../../assets/imgs/demo/i8.jpg";

import UiAboutCard from '../../components/ui/cards/UiAboutCard.js';
import UiBtnGreen from '../../components/ui/buttons/UiBtnGreen.js';
import UiBtnPink from '../../components/ui/buttons/UiBtnPink.js';
import UiCalculator from '../../components/ui/cards/UiCalculator.js';
import UiFaq from '../../components/ui/tabs/UiFaq.js';
import UiFoodTabs from '../../components/ui/tabs/UiFoodTabs.js';
import UiFooter from '../../components/ui/footer/UiFooter.js';
import UiHeader from '../../components/ui/header/UiHeader.js';
import UiInstCard from '../../components/ui/cards/UiInstCard.js';
import UiMenuLoader from '../../components/ui/modals/UiMenuLoader.js';
import UiPie from '../../components/ui/diagram/UiPie.js';
import UiRating from '../../components/ui/cards/UiRating.js';
import UiReviewsSlider from '../../components/ui/sliders/UiReviewsSlider';
import UiSectionTitle from '../../components/ui/titles/UiSectionTitle.js';
import UiSelect from '../../components/ui/forms/UiSelect.js';
import UiTopSlider from '../../components/ui/sliders/UiTopSlider.js';
import UiWeekSelect from '../../components/ui/sliders/UiWeekSelect.js';

import { en } from '../../i18n/en.js';
import { ru } from '../../i18n/ru.js';
import { ch } from '../../i18n/ch.js';
import { translateObject } from '../../i18n/locale.js';

import Env from '../../services/Env';

import { getPriceFromArray, getPriceFromArraySales, getPriceFromArrayEs, getPriceFromArraySalesEs, getCashBack } from '../../components/common/Calculate.js';
import { getFormatedCurrentDate, getWeekTitleByNum, getDay, formatDate2, getDateWithOffsetAllowed2, convertStringToDate, convertStringToDateForSending } from '../../components/common/Dates.js';
import { getAllPlans, getDaysByCalories } from '../../services/Plans.js';
import { storeData } from '../../services/Storage';
import { loadUser, getInstagramPhoto } from '../../services/Users';
import { retrieveData } from '../../services/Storage';


const AnyReactComponent = () => (
  <div classNameName='marker'></div>
);

class HomePage extends Component {

  state = {
    lang: 'ch',
    selectedMyPlanId: 0,
    selectedDayIndex: 1,
    selectedCaloriesId: 1,
    activePay: 0,
    tabsWrapSize: 0,
    menuHeight: 0,
    cartDayCount: 6,
    cartDayIndex: 1,
    cartType: 0,
    cartNumbersDay: 0,
    cartPeopleCount: 1,
    cashBackPrice: 2,
    dayOffset: 0,
    selectedStartDeliveryDate: getDateWithOffsetAllowed2(2, convertStringToDate(getFormatedCurrentDate()))[0].text,
    instaImages: [
      { img: instaImageSlide1 },
      { img: instaImageSlide3 },
      { img: instaImageSlide4 },
      { img: instaImageSlide5 },
      { img: instaImageSlide6 },
      { img: instaImageSlide7 },
    ],
    weeksList: [],
    plansList: [],
    dayListArr: [],
    optionsListDays: [4, 6, 12, 24],
    optionsListDaysSmall: [5, 10, 15, 20],
    selectedMyPlan: {
      title_ru: null,
      title_ch: null,
      title_en: null,
      description_ru: null,
      description_ch: null,
      description_en: null,
      full_description_ru: null,
      full_description_ch: null,
      full_description_en: null,
    },
    selectedPlan: {
      title_ru: null,
      title_ch: null,
      title_en: null,
      description_ru: null,
      description_ch: null,
      description_en: null,
      full_description_ru: null,
      full_description_ch: null,
      full_description_en: null,
      sales: [],
    },
    selectedDayValue: {
      dinner1: null,
      dinner2: null,
      dinner3: null,
      dinner4: null,
      dinner5: null,
    },
    selectedDinner: {
      calories: 0,
      fats: 0,
      protein: 0,
      carbohydrates: 0,
    },

    showTopBtn: false,
    isHideCalc: false,
  }

  constructor(props) {
    super(props);

    let _lang = retrieveData("ikLocale");
    if (_lang == null) {
      _lang = 'ch';
      storeData("ikLocale", 'ch');
    }
    if (_lang == 'ch') global.locale = ch;
    if (_lang == 'ru') global.locale = ru;
    if (_lang == 'en') global.locale = en;
    this.setState({ lang: _lang });

  }

  changeLang(val) {
    let _lang = 'ch';
    if (val == 0) _lang = 'ru';
    if (val == 1) _lang = 'ch';
    if (val == 2) _lang = 'en';
    if (_lang == 'ch') global.locale = ch;
    if (_lang == 'ru') global.locale = ru;
    if (_lang == 'en') global.locale = en;
    this.setState({ lang: _lang });
    storeData("ikLocale", _lang);
    this._load();
  }

  locale(obj, val) {
    let line = "";
    if (val == "title") {
      if (this.state.lang == "ch") line = obj.title_ch;
      if (this.state.lang == "en") line = obj.title_en;
      if (this.state.lang == "ru") line = obj.title_ru;
    }
    if (val == "description") {
      if (this.state.lang == "ch") line = obj.description_ch;
      if (this.state.lang == "en") line = obj.description_en;
      if (this.state.lang == "ru") line = obj.description_ru;
    }
    if (val == "full_description") {
      if (this.state.lang == "ch") line = obj.full_description_ch;
      if (this.state.lang == "en") line = obj.full_description_en;
      if (this.state.lang == "ru") line = obj.full_description_ru;
    }
    return line;
  }


  _excludeDates(_arr, _start, _end) {
    let arr = [];
    let replaceArr = [];
    let offsetStartDate = null;
    let index = 0;
    let arrMap = JSON.parse(JSON.stringify(_arr));


    //find date
    arrMap.map((item, index) => {
      if (!(
        new Date(item.date) >= new Date(_start) &&
        new Date(item.date) <= new Date(_end))) {
        if (arrMap[index - 1]) offsetStartDate = item.date;
        arr.push(item)
      } else {

      }
    })
    //console.log("arrMap",offsetStartDate, arr,   arrMap);

    if (new Date(offsetStartDate) <= new Date('2021-12-23')) {
      offsetStartDate = '2022-01-05';
    } else {
      var dd = new Date(offsetStartDate);
      dd.setDate(dd.getDate() + 1);
      offsetStartDate = dd;
    }

    arrMap.map((item, itemIndex) => {
      if (!(
        new Date(item.date) >= new Date(_start) &&
        new Date(item.date) <= new Date(_end))) {
        //arr.push(item);
        //console.log(item.date);
      } else {
        var d = new Date(offsetStartDate);
        d.setDate(d.getDate() + (index));
        index++;
        if (d.getDay() == 0) {
          d.setDate(d.getDate() + 1);
          index++;
        }
        item.day = formatDate2(d);
        item.dayName = getWeekTitleByNum(getDay(d));

        item.calDay = formatDate2(d);
        item.weekDay = getWeekTitleByNum(getDay(d));
        item.date = d;
        replaceArr.push(item);
      }
    })
    arr = arr.concat(replaceArr);

    let unique = [];
    arr.map(x => unique.filter(a => a.calDay == x.calDay).length > 0 ? null : unique.push(x));
    unique.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });

    return unique;
  }


  componentDidMount() {
    this._load();

    window.addEventListener('scroll', () => {
      //if (document.documentElement.scrollTop > 200) this.setState({ showMenuBar: true }); else this.setState({ showMenuBar: false });
      if (document.documentElement.scrollTop > 600) this.setState({ showTopBtn: true }); else this.setState({ showTopBtn: false });
    });
    window.addEventListener('resize', () => {
      console.log(window.location.href)
      //if (window.location.href == "/") {
        if(document.getElementsByClassName('food-card-tabs-wrap').length > 0) {
          this.setState({
            tabsWrapSize: document.getElementsByClassName('food-card-tabs-wrap')[0].offsetWidth,
            menuHeight: document.getElementById('root').offsetHeight,
          });
        }
     
      //}
    });
  }

  _load() {

    getInstagramPhoto().then((res) => {
      /*
      let arr = res.graphql.user.edge_owner_to_timeline_media.edges;
      let images = [];
      arr.map((item) => images.push({ img: item.node.thumbnail_src, url: "https://www.instagram.com/ikitchen.cz/" }));
      this.setState({ instaImages: images });
      */
    })


    getAllPlans().then((res) => {
      console.log(res);
      let _arr = [];
      let _lang = retrieveData("ikLocale");
      res.response.map((item) => {
        var _call = [];
        item.calories.map((item2) => {
          _call.push({
            value: item2.id,
            title: _lang == "ru" ? item2.title_ru : _lang == "ch" ? item2.title_ch : _lang == "en" ? item2.title_en : "",
            about: _lang == "ru" ? item2.description_ru : _lang == "ch" ? item2.description_ch : _lang == "en" ? item2.description_en : "",
            ccalFrom: item2.from_cal,
            ccalTo: item2.to_cal,
            img: require('../../assets/imgs/menu-type/healthy.svg'),
            imgActive: require('../../assets/imgs/menu-type/healthy-white.svg')
          })
        })
        _arr.push({
          value: item.id,
          sales: item.sales,
          programImage: item.image ? Env.PUBLIC_URL + item.image : require('../../assets/imgs/demo/demo-1-min.webp'),
          programName: _lang == "ru" ? item.title_ru : _lang == "ch" ? item.title_ch : _lang == "en" ? item.title_en : "",
          programAbout: _lang == "ru" ? item.description_ru : _lang == "ch" ? item.description_ch : _lang == "en" ? item.description_en : "",
          programDescription: _lang == "ru" ? item.full_description_ru : _lang == "ch" ? item.full_description_ch : _lang == "en" ? item.full_description_en : "",

          programName_ru: item.title_ru,
          programAbout_ru: item.description_ru,
          programDescription_ru: item.full_description_ru,

          programName_ch: item.title_ch,
          programAbout_ch: item.description_ch,
          programDescription_ch: item.full_description_ch,

          programName_en: item.title_en,
          programAbout_en: item.description_en,
          programDescription_en: item.full_description_en,

          caloriesList: _call,
        });
      })


      this.setState({ plansList: _arr, selectedMyPlan: _arr[0], selectedMyPlanCalories: _arr[0].caloriesList[0], selectedPlan: _arr[0], selectedPlanCalories: _arr[0].caloriesList[0].ccalFrom + " - " + _arr[0].caloriesList[0].ccalTo })
    })


    getDaysByCalories(1, getFormatedCurrentDate()).then((res) => {
      //console.log(res);
      if (res.success) {
        var _dates = [];
        var _index = 0;
        var d = getDateWithOffsetAllowed2(this.state.dayOffset, convertStringToDateForSending(this.state.selectedStartDeliveryDate))[0].value;

        var _minDate = new Date(d)
        _minDate.setDate(_minDate.getDate() + 1);

        res.response.map((item, index) => {
          if (
            this.state.cartDayCount > _index &&
            _minDate <= new Date(item.date)
          ) {
            _index++;
            _dates.push({
              value: item.id,
              date: item.date,
              weekDay: getWeekTitleByNum(getDay(item.date)),
              calDay: formatDate2(item.date),
              dinner1_may_change: item.dinner1_may_change,
              dinner2_may_change: item.dinner2_may_change,
              dinner3_may_change: item.dinner3_may_change,
              dinner4_may_change: item.dinner4_may_change,
              dinner5_may_change: item.dinner5_may_change,
              dinner1: item.dinner1,
              dinner2: item.dinner2,
              dinner3: item.dinner3,
              dinner4: item.dinner4,
              dinner5: item.dinner5
            });
          }
        })

        var _dates2 = [];
        res.response.map((item, index) => {

          _dates2.push({ value: index, weekDay: getWeekTitleByNum(getDay(item.date)), calDay: formatDate2(item.date), dinner1: item.dinner1, dinner2: item.dinner2, dinner3: item.dinner3, dinner4: item.dinner4, dinner5: item.dinner5 });
        })
        //console.log()
        let _selDay = null;
        if (_dates[0]) {
          if (_dates[0].dinner1) _selDay = _dates[0].dinner1[0];
          if (_dates[0].dinner2) _selDay = _dates[0].dinner2[0];
          if (_dates[0].dinner3) _selDay = _dates[0].dinner3[0];
          if (_dates[0].dinner4) _selDay = _dates[0].dinner4[0];
          if (_dates[0].dinner5) _selDay = _dates[0].dinner5[0];
        }

        let _weekList = this._excludeDates(_dates, "2021-12-24", "2022-01-04");
        console.log("_weekList", _weekList, _dates)
        this.setState({
          dayListArr: _dates,
          weeksList: _weekList,
          selectedDayValue: _dates[0],
          selectedDinner: _selDay
        })
      }
    })

    this.setState({ tabsWrapSize: document.getElementsByClassName('food-card-tabs-wrap')[0].offsetWidth, menuHeight: document.getElementById('root').offsetHeight });
  }

  _getDays(_id, _calories) {
    this.setState({ loader: true, selectedPlanCalories: _calories, selectedCaloriesId: _id });
    getDaysByCalories(_id, getFormatedCurrentDate()).then((res) => {
      console.log("getDaysByCalories", res);
      if (res.success) {
        var _dates = [];
        var _index = 0;
        var d = getDateWithOffsetAllowed2(this.state.dayOffset, convertStringToDateForSending(this.state.selectedStartDeliveryDate))[0].value;

        var _minDate = new Date(d)
        _minDate.setDate(_minDate.getDate() + 1);

        res.response.map((item, index) => {
          if (
            this.state.cartDayCount > _index &&
            _minDate <= new Date(item.date)
          ) {
            _index++;
            _dates.push({
              value: item.id,
              date: item.date,
              weekDay: getWeekTitleByNum(getDay(item.date)),
              calDay: formatDate2(item.date),
              dinner1_may_change: item.dinner1_may_change,
              dinner2_may_change: item.dinner2_may_change,
              dinner3_may_change: item.dinner3_may_change,
              dinner4_may_change: item.dinner4_may_change,
              dinner5_may_change: item.dinner5_may_change,
              dinner1: item.dinner1,
              dinner2: item.dinner2,
              dinner3: item.dinner3,
              dinner4: item.dinner4,
              dinner5: item.dinner5
            });
          }
        })

        var _dates2 = [];
        res.response.map((item, index) => {
          _dates2.push({ value: index, weekDay: getWeekTitleByNum(getDay(item.date)), calDay: formatDate2(item.date), dinner1: item.dinner1, dinner2: item.dinner2, dinner3: item.dinner3, dinner4: item.dinner4, dinner5: item.dinner5 });
        })
        let _selDay = null;
        if (_dates[0]) {
          if (_dates[0].dinner1) _selDay = _dates[0].dinner1[0];
          if (_dates[0].dinner2) _selDay = _dates[0].dinner2[0];
          if (_dates[0].dinner3) _selDay = _dates[0].dinner3[0];
          if (_dates[0].dinner4) _selDay = _dates[0].dinner4[0];
          if (_dates[0].dinner5) _selDay = _dates[0].dinner5[0];
        }
        let _weekList = this._excludeDates(_dates, "2021-12-24", "2022-01-04");

        this.setState({
          loader: false,
          dayListArr: _weekList,
          weeksList: _weekList,
          selectedDayValue: _weekList[0],
          selectedDinner: _selDay
        })
      }
    })
  }

  _scrollToTargetAdjusted(target) {
    var element = document.getElementById(target);
    var headerOffset = 130;
    if (window.innerWidth < 770) headerOffset = 350;
    var elementPosition = element.offsetTop;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  _showMyPlan(val, plan, age) {
    this._scrollToTargetAdjusted('calculateShow');
    console.log(this.state.plansList, this.state.plansList[plan].value - 1);
    this.setState({ selectedMyPlanId: this.state.plansList[plan].value - 1 });

    let ff = false;
    if (age > 12) {
      this.setState({ selectedMyPlan: this.state.plansList[plan] });
    } else {
      if (plan == 0) this.setState({ selectedMyPlan: this.state.plansList[0] });
      if (plan == 1) this.setState({ selectedMyPlan: this.state.plansList[1] });
      if (plan == 2) this.setState({ selectedMyPlan: this.state.plansList[6] });
    }
    this.state.plansList[plan].caloriesList.map((item2) => {
      //console.log(item2 ,item2.ccalFrom, item2.ccalTo , item2.ccalFrom <= val , val <=  item2.ccalTo  , item2.ccalFrom <= val && val <=  item2.ccalTo );
      if (age > 12) {
        if (item2.ccalFrom <= val && val <= item2.ccalTo) {
          ff = true;
          this.setState({
            selectedMyPlanCalories: item2,
          })
        }
      } else {
        if (item2.ccalFrom == 0) {
          ff = true;
          this.setState({
            selectedMyPlanCalories: item2,
          })
        }
      }


    })


    if (ff == false) {
      this.state.plansList[plan].caloriesList.map((item2) => {
        if (age > 12) {
          if (item2.ccalFrom <= val && item2.ccalFrom != 0) {
            ff = true;
            console.log(item2, val)
            this.setState({
              selectedMyPlanCalories: item2,
            })
          }
        } else {
          if (item2.ccalFrom == 0) {
            ff = true;
            this.setState({
              selectedMyPlanCalories: item2,
            })
          }
        }
      })

    }

  }

  toCart() {
    if (this.state.dayListArr.length > 0) {
      let data = {
        plan: this.state.selectedPlan,
        itemId: this.state.selectedCaloriesId,
        days: this.state.cartDayCount,
        size: this.state.cartType,
        count: this.state.cartPeopleCount,
        programSize: this.state.cartNumbersDay
      }
      storeData("ikCart", data);
      let _user = loadUser();
      if (_user == null) this.props.history.push("/login"); else this.props.history.push("/cart");
    }
  }

  render() {
    var instagramPhotosList = this.state.instaImages.map((item, index) => {
      return (
        <ReactWOW delay="0.8s" animation="zoomIn" key={index} >

          <div className="col-sm-6 col-md-4 col-lg-2">
            <UiInstCard img={item.img} url={"https://www.instagram.com/ikitchen.cz/"} />

          </div>
        </ReactWOW>
      )
    })

    return (
      <div ref={this.divPage} id="page">
        <div className={"wrapper" + (this.state.showMenuBar ? ' scroll' : '')}>
          <div className="content">
            <UiHeader homeMenu menuHeight={this.state.menuHeight} langCallBack={(val) => this.changeLang(val)} />
            <section className="top-info" id="header">
              <UiTopSlider />
            </section>

            {/* About Info Section */}
            <section className="about centred" id="about">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <ReactWOW delay="0.2s" animation="fadeIn">
                      <div className="title-wrap">
                        <UiSectionTitle
                          title={global.locale.home_about_header_1 + " - "}
                          mark={global.locale.home_about_header_2}
                        />
                      </div>
                    </ReactWOW>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10 offset-md-1">
                    <div className="row">
                      <ReactWOW delay="0.2s" animation="fadeInUp">
                        <div className="col-md-6 col-lg-3">
                          <UiAboutCard icon="chef" title={global.locale.home_about_text_1} text={global.locale.home_about_text_2} />
                        </div>
                      </ReactWOW>
                      <ReactWOW delay="0.6s" animation="fadeInUp">
                        <div className="col-md-6 col-lg-3">
                          <UiAboutCard icon="vegetable" title={global.locale.home_about_text_3} text={global.locale.home_about_text_4} />
                        </div>
                      </ReactWOW>
                      <ReactWOW delay="0.4s" animation="fadeInUp">
                        <div className="col-md-6 col-lg-3">
                          <UiAboutCard icon="dumbbell" title={global.locale.home_about_text_5} text={global.locale.home_about_text_6} />
                        </div>
                      </ReactWOW>
                      <ReactWOW delay="0.8s" animation="fadeInUp">
                        <div className="col-md-6 col-lg-3">
                          <UiAboutCard icon="bear" title={global.locale.home_about_text_7} text={global.locale.home_about_text_8} />
                        </div>
                      </ReactWOW>
                      <ReactWOW delay="1s" animation="fadeInUp">
                        <div className="col-md-6 col-lg-3">
                          <UiAboutCard icon="ecology" title={global.locale.home_about_text_9} text={global.locale.home_about_text_10} />
                        </div>
                      </ReactWOW>
                      <ReactWOW delay="1.2s" animation="fadeInUp">
                        <div className="col-md-6 col-lg-3">
                          <UiAboutCard icon="courier" title={global.locale.home_about_text_11} text={global.locale.home_about_text_12} />
                        </div>
                      </ReactWOW>
                      <ReactWOW delay="1.4s" animation="fadeInUp">
                        <div className="col-md-6 col-lg-3">
                          <UiAboutCard icon="law" title={global.locale.home_about_text_13} text={global.locale.home_about_text_14} />
                        </div>
                      </ReactWOW>
                      <ReactWOW delay="1.6s" animation="fadeInUp">
                        <div className="col-md-6 col-lg-3">
                          <UiAboutCard icon="slim" title={global.locale.home_about_text_15} text={global.locale.home_about_text_16} />
                        </div>
                      </ReactWOW>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="calculate" id="calculate">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-6" style={{ zIndex: 2 }}>
                    <ReactWOW delay="0.2s" animation="fadeIn">
                      <div className="title-wrap">
                        <UiSectionTitle title={global.locale.home_calculate_header_1 + " "} mark={global.locale.home_calculate_header_2} />
                      </div>
                      <div className="card-wrap">
                        <UiCalculator callBack={(val, plan, age) => this._showMyPlan(val, plan, age)} />
                      </div>
                    </ReactWOW>
                  </div>
                  <div className="col-md-12 col-lg-6" id="calculateShow">
                    <ReactWOW delay="0.4s" animation="fadeIn">
                      <div className="card-wrap">
                        <div className="calc-result card">
                          <h4 className="subtitle">{global.locale.home_calculate_subtitle}:</h4>
                          <h4>{this.state.selectedMyPlan.programName}</h4>
                          <p>
                            <strong>
                              {this.state.selectedMyPlanCalories ?
                                this.state.selectedMyPlanCalories.ccalTo == 0 ? "Kids" : this.state.selectedMyPlanCalories.ccalFrom + " - " + this.state.selectedMyPlanCalories.ccalTo + " " + global.locale.home_kkal : null
                              }
                            </strong>
                          </p>
                          <div className="calc-result-info">
                            <p>{this.state.selectedMyPlan.programDescription}</p>
                            <div className="imgs-info">
                              <picture className="imgs-info-big">

                                <img className="imgs-info-inner" src={require('../../assets/imgs/demo/demo-1-min.png')} />
                              </picture>
                              <div className="imgs-info-small-wrap">
                                <picture className="imgs-info-small">

                                  <img className="imgs-info-inner" src={require('../../assets/imgs/demo/demo-1-min.png')} />
                                </picture>
                                <picture className="imgs-info-small">

                                  <img className="imgs-info-inner" src={require('../../assets/imgs/demo/demo-1-min.png')} />
                                </picture>
                              </div>
                              <div className="img-link-wrap">
                                <UiBtnGreen onClick={() => {
                                  this._scrollToTargetAdjusted('menu');
                                  //console.log("selectedMyPlanCalories", this.state.selectedPlan , this.state.selectedMyPlan , this.state.selectedMyPlanCalories);
                                  this._getDays(this.state.selectedMyPlanCalories.value, this.state.selectedMyPlanCalories.ccalFrom + " - " + this.state.selectedMyPlanCalories.ccalTo);
                                  this.setState({
                                    selectedPlan: this.state.selectedMyPlan
                                  })

                                }} btnText={global.locale.home_calculate_btn} white />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ReactWOW>
                  </div>
                </div>
              </div>
            </section>

            <section className="food-menu pattern centred" id="menu">
              <div className="section-pattern">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card-wrap">
                        <div className="card food-menu-card">
                          <ReactWOW delay="0.2s" animation="fadeIn">
                            <div className="title-wrap">
                              <UiSectionTitle
                                subtitle={global.locale.home_food_menu_header_1}
                                title={global.locale.home_food_menu_header_2}
                              />
                            </div>
                          </ReactWOW>
                          <UiFoodTabs
                            selectedPlan={this.state.selectedMyPlanId}
                            selectedCalories={this.state.selectedCaloriesId}
                            callBack={(programId, id, calories) => {
                              // console.log(programId, id, calories , this.state.plansList[programId]);
                              let myPlan = {};
                              this.state.plansList[programId].caloriesList.map((item) => { if (item.value == id) myPlan = item; });
                              this.setState({
                                selectedMyPlanId: programId,
                                selectedMyPlan: this.state.plansList[programId],
                                selectedMyPlanCalories: myPlan,
                                selectedPlan: this.state.plansList[programId]
                              });

                              this._getDays(id, calories)
                            }}
                            tabsWrapSize={this.state.tabsWrapSize}
                            programsList={this.state.plansList}
                          />
                          <ReactWOW delay="0.4s" animation="fadeIn">
                            <div className="food-card">
                              <div className="food-card-info">
                                <div className="food-card-demo">
                                  <div className="slider-demo-menu">
                                    <div className="row">
                                      <div className="col-md-12 col-lg-7">
                                        <ReactWOW delay="0.6s" animation="fadeIn">
                                          <div className="top-menu-info">
                                            <h3>{this.state.selectedPlan.programName}</h3>
                                            <p><strong>{this.state.selectedPlanCalories == "0 - 0" ? "Kids" : this.state.selectedPlanCalories}</strong> <strong>{global.locale.home_kkal}</strong></p>
                                            <p>{this.state.selectedPlan.programDescription}</p>
                                          </div>
                                        </ReactWOW>
                                        <ReactWOW delay="0.8s" animation="fadeIn">
                                          <div className="food-slider">
                                            <UiWeekSelect
                                              callBack={(val) => {
                                                this.setState({
                                                  selectedDayIndex: val.value,
                                                  selectedDayValue: val,
                                                }, () => {
                                                  if (this.state.selectedDayValue.dinner1) this.setState({ selectedDinner: this.state.selectedDayValue.dinner1[0] }); else
                                                    if (this.state.selectedDayValue.dinner2) this.setState({ selectedDinner: this.state.selectedDayValue.dinner2[0] }); else
                                                      if (this.state.selectedDayValue.dinner3) this.setState({ selectedDinner: this.state.selectedDayValue.dinner3[0] }); else
                                                        if (this.state.selectedDayValue.dinner4) this.setState({ selectedDinner: this.state.selectedDayValue.dinner4[0] }); else
                                                          if (this.state.selectedDayValue.dinner5) this.setState({ selectedDinner: this.state.selectedDayValue.dinner5[0] });

                                                });

                                              }}
                                              daysList={this.state.weeksList}
                                            />
                                            {this.state.selectedDinner ?
                                              <div className="menu-items-wrap">
                                                <div className="slide-menu-item">
                                                  <UiPie
                                                    image={this.state.selectedDinner.image}
                                                    calorie={this.state.selectedDinner.calories}
                                                    weight={this.state.selectedDinner.weight}
                                                    fat={this.state.selectedDinner.fats} pro={this.state.selectedDinner.protein} car={this.state.selectedDinner.carbohydrates}
                                                  />
                                                  <div className="slide-menu-text">
                                                    <h3>{this.locale(this.state.selectedDinner, "title")}</h3>

                                                    <UiRating rating={this.state.selectedDinner.raiting} ratesNum={this.state.selectedDinner.raiting_count} />
                                                    <h4>{global.locale.home_food_menu_in}: {this.locale(this.state.selectedDinner, "description")}</h4>
                                                    {this.state.selectedDayValue.weekDay ? <p>{global.locale.home_food_menu_day}: <strong>{this.state.selectedDayValue.weekDay}</strong></p> : null}
                                                    <p>{global.locale.home_food_menu_text_1}:
                                                      <strong>
                                                        {this.state.selectedDayValue.dinner1 && (this.state.cartType == 0 || this.state.cartType == 1 || this.state.cartType == 2 || this.state.cartType == 4) ?
                                                          this.state.selectedDinner == this.state.selectedDayValue.dinner1[0] ? global.locale.home_food_menu_text_3
                                                            : null : null}
                                                        {this.state.selectedDayValue.dinner2 && (this.state.cartType == 0) ?
                                                          this.state.selectedDinner == this.state.selectedDayValue.dinner2[0] ? global.locale.home_food_menu_text_4
                                                            : null : null}
                                                        {this.state.selectedDayValue.dinner3 && (this.state.cartType == 0 || this.state.cartType == 1 || this.state.cartType == 3 || this.state.cartType == 4) ?
                                                          this.state.selectedDinner == this.state.selectedDayValue.dinner3[0] ? global.locale.home_food_menu_text_5
                                                            : null : null}
                                                        {this.state.selectedDayValue.dinner4 && (this.state.cartType == 0) ?
                                                          this.state.selectedDinner == this.state.selectedDayValue.dinner4[0] ? global.locale.home_food_menu_text_6
                                                            : null : null}
                                                        {this.state.selectedDayValue.dinner5 && (this.state.cartType == 0 || this.state.cartType == 2 || this.state.cartType == 3 || this.state.cartType == 4) ?
                                                          this.state.selectedDinner == this.state.selectedDayValue.dinner5[0] ? global.locale.home_food_menu_text_7
                                                            : null : null}

                                                      </strong>
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="control">
                                                  <div className="control-slide-wrap">
                                                    {this.state.selectedDayValue.dinner1 && (this.state.cartType == 0 || this.state.cartType == 1 || this.state.cartType == 2 || this.state.cartType == 4) ?
                                                      <div
                                                        style={this.state.selectedDayValue.dinner1[0].image ? { backgroundImage: 'url(' + Env.PUBLIC_URL + this.state.selectedDayValue.dinner1[0].image + ')' } : null}
                                                        className={"control-slide control-1" + (this.state.selectedDinner == this.state.selectedDayValue.dinner1[0] ? " active" : "")} onClick={() => {
                                                          this.setState({ selectedDinner: this.state.selectedDayValue.dinner1[0] });
                                                        }} >
                                                        <p>{global.locale.home_food_menu_text_3}</p>
                                                      </div>
                                                      : null}
                                                    {this.state.selectedDayValue.dinner2 && (this.state.cartType == 0) ?
                                                      <div
                                                        style={this.state.selectedDayValue.dinner2[0].image ? { backgroundImage: 'url(' + Env.PUBLIC_URL + this.state.selectedDayValue.dinner2[0].image + ')' } : null}
                                                        className={"control-slide control-1" + (this.state.selectedDinner == this.state.selectedDayValue.dinner2[0] ? " active" : "")} onClick={() => {
                                                          this.setState({ selectedDinner: this.state.selectedDayValue.dinner2[0] })
                                                        }}>
                                                        <p>{global.locale.home_food_menu_text_4}</p>
                                                      </div>
                                                      : null}
                                                    {this.state.selectedDayValue.dinner3 && (this.state.cartType == 0 || this.state.cartType == 1 || this.state.cartType == 3 || this.state.cartType == 4) ?
                                                      <div
                                                        style={this.state.selectedDayValue.dinner3[0].image ? { backgroundImage: 'url(' + Env.PUBLIC_URL + this.state.selectedDayValue.dinner3[0].image + ')' } : null}
                                                        className={"control-slide control-2" + (this.state.selectedDinner == this.state.selectedDayValue.dinner3[0] ? " active" : "")} onClick={() => {
                                                          this.setState({ selectedDinner: this.state.selectedDayValue.dinner3[0] })
                                                        }}>
                                                        <p>{global.locale.home_food_menu_text_5}</p>
                                                      </div>
                                                      : null}
                                                    {this.state.selectedDayValue.dinner4 && (this.state.cartType == 0) ?
                                                      <div
                                                        style={this.state.selectedDayValue.dinner4[0].image ? { backgroundImage: 'url(' + Env.PUBLIC_URL + this.state.selectedDayValue.dinner4[0].image + ')' } : null}
                                                        className={"control-slide control-3" + (this.state.selectedDinner == this.state.selectedDayValue.dinner4[0] ? " active" : "")} onClick={() => {
                                                          this.setState({ selectedDinner: this.state.selectedDayValue.dinner4[0] })
                                                        }}>
                                                        <p>{global.locale.home_food_menu_text_6}</p>
                                                      </div>
                                                      : null}
                                                    {this.state.selectedDayValue.dinner5 && (this.state.cartType == 0 || this.state.cartType == 2 || this.state.cartType == 3 || this.state.cartType == 4) ?
                                                      <div
                                                        style={this.state.selectedDayValue.dinner5[0].image ? { backgroundImage: 'url(' + Env.PUBLIC_URL + this.state.selectedDayValue.dinner5[0].image + ')' } : null}
                                                        className={"control-slide control-4" + (this.state.selectedDinner == this.state.selectedDayValue.dinner5[0] ? " active" : "")} onClick={() => {
                                                          this.setState({ selectedDinner: this.state.selectedDayValue.dinner5[0] })
                                                        }}>
                                                        <p>{global.locale.home_food_menu_text_7}</p>
                                                      </div>
                                                      : null}
                                                  </div>
                                                </div>
                                              </div>
                                              : null
                                            }
                                            <UiMenuLoader show={this.state.loader} />
                                          </div>
                                        </ReactWOW>
                                      </div>
                                      {!this.state.isHideCalc ?
                                        <div className="col-md-12 col-lg-5">
                                          <div className="slide-info">
                                            <div className="bottom-menu-info">
                                              <h3>{this.state.selectedPlan.programName}</h3>
                                              {this.state.selectedPlanCalories == "0 - 0" ? <p><strong>Kids</strong></p> : <p><strong>{this.state.selectedPlanCalories} {global.locale.home_kkal}</strong></p>}
                                              <p>{this.state.selectedPlan.programDescription}</p>

                                            </div>

                                            <div className="row">
                                              <div className="col-md-12">
                                                <h4 className="mt-3">{global.locale.home_food_menu_option_4}:</h4>
                                              </div>
                                              <div className="col-md-6">
                                                <UiSelect
                                                  optionList={[
                                                    global.locale.home_food_menu_option_4_item_1,
                                                    global.locale.home_food_menu_option_4_item_2
                                                  ]}
                                                  callBack={(val) => {
                                                    console.log("this.state.selectedMyPlan", this.state.selectedMyPlan)

                                                    this.setState({ cartNumbersDay: val });
                                                    setTimeout(() => {
                                                      //this.setState({  isHideCalc: false });
                                                    }, 1000);
                                                    if (val == 0) {

                                                      this.setState({
                                                        cartDayCount: this.state.optionsListDays[this.state.cartDayIndex],

                                                      }, () => {

                                                        this._getDays(this.state.selectedMyPlanCalories.value, this.state.selectedMyPlanCalories.ccalFrom + " - " + this.state.selectedMyPlanCalories.ccalTo);
                                                      })
                                                    } else {

                                                      this.setState({
                                                        cartDayCount: this.state.optionsListDaysSmall[this.state.cartDayIndex],

                                                      }, () => {
                                                        this._getDays(this.state.selectedMyPlanCalories.value, this.state.selectedMyPlanCalories.ccalFrom + " - " + this.state.selectedMyPlanCalories.ccalTo);
                                                      })
                                                    }
                                                  }}
                                                />
                                              </div>
                                            </div>

                                            <div className="row">
                                              <div className="col-md-12">
                                                <h4 className="mt-3">{global.locale.home_food_menu_option_1}:</h4>
                                              </div>
                                              {this.state.cartNumbersDay == 0 ?
                                                <div className="col-md-6 col-lg-12">
                                                  <UiSelect
                                                    currentIndex={1}
                                                    optionList={[
                                                      global.locale.home_food_menu_option_1_item_1,
                                                      global.locale.home_food_menu_option_1_item_2,
                                                      global.locale.home_food_menu_option_1_item_3,
                                                      global.locale.home_food_menu_option_1_item_4
                                                    ]}
                                                    callBack={(val) => this.setState({
                                                      cartDayCount: this.state.optionsListDays[val],
                                                      cartDayIndex: val,
                                                    }, () => {
                                                      console.log("UiSelect", this.state.selectedMyPlan);
                                                      this._getDays(this.state.selectedMyPlanCalories.value, this.state.selectedMyPlanCalories.ccalFrom + " - " + this.state.selectedMyPlanCalories.ccalTo);
                                                    })}
                                                  />
                                                </div>
                                                :
                                                <div className="col-md-6 col-lg-12">
                                                  <UiSelect
                                                    currentIndex={1}
                                                    optionList={[
                                                      global.locale.home_food_menu_option_1_item_5,
                                                      global.locale.home_food_menu_option_1_item_6,
                                                      global.locale.home_food_menu_option_1_item_7,
                                                      global.locale.home_food_menu_option_1_item_8
                                                    ]}
                                                    callBack={(val) => this.setState({
                                                      cartDayCount: this.state.optionsListDaysSmall[val],
                                                      cartDayIndex: val,
                                                    }, () => {
                                                      this._getDays(this.state.selectedMyPlanCalories.value, this.state.selectedMyPlanCalories.ccalFrom + " - " + this.state.selectedMyPlanCalories.ccalTo);
                                                    })}
                                                  />
                                                </div>
                                              }

                                            </div>
                                            <div className="row">
                                              <div className="col-md-12">
                                                <h4 className="mt-3">{global.locale.home_food_menu_option_2}:</h4>
                                              </div>
                                              <div className="col-md-6 col-lg-12">
                                                <UiSelect optionList={[
                                                  global.locale.home_food_menu_option_2_item_1,
                                                  global.locale.home_food_menu_option_2_item_2,
                                                  global.locale.home_food_menu_option_2_item_3,
                                                  global.locale.home_food_menu_option_2_item_5,
                                                  global.locale.home_food_menu_option_2_item_6

                                                ]} callBack={(val) => this.setState({ cartType: val })} />
                                              </div>
                                            </div>


                                            <div className="row">
                                              <div className="col-md-12">
                                                <h4 className="mt-3">{global.locale.home_food_menu_option_3}:</h4>
                                              </div>
                                              <div className="col-md-6">
                                                <UiSelect optionList={['1', '2', '3', '4']} callBack={(val) => this.setState({ cartPeopleCount: val + 1 })} />
                                              </div>
                                            </div>

                                            <div className="total-price">
                                              {this.state.selectedPlan.sales.length > 0 ?
                                                <div>
                                                  <h3>{global.locale.home_food_menu_price}: <span>{getPriceFromArray(this.state.dayListArr, this.state.cartDayCount, this.state.cartType, this.state.cartPeopleCount)} {global.locale.home_currency}</span> <strong>&nbsp;{getPriceFromArraySales(this.state.dayListArr, this.state.cartDayCount, this.state.cartType, this.state.cartPeopleCount, this.state.selectedPlan.sales[0].value)} {global.locale.home_currency}</strong></h3>

                                                  <p>{global.locale.home_food_menu_price_text_1} {Math.round(getPriceFromArray(this.state.dayListArr, this.state.cartDayCount, this.state.cartType, this.state.cartPeopleCount) - getPriceFromArraySales(this.state.dayListArr, this.state.cartDayCount, this.state.cartType, this.state.cartPeopleCount, this.state.selectedPlan.sales[0].value))} {global.locale.home_currency}</p>
                                                  <a><strong>{getCashBack(getPriceFromArraySales(this.state.dayListArr, this.state.cartDayCount, this.state.cartType, this.state.cartPeopleCount, this.state.selectedPlan.sales[0].value), this.state.cashBackPrice)} {global.locale.home_currency}</strong> {global.locale.home_food_menu_price_text_2}</a>

                                                </div>
                                                :
                                                <h3>{global.locale.home_food_menu_price}:  {getPriceFromArray(this.state.dayListArr, this.state.cartDayCount, this.state.cartType, this.state.cartPeopleCount)} {global.locale.home_currency}</h3>
                                              }
                                              <div className="total-price-btn-wrap">
                                                <UiBtnPink onClick={() => this.toCart()} btnText={global.locale.home_food_menu_btn} black />
                                              </div>
                                            </div>
                                          </div>
                                        </div> : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ReactWOW>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="app-links" id="app">
              <div className="container">
                <div className="row">
                  <div className="col-md-4 col-lg-3 offset-lg-1">
                    <ReactWOW delay="0.2s" animation="zoomIn">
                      <div className="device">
                        <picture>

                          <img src={require('../../assets/imgs/app/mockup.png')} />
                        </picture>
                      </div>
                    </ReactWOW>
                  </div>
                  <div className="col-md-8 col-lg-8">
                    <ReactWOW delay="0.2s" animation="fadeIn">
                      <div className="device-info">
                        <UiSectionTitle
                          subtitle={global.locale.home_app_header_1}
                          title={global.locale.home_app_header_2} />
                        <p>{global.locale.home_app_text_1}</p>
                        <p><strong>{global.locale.home_app_text_2}:</strong></p>
                        <div className="app-link-wrap">
                          <button className="btn btn-image mr-2">
                            <img className="link-image" src={require('../../assets/imgs/app/app-store-badge.svg')} />
                          </button>
                          <button className="btn btn-image">
                            <img className="link-image" src={require('../../assets/imgs/app/google-play-badge.svg')} />
                          </button>
                        </div>
                      </div>
                    </ReactWOW>
                  </div>
                </div>
              </div>
            </section>

            <section className="delivery-map centred" id="map">
              <ReactWOW delay="0.2s" animation="fadeIn">
                <div className="title-wrap">
                  <UiSectionTitle
                    title={global.locale.home_map_header} />
                </div>
              </ReactWOW>
              <ReactWOW delay="0.2s" animation="fadeIn">

                <iframe className="map-wrap" frameBorder="0" allowFullScreen src="https://www.google.com/maps/d/u/0/embed?mid=1youAAXWLQer4UXC3UBfPII-92wh8WiAU&z=10" height="540"></iframe>

              </ReactWOW>
            </section>


            <section className="reviews centred">
              <ReactWOW delay="0.2s" animation="fadeIn">
                <div className="title-wrap">
                  <UiSectionTitle
                    title={global.locale.home_reviews_header} />
                  <div className="reviews-wrap">
                    <div className="container-fluid">
                      <div className="row align-items-center justify-content-center">
                        <div className="col-6 col-sm-4 col-md-2 col-lg-1">
                          <img src={require('../../assets/imgs/partners/sconto_logo.png')} />
                        </div>
                        <div className="col-6 col-sm-4 col-md-2 col-lg-1">
                          <img src={require('../../assets/imgs/partners/abs-jets.png')} />
                        </div>
                        <div className="col-6 col-sm-4 col-md-2 col-lg-1">
                          <img src={require('../../assets/imgs/partners/logo-avast.png')} />
                        </div>
                        <div className="col-6 col-sm-4 col-md-2 col-lg-1">
                          <img src={require('../../assets/imgs/partners/MediaClub.png')} />
                        </div>
                        <div className="col-6 col-sm-4 col-md-2 col-lg-1">
                          <img src={require('../../assets/imgs/partners/rsm-logo-E4DC75DBEE-seeklogo.com.png')} />
                        </div>
                        <div className="col-6 col-sm-4 col-md-2 col-lg-1">
                          <img src={require('../../assets/imgs/partners/skanska-orig-logo-rgb-534.png')} />
                        </div>
                        <div className="col-6 col-sm-4 col-md-2 col-lg-1">
                          <img src={require('../../assets/imgs/partners/Delonghi.jpg')} />
                        </div>
                        <div className="col-6 col-sm-4 col-md-2 col-lg-1">
                          <img src={require('../../assets/imgs/partners/Lidl-logo.jpg')} />
                        </div>
                        <div className="col-6 col-sm-4 col-md-2 col-lg-1">
                          <img src={require('../../assets/imgs/partners/nn_group_web_2.png')} />
                        </div>
                        <div className="col-6 col-sm-4 col-md-2 col-lg-1">
                          <img src={require('../../assets/imgs/partners/Stock.png')} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ReactWOW>
            </section>

            <section className="reviews centred">
              <ReactWOW delay="0.2s" animation="fadeIn">
                <div className="title-wrap">
                  <UiSectionTitle title={global.locale.home_inst_header_2} />
                  <div className="reviews-wrap">
                    <div className="container-fluid">
                      <UiReviewsSlider />
                    </div>
                  </div>
                </div>
              </ReactWOW>
            </section>

            <section className="faqs centred" id="faq">
              <ReactWOW delay="0.2s" animation="fadeIn">
                <div className="title-wrap">
                  <UiSectionTitle
                    title={global.locale.home_faqs_header} />
                </div>
              </ReactWOW>
              <UiFaq />
            </section>

            <section className="inst-title centred">
              <ReactWOW delay="0.2s" animation="fadeIn">
                <div className="title-wrap">
                  <UiSectionTitle
                    
                    title={global.locale.home_inst_header_1} />
                </div>
              </ReactWOW>
            </section>

            <section className="inst pattern">
              <div className="container-fluid">
                <div className="row">
                  {instagramPhotosList}
                </div>
              </div>
            </section>

            <div id="contact">
              <UiFooter />
            </div>

            <button className={"top-button" + (this.state.showTopBtn ? " show" : " hide")} onClick={() => this._scrollToTargetAdjusted("header")}>
              <img src={require('../../assets/imgs/ui/up-arrow.svg')} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;