import React, { Component } from 'react';
import Env from '../../../services/Env';

class UiPie extends Component {

  state = {
    offcet: 25,
  }

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { 
  }

  render() {
    return (
      <div className="slide-menu-item-bg" style={ this.props.image ? null : null  }>
        <img src={Env.PUBLIC_URL + this.props.image} />
        <div className="slide-info-calc">
          <svg width="100%" height="100%" viewBox="0 0 42 42" className="donut">
            <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954"></circle>
            <circle className="donut-ring" cx="21" cy="21" r="15.91549430918954" strokeWidth="6"></circle>
            <circle 
              className="donut-segment car-segment" 
              cx="21" cy="21" 
              r="15.91549430918954" 
              strokeWidth="6" 
              strokeDasharray={[(this.props.car), (100 - (this.props.car))]} 
              strokeDashoffset={this.state.offcet}
            ></circle>
            <circle 
              className="donut-segment fat-segment" 
              cx="21" cy="21" 
              r="15.91549430918954" 
              strokeWidth="6" 
              strokeDasharray={[(this.props.fat), (100 - (this.props.fat))]} 
              strokeDashoffset={this.state.offcet + (100 - (this.props.car))}
            ></circle>
            <circle 
              className="donut-segment pro-segment" 
              cx="21" cy="21" 
              r="15.91549430918954" 
              strokeWidth="6" 
              strokeDasharray={[(this.props.pro), (100 - (this.props.pro))]} 
              strokeDashoffset={this.state.offcet + (100 - (this.props.car)) + (100 - (this.props.fat))}
            ></circle>
          </svg>
          <div className="donut-info">
            <p>{this.props.calorie} {global.locale.pie_kkal}</p>
            <h4>{this.props.weight} {global.locale.pie_gramm}</h4>
          </div>
          <div className="slide-info-lip">
            <p><strong>{global.locale.pie_B}:{this.props.pro}</strong></p>
            <p><strong>{global.locale.pie_J}:{this.props.fat}</strong></p>
            <p><strong>{global.locale.pie_U}:{this.props.car}</strong></p>
          </div>
        </div>
      </div>
    );
  }
}
export default UiPie;