import React, { Component } from 'react';

import docIcon from '../../../assets/imgs/profile/document.svg';

class UiBtnGreen extends Component {

  state = {}

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    return (
      <div className={"btn-wrapper green-button" + (this.props.mr2 ? " mr-2" : "") + (this.props.mb2 ? " mb-2" : "")}>
        <div className="btn-back">
          <div className={"btn-shadow" + (this.props.black ? " black-shadow" : (this.props.white ? " white-shadow" : ""))}></div>
        </div>
        {this.props.btnsm ?
          (this.props.link ? 
            <a className={"btn btn-sm btn-green" + (this.props.white ? " white-text" : "")} href={this.props.href}>{this.props.btnText}</a> : 
            <button onClick={this.props.onClick} className={"btn btn-sm btn-green" + (this.props.white ? " white-text" : "")}>{this.props.btnText}</button>
          )
          :
          (this.props.link ? 
            <a className={"btn btn-green" + (this.props.white ? " white-text" : "")} href={this.props.href}>{this.props.btnText}</a> : 
            <button onClick={this.props.onClick} className={"btn btn-green" + (this.props.white ? " white-text" : "")}>{this.props.btnText}</button>
          )
        }
      </div>
    );
  }
}
export default UiBtnGreen;