import React, { Component } from 'react';
import ReactWOW from 'react-wow';

import manIcon from '../../../assets/imgs/calc/man.svg';
import manWhiteIcon from '../../../assets/imgs/calc/man-white.svg';
import dumbbellIcon from '../../../assets/imgs/calc/dumbbell.svg';
import dumbbellWhiteIcon from '../../../assets/imgs/calc/dumbbell-white.svg';
import targetIcon from '../../../assets/imgs/calc/target.svg';
import targetWhiteIcon from '../../../assets/imgs/calc/target-white.svg';

import UiBtnGreen from '../buttons/UiBtnGreen.js';
import UiCalcInput from '../forms/UiCalcInput';
import UiSelect from '../forms/UiSelect';
import UiSelectInput from '../forms/UiSelectInput';
import UiBtnPink from '../buttons/UiBtnPink';
import UiCalcDropdown from '../forms/UiCalcDropdown';

class UiCalculator extends Component {

  state = {
    activeCalcStep: 0,
    heigthArr: [],
    weightArr: [],
    ageArr: [],

    selectedPlan: 0,
    selectedGender: 0,
    selectedHeight: 140,
    selectedAge: 23,
    selectedWeight: 43,
    selectedLifeStyle: 0,
    selectedWish: 0,
    selectedTarget: 0
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let _arr = [];
    let _arr2 = [];
    let _arr3 = [];
    for (var i = 140; i < 250; i++) _arr.push(i);

    for (var i = 4; i < 81; i++) _arr2.push(i);

    for (var i = 25; i < 221; i++) _arr3.push(i);

    this.setState({ heigthArr: _arr, weightArr: _arr3, ageArr: _arr2 });
  }

  componentDidUpdate(prevProps, prevState) { }

  _scrollToTargetAdjusted(target) {
    if (window.innerWidth < 991) {
      var element = document.getElementById(target);
      var headerOffset = 146;
      if (window.innerWidth < 770) headerOffset = 62;
      var elementPosition = element.offsetTop;
      var offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  }

  calculate() {

    let _oo = 0;
    let _cal = 0;
    if (this.state.selectedGender == 0) {
      _oo = 10 * this.state.selectedWeight + (6.25 * this.state.selectedHeight - (5 * this.state.selectedAge) - 161);
    }
    if (this.state.selectedGender == 1) {
      _oo = 10 * this.state.selectedWeight + (6.25 * this.state.selectedHeight - (5 * this.state.selectedAge) + 5);
    }
    if (this.state.selectedLifeStyle == 0 || this.state.selectedLifeStyle == 1) {
      _cal = _oo * 1.2;
    } else if (this.state.selectedLifeStyle == 2) {
      _cal = _oo * 1.3;
    } else if (this.state.selectedLifeStyle == 3) {
      _cal = _oo * 1.4;
    }

    if (this.state.selectedTarget == 0) {
      _cal = _cal - _cal * 0.2;
    } else if (this.state.selectedTarget == 1) {

    } else if (this.state.selectedTarget == 2) {
      _cal = _cal + _cal * 0.2;
    }
    console.log(_cal, this.state.selectedAge)

    this.props.callBack(_cal, this.state.selectedPlan, this.state.selectedAge);
  }

  render() {
    return (
      <div className="calc card">
        <div className="calc-steps">
          <ReactWOW delay="0.2s" animation="fadeIn">
            <button onClick={() => this.setState({ activeCalcStep: 0 })} className={"btn step" + (this.state.activeCalcStep == 0 ? " active" : "")}>
              {this.state.activeCalcStep == 0 ?
                <img className="step-icon" src={manIcon} /> :
                <img className="step-icon" src={manWhiteIcon} />
              }
            </button>
            <button onClick={() => this.setState({ activeCalcStep: 1 })} className={"btn step" + (this.state.activeCalcStep == 1 ? " active" : "")}>
              {this.state.activeCalcStep == 1 ?
                <img className="step-icon" src={dumbbellIcon} /> :
                <img className="step-icon" src={dumbbellWhiteIcon} />
              }
            </button>
            <button onClick={() => this.setState({ activeCalcStep: 2 })} className={"btn step last" + (this.state.activeCalcStep == 2 ? " active" : "")}>
              {this.state.activeCalcStep == 2 ?
                <img className="step-icon" src={targetIcon} /> :
                <img className="step-icon" src={targetWhiteIcon} />
              }
            </button>
          </ReactWOW>
        </div>
        <div className="calc-info">
          {this.state.activeCalcStep == 0 ?
            <ReactWOW delay="0s" animation="fadeInRight">
              <div className="calc-info-content">
                <div className="row">
                  <div className="col-sm-6">
                    <UiCalcDropdown
                      title={global.locale.calculator_gender + ":"}
                      optionList={[
                        { title: global.locale.calculator_woman, index: 0 },
                        { title: global.locale.calculator_man, index: 1 },
                      ]}
                      currentIndex={this.state.selectedGender}
                      callBack={(val) => this.setState({ selectedGender: val })}
                    />
                  </div>
                  <div className="col-sm-6">
                    <UiCalcInput
                      title={global.locale.calculator_age + ":"}
                      placeholder={global.locale.calculator_age}
                      currentIndex={this.state.selectedAgeVal}
                      callBack={(val) => {
                        this.setState({ selectedAge: val, selectedAgeVal: val });
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <UiCalcInput
                      title={global.locale.calculator_height + ":"}
                      placeholder={global.locale.calculator_height}
                      currentIndex={this.state.selectedHeightVal}
                      callBack={(val) => this.setState({ selectedHeight: val, selectedHeightVal: val })}
                    />
                  </div>
                  <div className="col-sm-6">
                    <UiCalcInput
                      title={global.locale.calculator_weight + ":"}
                      placeholder={global.locale.calculator_weight}
                      currentIndex={this.state.selectedWeightVal}
                      callBack={(val) => this.setState({ selectedWeight: val, selectedWeightVal: val })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="calc-btn-wrap">
                      <UiBtnPink onClick={() => {
                        this.setState({ activeCalcStep: 1 });
                        this._scrollToTargetAdjusted('calculate');
                      }} btnText={global.locale.calculator_next_step} btnsm mr2 mb2 black />
                    </div>
                  </div>
                </div>
              </div>
            </ReactWOW>
            : null
          }
          {this.state.activeCalcStep == 1 ?
            <ReactWOW delay="0s" animation="fadeInRight">
              <div className="calc-info-content">
                <div className="row">
                  <div className="col-sm-12">
                    <UiCalcDropdown
                      title={global.locale.calculator_header_1 + ":"}
                      currentIndex={this.state.selectedLifeStyle}
                      optionList={[
                        { title: global.locale.calculator_li_1_item_1, index: 0 },
                        { title: global.locale.calculator_li_1_item_2, index: 1 },
                        { title: global.locale.calculator_li_1_item_3, index: 2 },
                        { title: global.locale.calculator_li_1_item_4, index: 3 },
                      ]}
                      callBack={(val, title) => this.setState({ selectedLifeStyle: val, selectedLFTitle: title })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <UiCalcDropdown
                      title={global.locale.calculator_header_2 + ":"}
                      optionList={
                        this.state.selectedAge > 12 ? [
                          { title: global.locale.calculator_li_2_item_1, index: 0 },
                          { title: global.locale.calculator_li_2_item_2, index: 1 },
                          { title: global.locale.calculator_li_2_item_4, index: 2 },

                          { title: global.locale.calculator_li_2_item_6, index: 4 },

                        ] : [
                            { title: global.locale.calculator_li_2_item_1, index: 0 },
                            { title: global.locale.calculator_li_2_item_2, index: 1 },
                            { title: global.locale.calculator_li_2_item_3, index: 2 },
                          ]
                      }
                      currentIndex={this.state.selectedPlan}
                      callBack={(val, title) => this.setState({ selectedPlan: val, selectedPlanTitle: title })}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="calc-btn-wrap">
                      <UiBtnGreen onClick={() => {
                        this.setState({ activeCalcStep: 0 });
                        this._scrollToTargetAdjusted('calculate');
                      }} btnText={global.locale.calculator_back_step} btnsm mr2 mb2 black />
                      <UiBtnPink onClick={() => {
                        this.setState({ activeCalcStep: 2 });
                        this._scrollToTargetAdjusted('calculate');
                      }} btnText={global.locale.calculator_next_step} btnsm mr2 mb2 black />
                    </div>
                  </div>
                </div>
              </div>
            </ReactWOW>
            : null
          }
          {this.state.activeCalcStep == 2 ?
            <ReactWOW delay="0s" animation="fadeInRight">
              <div className="calc-info-content">
                <div className="row">
                  <div className="col-sm-12">
                    <UiCalcDropdown
                      title={global.locale.calculator_header_3 + ":"}
                      currentIndex={this.state.selectedTarget}
                      optionList={[
                        { title: global.locale.calculator_li_3_item_1, index: 0 },
                        { title: global.locale.calculator_li_3_item_2, index: 1 },
                        { title: global.locale.calculator_li_3_item_3, index: 2 },
                      ]}
                      callBack={(val) => { this.setState({ selectedTarget: val }) }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="calc-btn-wrap">

                      <UiBtnGreen onClick={() => {
                        this.setState({ activeCalcStep: 1 });
                        this._scrollToTargetAdjusted('calculate');
                      }} btnText={global.locale.calculator_back_step} btnsm mr2 mb2 black />
                      <UiBtnPink onClick={() => this.calculate()} btnText={global.locale.calculator_done} btnsm mr2 mb2 black />

                    </div>
                  </div>
                </div>
              </div>
            </ReactWOW>
            : null
          }
        </div>
      </div>
    );
  }
}
export default UiCalculator;