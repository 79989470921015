import React, { Component } from 'react';

class UiMenuLoader extends Component {

    state = {}

    constructor(props) {
        super(props);
    }

    componentDidUpdate(prevProps, prevState) { }

    render() {

        return (
            <div className={"loader " + (this.props.show ? "show" : "hide")}>
                <div className="loader-frame">
                    <div className="week-select loader-week-frame">
                        <div className="week-loader">
                            <div className="week-loader-day"></div>
                            <div className="week-loader-day"></div>
                            <div className="week-loader-day"></div>
                            <div className="week-loader-day"></div>
                            <div className="week-loader-day"></div>
                            <div className="week-loader-day"></div>
                            <div className="week-loader-day"></div>
                            <div className="week-loader-day"></div>
                        </div>
                    </div>
                    <div className="menu-items-wrap loader-menu-frame">
                        <div className="menu-loader">
                            <div className="menu-loader-slide">
                                <div className="menu-loader-img"></div>
                                <div className="menu-loader-text">
                                    <div className="menu-loader-title"></div>
                                    <div className="menu-loader-rate">
                                        <div className="menu-loader-start"></div>
                                        <div className="menu-loader-num"></div>
                                    </div>
                                    <div className="menu-loader-ingredients"></div>
                                    <div className="menu-loader-info1"></div>
                                    <div className="menu-loader-info2"></div>
                                </div>
                            </div>
                            <div className="menu-loader-control">
                                <div className="menu-loader-control-day"></div>
                                <div className="menu-loader-control-day"></div>
                                <div className="menu-loader-control-day"></div>
                                <div className="menu-loader-control-day"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="loader-overflow">
                    <img className="loader-gif" src={require("../../../assets/imgs/loader.gif")} />
                </div>
            </div>
        );
    }
}

export default UiMenuLoader;