import React, { Component, useState } from 'react';
import ReactWOW from 'react-wow';

import { ReactComponent as SvgNext } from "../../../assets/imgs/ui/left-round-active.svg";
import { ReactComponent as SvgPrev } from "../../../assets/imgs/ui/right-round-active.svg";

const UiReviewsSlider = (props) => {
    const [active, setActive] = useState(0);

    const slidesList = [
        {
            name: "D. Bodláková", text: ["Nejprve moc děkuji za bezproblémovou komunikaci při objednání a rozvozu. Vždy příjemně naladěný pán mi přiveze krabičky a mám pocit, opravdové péče :-) Krabičky jsou krásně zabalené.",
                "Co mě ale absolutně dostalo je chuť a vizuální úprava jídla. Jím často a ráda v restauracích, dokážu se najíst i v dobré restauraci :-) Nepamatuji si, kdy jsem si tak pochutnala, jako z vaší kuchyně, né jedno jídlo, ale pokaždé je jídlo chuťově zajímavé, vyvážené a pokaždé je to fakt zážitek.",
                "Mimořádná je i úprava pokrmu a to velmi oceňuji. Čím se o vesmír lišíte od \"krabiček\" které jsem v minulosti měla možnost ochutnat, je skutečnost, že je poznat co jím i druhý den, a když jídlo i druhý den ohřeji a nandám na talíř, vypadá jako bych ho právě dovařila. Vypadá krásně.",
                "Porce jsou dostatečné, a to i v mém režimu (lose weight), ale pokaždé si opravdu užiju chuť i vizuální stránku, jsem nadšená. Jsem opravdu mile překvapená, velký pozdrav a díky kuchařům a celému týmu za servis, který pro mě tvoříte."]
        },
        {
            name: "R. Hüblová", text: ["Chci vám jen napsat, že vaše krabičková dieta je naprosto úžasná. To jídlo je tak dobré, jsem moc překvapená. Vyzkoušela jsem v minulosti jiné dvě firmy, ale tohle vaše jídlo je o několik mnoho stupňů výš. Prostě super!", "Moc děkuji, jste báječní"]
        },
        {
            name: "S. Kučerová", text: ["Musím říct, že jídla jsou skvělá   Jedu 4. týden, dole 6 kg a s jídlem naprostá spokojenost. Při náročné práci co mám už nemám energii řešit vaření a s vámi mám jídelníček pestrý a chutný. A servis je také skvělý. Za mě Top"]
        },
        {
            name: "R. Hüblová", text: ["Jsem iKitchen naprosto ohromená. Fantastická kvalita jídla.  Vážně gurmánský zážitek 😍"]
        },
        {
            name: "V.Perglová", text: ["Chci vám jen napsat, že vaše krabičková dieta je naprosto úžasná. To jídlo je tak dobré, jsem moc překvapená. Vyzkoušela jsem v minulosti jiné dvě firmy, ale tohle vaše jídlo je o několik mnoho stupňů výš. Prostě super!", "Moc děkuji, jste báječní"]
        },
        {
            name: "D. Grafnetrová", text: ["Musím Vám vysmeknout poklonu!", "Moc děkuji, jste báječní"]
        },
        {
            name: "R. Hüblová ", text: ["Chci vám jen napsat, že vaše krabičková dieta je naprosto úžasná. To jídlo je tak dobré, jsem moc překvapená. Vyzkoušela jsem v minulosti jiné dvě firmy, ale tohle vaše jídlo je o několik mnoho stupňů výš. Prostě super!", "Tento týden jste mi začali zavážet a všechna jídla, která jsem měla jsou skvěle ochucená!", "Mám low carb program a opravdu vyborne.", "Opravdu jsem nadšena!", "Jen tak dál!"]
        },
        {
            name: "B. Lyerová", text: ["Vyzkoušela jsem týden, objednala variantu ke snížení hmotnosti, snídaně a obědy. Doručení bylo přesně na čas, jídlo opravdu pestré, chuťově až neuvěřitelně dobré. Určite využiju i v budoucnu."]
        },
        {
            name: "I. Soukupová", text: ["Objednávám krabičky iKitchen již 4 týdny spolu s manželem. Zkoušeli jsme dříve 3 jiné krabičkové diety, a musím říct, že iKitchen je absolutní TOP. Kvalita a chuť je vynikající. Jídla se neopakují skoro měsíc, a tohle hodnotím jako obrovskou výhodu."]
        },
    ];

    function _prevSlide() {
        if (active > 0) {
            setActive(active - 1)
        } else {
            setActive(slidesList.length - 1)
        }
    }

    function _nextSlide() {
        if (active < slidesList.length - 1) {
            setActive(active + 1)
        } else {
            setActive(0)
        }
    }

    function _scrollToTargetAdjusted(target) {
        var element = document.getElementById(target);
        var headerOffset = 130;
        if (window.innerWidth < 770) headerOffset = 350;
        var elementPosition = element.offsetTop;
        var offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }

    var slides = slidesList.map((item, index) => {
        var reviewTexts = item.text.map((item2, index2) => {
            return (
                <p key={index2}>{item2}</p>
            );
        })
        return (
            <div key={index} className={'reviews-slider-slide' + (active === index ? " active" : "")}>
                {reviewTexts}
                <p><span>— {item.name}</span></p>
            </div>
        );
    });

    return (
        <div className="reviews-slider">
            <button className="reviews-slider-prev" onClick={() => _prevSlide()}><SvgNext className="reviews-slider-icon" /></button>
            {slides}
            <button className="reviews-slider-next" onClick={() => _nextSlide()}><SvgPrev className="reviews-slider-icon" /></button>
        </div>
    );
}
export default UiReviewsSlider;