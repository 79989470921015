
import ENV from './Env.js';



export const getAllPlans = () => {
    return fetch(ENV.API_URL + '/plans/get/all', {
        method: 'GET'
    }).then((response) => response.json());
}

export const getDaysByCalories = (_id, _date) => {
    return fetch(ENV.API_URL + '/dinners/day/get/calories/' + _id + '/' + _date, {
        method: 'GET'
    }).then((response) => response.json());
}

export const getAdditionalDinner = (_id, _date) => {
    return fetch(ENV.API_URL + '/dinners/day/get/additional/' + _id, {
        method: 'GET'
    }).then((response) => response.json());
}

export const getEShop = () => {
    return fetch(ENV.API_URL + '/dinners/dishes/eshop/all', {
        method: 'GET'
    }).then((response) => response.json());
}

export const getProlongateList = (id) => {
    return fetch(`${ENV.API_URL}/users/prolongate/list/${id}`, {
        method: 'GET'
    }).then((response) => response.json());
}

export const startAllProlongation = () => {
    return fetch(`${ENV.API_URL}/users/prolongate/start`, {
        method: 'GET'
    }).then((response) => response.json());
}

export const addProlongation = (_token, _planId, _uid, _date, _days) => {
    return fetch(ENV.API_URL + '/users/prolongate/add', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            api_token: _token,
            plan_id: _planId,
            user_id: _uid ,
            start_date: _date, 
            days: _days
        })
    }).then(function (response) {
        return response.json();
    });
}


export const stopProlongation = (_token, _selectedPlanId ) => {
    return fetch(ENV.API_URL + '/users/prolongate/stop', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            api_token: _token,
            id: _selectedPlanId,
       
        })
    }).then(function (response) {
        return response.json();
    });
}




export const changePlanDate = (_token, _selectedPlanId, _date, _newPlanDaysCount) => {
    return fetch(ENV.API_URL + '/users/order/transfer', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            api_token: _token,
            id: _selectedPlanId,
            date: _date,
            days: _newPlanDaysCount,
        })
    }).then(function (response) {
        return response.json();
    });
}

