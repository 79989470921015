import React, { Component } from 'react';

import slimIcon from '../../../assets/imgs/ui/right-round.svg';

class UiSubInfoCard extends Component {

  state = {}

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    return (
      <div className="sub-info-card">
          <div className="sub-info-img">
            <img className="icon" src={slimIcon} />
          </div>
          <p>{this.props.text}</p>
      </div>
    );
  }
}
export default UiSubInfoCard;