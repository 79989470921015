import ENV from './Env.js';

export const getDeliveryPrice = () => {
  return fetch(ENV.API_URL+'/orders/price/delivery', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }).then(function(response) {
      return response.json();
    });
}

export const getExcludesPrice = () => {
  return fetch(ENV.API_URL+'/orders/price/exclude', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }).then(function(response) {
      return response.json();
    });
}
