import React, { useEffect, useState } from 'react';
import { Container } from './components';

import { startAllProlongation } from "./services/Plans";

import './styles/bootstrap.min.css';
import './styles/style.scss';
import 'animate.css';




const App = () => {
  useEffect(() => {
    //startAllProlongation();
  }, [])


  return (
    <Container />
  );

}

export default App;
