import React, { Component } from 'react';

import leftIcon from '../../../assets/imgs/ui/left-round.svg';
import leftIconActive from '../../../assets/imgs/ui/left-round-active.svg';
import leftIconDis from '../../../assets/imgs/ui/left-round-gray.svg';
import rightIcon from '../../../assets/imgs/ui/right-round.svg';
import rightIconActive from '../../../assets/imgs/ui/right-round-active.svg';
import rightIconDis from '../../../assets/imgs/ui/right-round-gray.svg';

class UiCalcInput extends Component {

  state = {
 
    currentIndex: 0,
  }

  constructor(props) {
    super(props);
  }

  componentWillMount(){
    if(this.props.selectValue != undefined && this.props.selectValue ){
      this.setState({ selectValue: this.props.selectValue });
    }
    if(this.props.currentIndex != undefined && this.props.currentIndex ){
      this.setState({ currentIndex: this.props.currentIndex,  selectValue: this.props.currentIndex });
    }
  }
  componentDidUpdate(nProps,nState){
    if(nProps.clear != undefined && nProps.clear){
      this.setState({ selectValue: null });
      this.props.clearCallBack(!nProps.clear);
    }
    if(this.props.selectValue != undefined && this.props.selectValue && nProps.selectValue != this.state.selectValue){
      this.setState({ selectValue: this.props.selectValue });
    }

    if(this.props.currentIndex != undefined && this.props.currentIndex && nProps.currentIndex != this.state.currentIndex ){
        console.log(this.props.currentIndex )
        this.setState({ currentIndex: this.props.currentIndex, selectValue: this.props.currentIndex });
      }

    return true;
  }

  setValue = (val) => {
    this.setState({ selectValue: val });
    this.props.callBack(val);
  }

  _nextClick() {
    if (this.state.currentIndex < (this.props.optionList.length - 1)) {
        if(this.props.callBack) this.props.callBack( this.state.currentIndex + 1);
      this.setState({currentIndex: this.state.currentIndex + 1}, () => {
        
      });
    }
  }
  _prevClick() {
    if (this.state.currentIndex > 0) {
        if(this.props.callBack) this.props.callBack( this.state.currentIndex - 1);
      this.setState({currentIndex: this.state.currentIndex - 1}, () => {
       
      });
    }
  }

  render() {
    return (
      <div className="select-wrap">
        <h4>{this.props.title}</h4>
        <div className="input-group">
          <div className="form-control select-input">
            <input type="number" placeholder={this.props.placeholder} value={this.state.selectValue} onChange={e => {
              this.props.callBack( e.target.value ); 
              this.setState({selectValue: e.target.value });
            }} />
          </div>
        </div>
      </div>
    );
  }
}
export default UiCalcInput;