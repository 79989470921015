import React, { Component } from 'react';

import UiAlert from '../../components/ui/modals/UiAlert.js';
import UiBtnGreen from '../../components/ui/buttons/UiBtnGreen.js';
import UiHeader from '../../components/ui/header/UiHeader.js';
import UiFooter from '../../components/ui/footer/UiFooter.js';
import UiModalPay from '../../components/ui/modals/UiModalPay.js';
import UiModalMenu from '../../components/ui/modals/UiModalMenu.js';
import UiLoader from '../../components/ui/modals/UiLoader';
import UiMenuProfile from '../../components/ui/tabs/UiMenuProfile';
import UiSubInfoCard from '../../components/ui/cards/UiSubInfoCard.js';

import { en } from '../../i18n/en.js';
import { ru } from '../../i18n/ru.js';
import { ch } from '../../i18n/ch.js';
import { translateObject } from '../../i18n/locale.js';

import { loadUser } from '../../services/Users';
import { retrieveData, storeData } from '../../services/Storage';

const AnyReactComponent = () => (
    <div classNameName='marker'></div>
);

class SubscribePage extends Component {

    state = {
        loader: false,
        plansNum: 1,
        activePlanTab: 0,
        activeItem: 1,
        activeOrderTab: 0,
        menuHeight: 0,
        subscribeActive: true,
        subscribeItem: 0,

        showAlert: false,
        alertText: "",
        alertTitle: "",

        user: {
        }
    }

    constructor(props) {
        super(props);

        let _lang = retrieveData("ikLocale");
        if (_lang == null) _lang = 'ch';
        if (_lang == 'ch') global.locale = ch;
        if (_lang == 'ru') global.locale = ru;
        if (_lang == 'en') global.locale = en;
        this.setState({ lang: _lang });

    }

    changeLang(val) {
        let _lang = 'ch';
        if (val == 0) _lang = 'ru';
        if (val == 1) _lang = 'ch';
        if (val == 2) _lang = 'en';
        if (_lang == 'ch') global.locale = ch;
        if (_lang == 'ru') global.locale = ru;
        if (_lang == 'en') global.locale = en;
        this.setState({ lang: _lang });
        storeData("ikLocale", _lang);
        //this._load();
    }

    componentDidMount() {
        this._load();
        window.addEventListener('scroll', () => {
            //if (document.documentElement.scrollTop > 160) this.setState({ showMenuBar: true }); else this.setState({ showMenuBar: false });
        });
        window.addEventListener('resize', () => {
            this.setState({
                menuHeight: document.getElementById('root').offsetHeight,
            });
        });
    }

    _alert(_title, _text) {
        this.setState({
            showAlert: true,
            alertTitle: _title,
            alertText: _text
        })
    }

    _load() {
        this.setState({ menuHeight: document.getElementById('root').offsetHeight });
        this.setState({ loader: false });
        let _user = loadUser();
        this.setState({ user: _user });
        if (_user == null) this.props.history.push("/login");
    }

    _changeCard() {
        this._alert(global.locale.subscribe_alert);
    }

    render() {
        return (
            <div ref={this.divPage} id="page">
                <UiLoader show={this.state.loader} />

                <UiModalMenu show={false} />
                <UiModalPay show={false} />
                <UiAlert
                    show={this.state.showAlert}
                    title={this.state.alertTitle}
                    text={this.state.alertText}
                    callBack={() => this.setState({ showAlert: false })}
                    leftBtnText="Ок"
                />

                <div className={"wrapper login-wrapper" + (this.state.showMenuBar ? ' scroll' : '')}>
                    <div className="content">
                        <UiHeader profile menuHeight={this.state.menuHeight} langCallBack={(val) => this.changeLang(val)} />
                        <section className="profile-title image-bg small-section">
                            <div className="image-shadow">
                                <div className="container">
                                    <h2 className="section-title">{global.locale.subscribe_header}</h2>
                                </div>
                            </div>
                        </section>

                        <section className="profile-wrap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 col-xl-3">
                                        <UiMenuProfile activeItem={3} callBack={(val) => {
                                            if (val == 0) this.props.history.push("/profile");
                                            if (val == 1) this.props.history.push("/cart");
                                            if (val == 2) this.props.history.push("/plans");
                                            if (val == 3) this.props.history.push("/subscribe");
                                            if (val == 5) this.props.history.push("/bonus");
                                        }} />
                                    </div>

                                    <div className="col-md-12 col-lg-8 col-xl-9">

                                        <div className="row">
                                            <div className="col-md-7">
                                                <h3 className="form-title">{global.locale.subscribe_question}</h3>
                                                <UiSubInfoCard text={global.locale.subscribe_info_1} />
                                                <UiSubInfoCard text={global.locale.subscribe_info_2} />
                                                <UiSubInfoCard text={global.locale.subscribe_info_3} />
                                                <UiSubInfoCard text={global.locale.subscribe_info_4} />
                                            </div>
                                            <div className="col-md-5">
                                                <div className="sub-wrap">
                                                    <h3 className="form-title">{global.locale.subscribe_my_subscribe}</h3>
                                                    {!this.state.subscribeActive ?
                                                        <div className="sub-activiti">
                                                            <p>{global.locale.subscribe_miss}</p>
                                                            <div className="sub-header-button">
                                                                <UiBtnGreen btnText={global.locale.subscribe_btn_global.locale.subscribe_deal} black btnsm />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="sub-activiti">
                                                            <span>{global.locale.subscribe_discount_info}: 3%</span><br />
                                                            <span>{global.locale.subscribe_card_info}:</span>
                                                            <div className="sub-card-info">
                                                                <span>{global.locale.subscribe_card_num}: <strong>XXXX-XXXX-XXXX-1234</strong></span>
                                                            </div>
                                                            <div className="sub-header-button">
                                                                <UiBtnGreen btnText={global.locale.subscribe_btn_change_card} onClick={() => { this._changeCard() }} black btnsm />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <h3 className="form-title">{global.locale.subscribe_faq_header}</h3>
                                        <div className="card sub-card">
                                            <div className="sub-help">
                                                <div className="sub-help-line">
                                                    <div className="sub-help-btn">
                                                        <div className="profile-menu-subscribe">
                                                            <button onClick={() => this.setState({ subscribeItem: 0 })} className={"menu-subscribe-item" + (this.state.subscribeItem == "0" ? " active" : "")}>
                                                                <p><strong>{global.locale.subscribe_faq_text_1}</strong></p>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="sub-help-info">
                                                        {this.state.subscribeItem == "0" ?
                                                            <p>{global.locale.subscribe_faq_text_2}</p> : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="sub-help-line">
                                                    <div className="sub-help-btn">
                                                        <div className="profile-menu-subscribe">
                                                            <button onClick={() => this.setState({ subscribeItem: 1 })} className={"menu-subscribe-item" + (this.state.subscribeItem == "1" ? " active" : "")}>
                                                                <p><strong>{global.locale.subscribe_faq_text_3}</strong></p>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="sub-help-info">
                                                        {this.state.subscribeItem == "1" ?
                                                            <p>{global.locale.subscribe_faq_text_4}</p>
                                                            : null}
                                                    </div>
                                                </div>
                                                <div className="sub-help-line">
                                                    <div className="sub-help-btn">
                                                        <div className="profile-menu-subscribe">
                                                            <button onClick={() => this.setState({ subscribeItem: 2 })} className={"menu-subscribe-item" + (this.state.subscribeItem == "2" ? " active" : "")}>
                                                                <p><strong>{global.locale.subscribe_faq_text_5}</strong></p>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="sub-help-info">
                                                        {this.state.subscribeItem == "2" ?
                                                            <p>{global.locale.subscribe_faq_text_6}</p>
                                                            : null}
                                                    </div>
                                                </div>
                                                <div className="sub-help-line">
                                                    <div className="sub-help-btn">
                                                        <div className="profile-menu-subscribe">
                                                            <button onClick={() => this.setState({ subscribeItem: 3 })} className={"menu-subscribe-item" + (this.state.subscribeItem == "3" ? " active" : "")}>
                                                                <p><strong>{global.locale.subscribe_faq_text_7}</strong></p>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="sub-help-info">
                                                        {this.state.subscribeItem == "3" ?
                                                            <p>{global.locale.subscribe_faq_text_8}</p>
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <UiFooter />
                    </div>
                </div>
            </div>
        );
    }
}
export default SubscribePage;