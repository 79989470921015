
export const translateObject = (_obj, _title, _lang) => {
    let _line = "";
    if (_lang == 'ru') {
        _line = _obj[_title + '_ru'];
    }
    if (_lang == 'ch') {
        _line = _obj[_title + '_ch'];
    }
    if (_lang == 'en') {
        _line = _obj[_title + '_en'];
    }
    return _line;
}